import PropTypes from 'prop-types'
import React, { useState, useRef, useEffect } from 'react'


import DetailsList from '../common/DetailsList'
import HorizontalTabs from '../common/tabs/HorizontalTabs'
import RecipientsManager from '../../containers/RecipientsManager'
import MarketingEmailEventSummaryWidget from '../widgets/MarketingEmailEventSummaryWidget'
import MarketingEmailEventDetailWidget from '../widgets/MarketingEmailEventDetailWidget'
import Tab from '../common/tabs/Tab'
import Activity from '../common/Activity'
import { capitalize } from '../../utils'


const MarketingEmail = props => {
  const {
    model,
    config,
    cache,
    actions,
    user,
    routeConfig
  } = props

  const [ sigma, setSigma ] = useState({})
  const [ daily, setDaily ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const abortController = useRef(new AbortController())
  const keys = {
    delivered: '#73C677',
    unsubscribe: '#70859E',
    open: '#0CA6C8',
    click: '#10294D',
    spamreport: '#459948',
    bounce: '#FC495D',
    dropped: '#006DDC'
  }

  const fetchStatsEvents = () => {
    setLoading(true)
    new Promise((resolve, reject) => actions.fetchMarketingEmailStats({
      params: { id: model.id },
      signal: abortController.current.signal,
      resolve,
      reject
    })).then(r => {
      const data = []
      const stats = r.sigma
      setDaily(r.daily)
      const total = Object.keys(stats).map(s => stats[s]).reduce((a, b) => a + b, 0)
      Object.keys(stats).forEach(event => {
        const percent = Math.round(stats[event] / total * 100)
        let formatted_event = event
        if (!event.endsWith('ed')) {
          if (event.endsWith('e')) {
            formatted_event += 'd'
          } else {
            formatted_event += 'ed'
          }
        }
        if (event === 'spamreport') { formatted_event = 'Spam'}
        const d = {
          value: stats[event],
          label: `${capitalize(formatted_event)}`,
          fill: keys[event],
          sublabel: percent
        }
        data.push(d)
      })
      setSigma(data)
    }).catch(e => {
      // eslint-disable-next-line no-console
      console.error(e)
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    fetchStatsEvents()
    return () => {
      abortController.current.abort()
    }
  }, [])

  return (
    <HorizontalTabs
      config={routeConfig}
      location={props.location}
      match={props.match}
      model={model}
      defaultTab="details"
      user={{ permissions: user.permissions, agent: user.agent }}
    >

      {model.status === 'Sent' ? (
        <Tab tab="dashboard" label="Dashboard" noScroll>
          <div className="grid-container grid-1">
            <MarketingEmailEventSummaryWidget
              statistics={sigma}
              loading={loading}
            />
          </div>
          <MarketingEmailEventDetailWidget
            statistics={daily}
            loading={loading}
          />
        </Tab>
      ) : null }

      <Tab tab="details" label="Details" noScroll>
        <DetailsList {...props} />
      </Tab>

      <Tab tab="recipients" label="Recipients">
        <RecipientsManager
          location={props.location}
          match={props.match}
          model={model}
          user={user}
        />
      </Tab>


      <Tab tab="activity" label="Activity">
        <Activity
          model={model}
          cache={cache}
          config={config}
          events={model.activity}
          user={user}
          settings={{ currency: cache.settings[user.agent.site.id].default_currency }}
          agents={cache && cache.agents ? cache.agents : {}}
          fetchActivity={actions.fetchActivity}
          fetchOne={actions.fetchOne}
          fetchMany={actions.fetchMany}
        />
      </Tab>

    </HorizontalTabs>

  )
}

MarketingEmail.propTypes = {
  listingid: PropTypes.number,
  selected: PropTypes.array,
  model: PropTypes.object,
  actions: PropTypes.object,
  user: PropTypes.object,
  config: PropTypes.object,
  cache: PropTypes.object,
  routeConfig: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  settings: PropTypes.object
}

export default MarketingEmail
