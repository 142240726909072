/* eslint-disable new-cap */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { Map } from 'immutable'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { toggleWideSidebar, createModel, fetchMany, notifyUser } from '../actions'
import { CONFIG, MINUSER, CONFIGS, CACHE, MODEL, PREFERENCES } from '../selectors'
import RecipientSelectSidebar from '../components/RecipientSelectSidebar'


const RecipientSelectSidebarContainer = props => {
  const [ recipients, setRecipients ] = useState({
    contacts: [],
    profiles: [],
    agents: []
  })

  const selectOne = obj => {
    const newrecipients = { ...recipients }
    if (newrecipients[props.config.modelname].includes(obj.id)) {
      newrecipients[props.config.modelname] = newrecipients[props.config.modelname].filter(a => a !== obj.id)
    } else {
      newrecipients[props.config.modelname].push(obj.id)
    }
    setRecipients({ ...newrecipients })
  }

  const newprops = { ...props }
  newprops.actions.selectOne = selectOne
  newprops.selected = recipients[props.config.modelname]
  return <RecipientSelectSidebar { ...newprops } />
}

RecipientSelectSidebarContainer.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object,
  ui: PropTypes.object,
  app: PropTypes.object,
  actions: PropTypes.object,
  site: PropTypes.object,
  model: PropTypes.object,
  config: PropTypes.object,
  configs: PropTypes.object,
  cache: PropTypes.object,
  modelname: PropTypes.string,
  portals: PropTypes.object,
  settings: PropTypes.object,
  addSelected: PropTypes.func,
  addAll: PropTypes.func
}


function mapStateToProps(state, ownProps) {
  const cache = CACHE(state)
  const preferences = PREFERENCES(state, ownProps.modelname)
  let meta_cache = Map({ settings: {} })
  meta_cache = meta_cache.set('settings', cache.get('settings'))
  return {
    user: MINUSER(state),
    sidebar: state.getIn([ 'ui', 'sidebar' ]),
    cache,
    meta_cache,
    config: CONFIG(state, ownProps.modelname),
    configs: CONFIGS(state),
    model: MODEL(state, ownProps.modelname),
    tableconfig: Map({ column_preferences: preferences ? preferences : Map({}) })
  }
}

const mapDispachToProps = dispatch => ({
  actions: bindActionCreators({
    toggleWideSidebar,
    createModel,
    fetchMany,
    notifyUser
  }, dispatch) })


export default connect(mapStateToProps, mapDispachToProps)(withImmutablePropsToJS(RecipientSelectSidebarContainer))
