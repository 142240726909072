import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Formik } from 'formik'
import { NavLink } from 'react-router-dom'
import { getIn } from 'formik'
import merge from 'deepmerge'
import isEqual from 'react-fast-compare'
import Card from '../Card'
import CustomForm from '../forms/CustomForm'
import { Button } from '../../ui/Button'
import FieldGroup from '../forms/FieldGroup'
import { valueFormat, handleSubmitError } from '../../../utils'
import Loader from '../Loader'


class LeadInteractionsHistoryItem extends React.Component {
  constructor(props) {
    super(props)
    let interaction_type
    if (props.interaction.communication) {
      interaction_type = 'communication'
    } else if (props.interaction.viewing) {
      interaction_type = 'viewing'
    } else if (props.interaction.offer) {
      interaction_type = 'offer'
    } else if (props.interaction.archive) {
      interaction_type = 'archive'
    } else if (props.interaction.rental_application) {
      interaction_type = 'rental_application'
    }
    const initialValues = {
      ...getIn(props.interaction.meta, interaction_type),
      tags: props.interaction.tags,
      id: props.interaction.id
    }
    if (initialValues.feedback_other) {
      initialValues.viewing_concerns = { feedback_other_check: true }
      initialValues.feedback_other_check = true
    }
    this.state = {
      editing: false,
      config: getIn(props.interactionConfigs, interaction_type),
      initialValues,
      collapsed: props.collapsed
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { interaction } = this.props
    if (!isEqual(interaction, prevProps.interaction)) {
      let interaction_type
      if (interaction.communication) {
        interaction_type = 'communication'
      } else if (interaction.viewing) {
        interaction_type = 'viewing'
      } else if (interaction.offer) {
        interaction_type = 'offer'
      } else if (interaction.rental_application) {
        interaction_type = 'rental_application'
      }
      const initialValues = {
        ...getIn(interaction.meta, interaction_type),
        tags: interaction.tags,
        id: interaction.id
      }
      if (initialValues.feedback_other) {
        initialValues.viewing_concerns = { feedback_other_check: true }
      }
      this.setState({ initialValues })
    }
  }

  handleSubmit(values, formik) {
    formik.setSubmitting(true)
    new Promise((resolve, reject) => this.props.actions.updateLeadInteraction({
      modelid: this.props.lead.id,
      values,
      resolve,
      reject
    })).then(() => {
      formik.resetForm({})
      formik.setSubmitting(false)
      this.setState({ editing: false })
      this.props.actions.fetchAgain()
      this.props.actions.fetchInteractionsAgain()
    }).catch(e => {
      handleSubmitError(e, formik, this.form)
      formik.setSubmitting(false)
    })
  }

  render() {
    const {
      interaction,
      user,
      canEdit,
      model
    } = this.props
    let icon
    switch (getIn(interaction, 'meta.communication.communication_type')) {
      case 'Phone Call':
        icon = '#icon24-Phone'
        break
      case 'In Person':
        icon = '#icon24-Chat'
        break
      case 'SMS/WhatsApp':
        icon = '#icon24-WhatsApp'
        break
      default:
        icon = '#icon24-Envelope'
        if (getIn(interaction, 'viewing')) {
          icon = '#icon24-EyeOpen'
        }
        if (getIn(interaction, 'offer')) {
          icon = '#icon24-Star'
        }
        if (getIn(interaction, 'archive')) {
          icon = '#icon24-Archive'
        }
    }
    let username
    let canedit = false
    if (!interaction.agent) {
      username = 'System User'
    }
    if (interaction.agent && user.agent.id === interaction.agent) {
      username = 'You'
    }
    if (interaction.agent && getIn(interaction.meta, 'agent') && user.agent.id !== interaction.agent) {
      username = getIn(interaction.meta, 'agent.full_name')
    }
    if ((interaction.user === user.id && canEdit) || user.permissions.includes('is_prop_data_user')) {
      canedit = true
    }
    if (interaction.rental_application
      && !user.permissions.includes('is_prop_data_user')
      && !user.permissions.includes('applications_update')
      && (user.permissions.includes('applications_update_own') && user.agent.id !== interaction.agent)
    ) {
      canedit = false
    }
    const feedback = getIn(interaction, 'meta.communication.feedback') || getIn(interaction, 'meta.viewing.feedback') || getIn(interaction, 'meta.offer.feedback')
    const viewing_feedback = [
      getIn(interaction, 'meta.viewing.feedback_other'),
      getIn(interaction, 'meta.viewing.feedback_price') ? 'Price' : null,
      getIn(interaction, 'meta.viewing.feedback_location') ? 'Location' : null,
      getIn(interaction, 'meta.viewing.feedback_layout') ? 'Layout' : null,
      getIn(interaction, 'meta.viewing.feedback_size') ? 'Size' : null
    ].filter(n => n)
    const { settings } = this.props.cache
    let fields = merge([], getIn(this.state, 'config.fields', []))
    if (interaction.rental_application) {
      fields = fields.map(f => {
        if (f.name !== 'expiry_date') {
          f.readonly = true
        }
        return f
      })
    }
    return (
      <Card
        collapsable
        collapsed={this.state.collapsed || !this.state.editing}
        onCardToggle={isOpen => {
          if (!isOpen && this.state.editing) {
            this.setState({ editing: false, collapsed: true })
          } else {
            this.setState({ collapsed: false })
          }
        }}
        background
        classes="nopadding grey_25"
        header={(
          <div className='lead-interaction-item-header'>
            <div className={classNames('lead-interaction-item-icon', { Contacted: interaction.communication, Viewing: interaction.viewing, Offer: interaction.offer, Archived: interaction.archive, 'Rental-Application': interaction.rental_application })}>
              <svg viewBox='0 0 32 32'>`<use href={`/images/icons-24.svg${icon}`} /></svg>
            </div>
            <div className='flex-container lead-interaction-item-top'>
              <div>
                <strong>{username}</strong>
                <div className='flex-container lead-interaction-item-date'>
                  <span>{valueFormat('datetime', interaction.created)}</span>
                </div>
              </div>
            </div>
            {getIn(interaction, 'meta.offer.status') === 'Accepted' && model && model.listing_type === 'For Sale' ? (
              <span>
                <Button
                  component={NavLink}
                  className="btn btn-red btn-round"
                  to={`/secure/${model.site}/deals/add?sale_price=${getIn(interaction, 'meta.offer.offer_amount')}&listing_model=${model.model}&model_id=${model.id}&offer=${interaction.id}&buyers=${this.props.lead.id}`}
                >
                  Create Deal
                </Button>
              </span>
            ) : null}
            <span>
              {(!this.state.editing && canedit && getIn(interaction, 'meta.offer.status') === 'Accepted') ? (
                <Button
                  icon="#icon24-EyeOpen"
                  id="interaction-offer"
                  tabIndex="-1"
                  component={NavLink}
                  to={`/secure/${interaction.site}/offers/${interaction.id}/details`}
                  className="btn btn-icon-16 btn-icon-only btn-none"
                  type="button"
                  title="View Offer"
                />
              ) : null }
            </span>
            <span>
              {(!this.state.editing && canedit) ? (
                <Button
                  icon="#icon16-Edit"
                  className="btn btn-none btn-icon-only btn-icon-16"
                  title="Edit Tag"
                  onClick={() => {
                    this.setState({ editing: true, collapsed: false })
                  }} type="button"
                />
              ) : null }
            </span>
          </div>
        )}
        body={this.state.editing ? (
          <Formik
            initialValues={this.state.initialValues}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={this.handleSubmit}
            enableReinitialize={true}
          >{ formik => {
              this.form = formik
              return (
                <CustomForm
                  component={'div'}
                  className={'interaction-form'}
                  render={() => (
                    <>
                      <FieldGroup
                        card={false}
                        config={{
                          fields
                        }}
                        fields={fields}
                      />
                      {getIn(this.state, 'config.fields', []).length ? (
                        <div className="interaction-buttons">
                          {formik.isSubmitting ? (
                            <Loader inline />
                          ) : (
                            <>
                              <Button
                                id="interaction-submit"
                                tabIndex="-1"
                                type="button"
                                onClick={() => {
                                  formik.submitForm()
                                }}
                                disabled={formik.isSubmitting}
                                className="btn btn-primary"
                              >
                            Save
                              </Button>
                              <Button
                                id="interaction-submit"
                                tabIndex="-1"
                                type="button"
                                onClick={() => {
                                  this.setState({ editing: false })
                                }}
                                disabled={formik.isSubmitting}
                                className="btn btn-grey"
                              >
                            Cancel
                              </Button>
                            </>
                          )}
                        </div>
                      ) : null}
                    </>
                  )}
                />
              )
            }}
          </Formik>
        ) : (
          <div className='lead-interaction-item-body'>
            <div className='lead-interactions-values'>
              {interaction.communication ? (
                <>
                  <label>Interested in Viewing</label>
                  <div>{getIn(interaction, 'meta.communication.interested')}</div>
                  {getIn(interaction, 'meta.communication.viewing_date') ? (
                    <>
                      <label>Scheduled Viewing</label>
                      <div>{valueFormat('date', getIn(interaction, 'meta.communication.viewing_date'))}</div>
                    </>
                  ) : null}
                </>
              ) : null}
              {interaction.viewing ? (
                <>
                  <label>Interested</label>
                  <div>{getIn(interaction, 'meta.viewing.interested')}</div>
                  {getIn(interaction, 'meta.viewing.viewing_date') ? (
                    <>
                      <label>Viewing</label>
                      <div>{valueFormat('date', getIn(interaction, 'meta.viewing.viewing_date'))}</div>
                    </>
                  ) : null}
                  {viewing_feedback.length ? (
                    <>
                      <label>Concerns</label>
                      <div>{viewing_feedback.join(', ')}</div>
                    </>
                  ) : null}
                </>
              ) : null}
              {interaction.offer ? (
                <>
                  <label>Amount</label>
                  <div>{valueFormat('currency', getIn(interaction, 'meta.offer.offer_amount'), { currency: settings[user.agent.site.id].default_currency })}</div>
                  {getIn(interaction, 'meta.offer.offer_date') ? (
                    <>
                      <label>Offer Date</label>
                      <div>{valueFormat('date', getIn(interaction, 'meta.offer.offer_expiry_date'))}</div>
                    </>
                  ) : null}
                  <>
                    <label>Offer Status</label>
                    <div>{getIn(interaction, 'meta.offer.status')}</div>
                  </>
                </>
              ) : null}
              {interaction.rental_application ? (
                <>
                  {(getIn(interaction, 'meta.rental_application.deposit')) ? (
                    <>
                      <label>Deposit</label>
                      <div>{valueFormat('currency', getIn(interaction, 'meta.rental_application.deposit'), { currency: settings[user.agent.site.id].default_currency })}</div>
                    </>
                  ) : null}
                  {(getIn(interaction, 'meta.rental_application.key_deposit')) ? (
                    <>
                      <label>Key Deposit</label>
                      <div>{valueFormat('currency', getIn(interaction, 'meta.rental_application.key_deposit'), { currency: settings[user.agent.site.id].default_currency })}</div>
                    </>
                  ) : null}
                  {(getIn(interaction, 'meta.rental_application.utility_deposit')) ? (
                    <>
                      <label>Utility Deposit</label>
                      <div>{valueFormat('currency', getIn(interaction, 'meta.rental_application.utility_deposit'), { currency: settings[user.agent.site.id].default_currency })}</div>
                    </>
                  ) : null}
                  {(getIn(interaction, 'meta.rental_application.lease_fee')) ? (
                    <>
                      <label>Lease Fee</label>
                      <div>{valueFormat('currency', getIn(interaction, 'meta.rental_application.lease_fee'), { currency: settings[user.agent.site.id].default_currency })}</div>
                    </>
                  ) : null}
                  {(getIn(interaction, 'meta.rental_application.pro_rata_rent')) ? (
                    <>
                      <label>Pro-Rata Rent</label>
                      <div>{valueFormat('currency', getIn(interaction, 'meta.rental_application.pro_rata_rent'), { currency: settings[user.agent.site.id].default_currency })}</div>
                    </>
                  ) : null}
                  {(getIn(interaction, 'meta.rental_application.first_months_rent')) ? (
                    <>
                      <label>First Month&apos;s Rent</label>
                      <div>{valueFormat('currency', getIn(interaction, 'meta.rental_application.first_months_rent'), { currency: settings[user.agent.site.id].default_currency })}</div>
                    </>
                  ) : null}
                  {(getIn(interaction, 'meta.rental_application.expiry_date')) ? (
                    <>
                      <label>Expiry Date</label>
                      <div>{valueFormat('date', getIn(interaction, 'meta.rental_application.expiry_date'))}</div>
                    </>
                  ) : null}
                  <div style={{ display: 'flex', marginTop: '12px' }}>
                    <Button
                      id="interaction-submit"
                      tabIndex="-1"
                      component={NavLink}
                      type="button"
                      to={`/secure/${this.props.lead.site}/applications/${interaction.rental_application}`}
                      className="btn btn-primary"
                    >
                    View Application
                    </Button>
                  </div>
                </>
              ) : null}
              {interaction.archive ? (
                <>
                  <label>Reason</label>
                  <div>{getIn(interaction, 'meta.archive.reason')}</div>
                </>
              ) : null}
            </div>
            {feedback ? (
              <>
                <label>Note</label>
                <span>{feedback}</span>
              </>
            ) : null}
            <div className="lead-interaction-tags">
              {getIn(interaction, 'meta.tags', []).length ? (
                getIn(interaction, 'meta.tags').map(tag => <span key={`tag-${tag.id}`} className='lead-interaction-tag tag'>{tag.label}</span>)
              ) : null}
            </div>
          </div>
        )}
      />
    )
  }
}


LeadInteractionsHistoryItem.propTypes = {
  interaction: PropTypes.object,
  model: PropTypes.object,
  config: PropTypes.object,
  lead: PropTypes.object,
  user: PropTypes.object,
  interactionConfigs: PropTypes.object,
  cache: PropTypes.object,
  actions: PropTypes.object,
  collapsed: PropTypes.bool,
  canEdit: PropTypes.bool
}


class LeadInteractionsHistory extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      interactions: props.interactions,
      has_more: false
    }
    this.fetchInteractionsAgain = this.fetchInteractionsAgain.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    const { interactions } = this.props
    if (prevState.interaction !== this.state.interaction) {
      this.setState({ config: getIn(this.configs, this.state.interaction, {}) })
    }
    if (!isEqual(prevProps.interactions, this.props.interactions)) {
      this.setState({ interactions: interactions })
    }
  }

  fetchInteractionsAgain() {
    const { actions, config, model, lead } = this.props
    new Promise((resolve, reject) => actions.fetchViewingFeedback({
      modelname: config.modelname,
      action: 'interactions',
      modelid: model.id,
      params: {
        lead: lead.id,
        order_by: '-created'
      },
      resolve,
      reject
    })).then(r => {
      this.setState({ interactions: r.results, has_more: !!r.next })
    }).catch(e => {
      if (e.status !== 408) {
        console.error(e)
      }
    })
  }

  render() {
    return this.state.interactions.length ? (
      this.state.interactions.map((interaction, idx) => <LeadInteractionsHistoryItem key={`interaction-${interaction.id}`} collapsed={idx > 0} {...this.props} actions={{ ...this.props.actions, fetchInteractionsAgain: this.fetchInteractionsAgain }} interaction={interaction} />)
    ) : null
  }
}

LeadInteractionsHistory.propTypes = {
  interaction: PropTypes.object,
  model: PropTypes.object,
  config: PropTypes.object,
  lead: PropTypes.object,
  interactions: PropTypes.array,
  user: PropTypes.object,
  actions: PropTypes.object,
  refresh: PropTypes.bool,
  canEdit: PropTypes.bool
}

export default LeadInteractionsHistory
