import React from 'react'
import isEqual from 'react-fast-compare'
import merge from 'deepmerge'

import FieldGroup from '../common/forms/FieldGroup'
import { isConditional, getDisplayName, uniqueArray, sortBy, convertArrayToObject, overwriteMerge } from '../../utils'


export function withReport(ActivityComponent) {
  return class ReportComponent extends ActivityComponent {
    static displayName = `ReportComponent(${getDisplayName(ActivityComponent)})`

    constructor(props) {
      super(props)
      this.isConditional = isConditional.bind(this)
      this.updateTemplates = this.updateTemplates.bind(this)
      this.redirectSchema = this.redirectSchema.bind(this)
      this.addVerb = this.addVerb.bind(this)
      this.fetchTemplates = this.fetchTemplates.bind(this)
    }

    redirectSchema(schema) { this.setState({ redirect: schema }) }

    addVerb(bag) {
      const { fields } = this.state
      if (fields) {
        const report_fields = fields.map((field, ind) => {
          const f = {
            ...field,
            name: `${field.name}${field.verb ? `__${field.verb}` : ''}`,
            cols: 'lg-6',
            key: `adv-${field.name}-${ind}`,
            edit: true,
            group: 'Report Details',
            updateTemplates: field.name === 'template' ? this.updateTemplates : null
          }
          if (field.input === 'LocationSelect' && [ 'locations', 'suburbs' ].includes(field.modelname) && this.state.locations) {
            f.locations = this.state.locations
          }
          if (field.input === 'LocationSelect' && field.modelname === 'areas' && this.state.areas) {
            f.areas = this.state.areas
          }
          return f
        })
        return <FieldGroup
          form={bag}
          card={false}
          groupname="Report Details"
          match={this.props.match}
          config={{
            fields
          }}
          fields={report_fields}
        />
      }
      return null
    }

    updateTemplates(templates) {
      if (!isEqual(templates, this.state.templates)) {
        this.setState({ templates })
      }
    }

    fetchTemplates() {
      const { config } = this.props
      new Promise((resolve, reject) => this.props.actions.fetchMany({
        values: {
          modelname: 'templates',
          conflict: true,
          params: {
            template_type: 'report',
            order_by: 'ordinal',
            model: config.servicename,
            get_all: 1
          }
        },
        resolve,
        reject
      })).then(r => {
        const template_config = r.options[0]
        this.setState({ template_cache: convertArrayToObject(r.options, 'name'), template_config })
      })
    }

    initModel(template_config) { // Initialise model data for formik
      const { config } = this.props
      const initvals = {}
      const fields = []
      config.reports.fields.forEach(f => {
        if (f.defaultvalue) {
          initvals[f.name] = f.defaultvalue
        }
        if (f.name === 'template') {
          initvals.template = this.state.template_configs.length ? this.state.template_configs[0].name : null
        }
        fields.push(f)
      })
      if (template_config) {
        initvals.template = template_config.name
      }
      if (initvals.template) {
        const template = this.state.template_cache[initvals.template]
        if (template && template.fields) {
          template.fields.forEach(f => {
            let field = null
            if (typeof f === 'string') {
              field = config.fields.find(fe => fe.name === f)
            } else {
              field = config.fields.find(fe => fe.name === f.name)
              if (field) {
                field = merge(field, f, { arrayMerge: overwriteMerge })
              } else {
                field = f
              }
            }
            if (field) {
              if (field.defaultvalue) {
                initvals[`${field.name}${field.verb ? `__${field.verb}` : ''}`] = field.defaultvalue
              }
              fields.push(field)
            }
          })
        }
      }
      return { fields, initvals }
    }

    componentDidMount() {
      const { fields, initvals } = this.initModel()
      if (!isEqual(this.state.initvals, initvals)) {
        this.setState({ fields, initvals })
      }
      return super.componentDidMount && super.componentDidMount()
    }

    componentDidUpdate(prevProps, prevState) {
      if (
        !isEqual(prevState.template_cache, this.state.template_cache) ||
        !this.state.template_configs.length
      ) { // Model has loaded after mount
        const field = this.props.config.reports.fields.find(f => f.name === 'template')
        let templates = []
        let configs = []
        Object.keys(this.state.template_cache).forEach(o => {
          const template = this.state.template_cache[o]
          configs.push(template)
          templates.push({ value: template[field.optionvalue], label: template[field.optionlabel] })
        })
        configs = sortBy(configs, 'ordinal')
        templates = uniqueArray(templates, 'value')
        if (!isEqual(configs, this.state.template_config) && !isEqual(this.state.templates, templates)) {
          this.setState({ template_configs: configs, templates: uniqueArray(templates, 'value') })
        }
      }

      if (!this.state.initvals.template
        && Object.keys(this.state.template_cache).length
      ) { // Model has loaded after mount
        const { fields, initvals } = this.initModel()
        if (!isEqual(this.state.initvals, initvals)) {
          this.setState({ fields, initvals })
        }
      }
      return super.componentDidUpdate && super.componentDidUpdate()
    }

    render() {
      return super.render()
    }
  }
}

export default withReport
