import { Formik, getIn, setIn } from 'formik'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import isEqual from 'react-fast-compare'
import merge from 'deepmerge'

import { groupBy, buildOptionLabel, uniqueArray, resolveError } from '../utils'
import { Button } from './ui/Button'
import { Scrollbar } from './ui/Scrollbars'
import CustomForm from './common/forms/CustomForm'
import FieldGroup from './common/forms/FieldGroup'


const mergeOptions = (newResults, props) => {
  const results = newResults.map(o => ({
    value: getIn(o, 'meta.category.id'),
    label: getIn(o, 'meta.category.name')
  })).filter(o => o.value)
  if (props.labelgrouper) {
    const grouped_results = uniqueArray(results.map(group => {
      group.options = newResults.filter(g => getIn(g, 'meta.category.id') === group.value).map(g => buildOptionLabel(props, g))
      return group
    }), 'value', true)
    const ungrouped_results = newResults.filter(r => !getIn(r, 'meta.category.id')).map(g => buildOptionLabel(props, g))
    const all_options = [ ...ungrouped_results, ...grouped_results ]
    return all_options
  }
  return []
}


const ReportEditSidebar = props => {
  const { actions, config, field, match, modelname, templates, report, setEditTemplate, modelconfig } = props
  const [ fields, setFields ] = useState({})
  const [ groups, setGroups ] = useState([])
  const [ initvals, setInitvals ] = useState({
    modelname,
    ...report
  })
  const [ selected_template, setSelectedTemplate ] = useState(report?.template)

  useEffect(() => {
    const template = templates?.find(t => t.id === selected_template)
    if (!report && template) {
      setInitvals({ ...merge({}, initvals), name: `${template.display_name} - `, template: template.id })
    }
    const base_groups = Object.keys(config.fieldgroups)
    const template_fields = (template?.fields || []).map(f => {
      let actualField
      let newField = {}
      if (typeof f === 'string') {
        actualField = modelconfig.fields.find(fe => fe.name === f)
      } else if (f.name) {
        actualField = modelconfig.fields.find(fe => isEqual(fe.name, f.name))
      }
      if (actualField) {
        if (typeof f === 'string') {
          newField = merge({}, { ...actualField, required: false })
        } else {
          newField = merge(actualField, f)
        }
      } else {
        newField = { ...f }
      }
      newField.group = f.group || 'Criteria'
      newField.name = `report_filters.${newField.name}${newField.verb ? `__${newField.verb}` : ''}`
      return newField
    })
    const field_groups = groupBy([ ...config.fields, ...template_fields ], 'group')
    setFields(field_groups)
    const sorted_groups = Object.keys(field_groups)
      .sort((a, b) => base_groups.indexOf(b) - base_groups.indexOf(a))

    setGroups(sorted_groups)
  }, [ selected_template ])

  const handleSubmit = (values, form) => {
    if (values.noSubmit) {
      const { template, ...vals } = values
      Object.keys(vals).forEach(k => {
        // Remove empty values
        if ([ null, undefined ].includes(vals[k])) {
          delete vals[k]
        }
        if (Array.isArray(vals[k]) && !vals[k].length) {
          delete vals[k]
        }
      })
      setTimeout(() => {
        form.setSubmitting(false) // Set to false here so form can be submitted again
      }, 300)
      new Promise((resolve, reject) => {
        const params = {
          params: vals,
          args: {
            action: 'report',
            template
          },
          modelname: modelconfig.modelname,
          label: templates.find(t => t.id === selected_template).display_name,
          resolve,
          reject
        }
        return actions.exportData(params)
      }).catch(e => {
        console.error(e)
      }).finally(() => {
        form.setSubmitting(false)
      })
      return
    }
    const fieldnames = Object.keys(fields).map(group => fields[group])
      .reduce((a, b) => merge(a, b), []).map(f => f.name).filter(fname => fname !== 'report_filters')
    fieldnames.push('id')

    let clean = {}
    fieldnames.forEach(fname => {
      const val = getIn(values, fname)
      if (val) {
        clean = setIn(clean, fname, val)
      }
    })
    if (values.id) {
      new Promise((resolve, reject) => actions.updateModel({
        values: {
          ...clean,
          modelname: 'reports'
        },
        resolve,
        reject
      })).then(() => {
        setEditTemplate(null)
        actions.toggleWideSidebar()
      }).catch(e => {
        actions.notifyUser({ title: 'Error', body: resolveError(e, Object.keys(fields).map(group => fields[group]).reduce((a, b) => merge(a, b), [])) || e.error, type: 'error' })
      })
    } else {
      new Promise((resolve, reject) => actions.createModel({
        values: {
          ...values,
          modelname: 'reports'
        },
        resolve,
        reject
      })).then(() => {
        setEditTemplate(null)
        actions.toggleWideSidebar()
      }).catch(e => {
        actions.notifyUser({ title: 'Error', body: resolveError(e, Object.keys(fields).map(group => fields[group]).reduce((a, b) => merge(a, b), [])) || e.error, type: 'error' })
      })
    }
  }
  return (
    <div id="report-edit-sidebar" className="report-edit-sidebar wide-sidebar">
      <div className="wide-sidebar-container">
        <Formik
          initialValues={initvals}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >{ formik => (
            <CustomForm
              onChange={(changes, form) => {
                if (report && form.values.template && !formik.touched.template) {
                  formik.setFieldTouched('template') // enable button as soon as templates are loaded
                }
                if (changes.includes('template')) {
                  setSelectedTemplate(form.values.template)
                }
              }}
              render={() => (
                <div className="wide-sidebar-pane">
                  <div className="wide-sidebar-heading">
                    <h4>Report Criteria</h4>
                    <Button
                      type="button"
                      icon="#icon24-X-Large"
                      className="btn btn-none btn-icon btn-icon-24 btn-wide-sidebar-close"
                      onClick={() => {
                        actions.toggleWideSidebar()
                        setEditTemplate(null)
                      }}
                    />
                  </div>
                  <Scrollbar
                    style={{ height: 'calc(100vh - 218px)' }}
                    renderView={({ style, ...rest }) => <div {...rest} style={{ ...style, position: 'relative', height: 'calc(100% + 15px)' }} className="scrollview"/>}
                  >
                    <div className="wide-sidebar-content">
                      <>
                        { groups.map((group, gidx) => (
                          <FieldGroup
                            key={`fs-${gidx}`}
                            groupname={group}
                            group={{}}
                            gidx={gidx}
                            fields={fields[group].map(f => {
                              if (f.name === 'template' && templates) {
                                f.options = mergeOptions(templates, {
                                  optionlabel: 'display_name',
                                  optionvalue: 'id',
                                  labelseparator: '',
                                  labelformat: '',
                                  labelgrouper: 'meta.category.name'
                                })
                              }
                              return f
                            })}
                            creator={field?.name}
                            config={config}
                            match={match}
                            columns
                            render={({ renderFieldGroup, hidden }) => {
                              if (hidden) { return null }
                              return (
                                <fieldset className='editgroup'>
                                  <h5>{group}</h5>
                                  {renderFieldGroup(group)}
                                </fieldset>
                              )
                            }}
                          />
                        ))}
                        <div className="wide-sidebar-footer">
                          <Button
                            onClick={e => {
                              formik.setFieldValue('noSubmit', true, false).then(() => {
                                formik.submitForm(e)
                              })
                            }}
                            type="button" // This cannot be submit otherwise sibling form is submitted
                            className="btn btn-red"
                          >
                            Generate
                          </Button>
                          <Button
                            onClick={e => {
                              formik.setFieldValue('noSubmit', undefined, false).then(() => {
                                formik.submitForm(e)
                              })
                            }}
                            type="button" // This cannot be submit otherwise sibling form is submitted
                            className="btn btn-primary"
                          >
                            Save
                          </Button>
                        </div>
                      </>
                    </div>
                  </Scrollbar>
                </div>
              )}
            />
          )}
        </Formik>
      </div>
    </div>
  )
}

ReportEditSidebar.propTypes = {
  config: PropTypes.object,
  modelname: PropTypes.string,
  field: PropTypes.object,
  siblingform: PropTypes.object,
  actions: PropTypes.object,
  user: PropTypes.object,
  cache: PropTypes.object,
  readOnly: PropTypes.bool,
  comment: PropTypes.oneOfType([ PropTypes.object, PropTypes.bool ]),
  note: PropTypes.object,
  modelid: PropTypes.number,
  match: PropTypes.object,
  report: PropTypes.object,
  sidebar: PropTypes.string,
  modelconfig: PropTypes.object,
  setEditTemplate: PropTypes.func,
  templates: PropTypes.array
}

export default ReportEditSidebar
