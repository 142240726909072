/* eslint-disable new-cap */
import merge from 'deepmerge'
import { getIn, setIn } from 'formik'
import { hasIn, List, Map } from 'immutable'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import isEqual from 'react-fast-compare'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import * as actions from '../../../actions'
import { ADDONS, CACHE, CONFIG, CONFIGS, SELECTED, SETTINGS, SITE, FIELDGROUP_USER, LOCATION } from '../../../selectors'
import { isConditional, parseClasses, convertArrayToObject, parseURL, hasPermission, useCustomCompareMemo } from '../../../utils'
import Card from '../Card'
import FieldComponent from './FieldComponent'
import { formikConnect } from './customFormikConnect'


const FieldGroup = props => {
  const getWatched = (fields, locationState) => {
    if (!props.form) {
      return []
    }
    return fields.filter(field => field.edit).map(field => {
      const field_name = field.parent ? `${field.parent}.${field.name}` : field.name
      const touched = getIn(props.form.touched, field_name)
      const f = {
        ...field,
        // Watched field immutables which should not change
        classes: field.classes ? parseClasses(field.classes, props.form.values) : '',
        collapsed: props.collapsed,
        required: props.required,
        quality: props.quality,
        webedit: props.webedit,
        touched,
        _disabled: isConditional(field, 'disabled', false, props.form, props.user, props.cache).toString(),
        _edit: isConditional(field, 'edit', false, props.form, props.user, props.cache).toString(),
        _required: isConditional(field, 'required', false, props.form, props.user, props.cache).toString(),
        _webedit: isConditional(field, 'webedit', false, props.form, props.user, props.cache).toString(),
        _quality: isConditional(field, 'quality', false, props.form, props.user, props.cache).toString(),
        _value: getIn(props.form.values, field_name),
        defaultValue: getIn(props.form.initialValues, field_name),
        _errors: props.form.errors,
        _areas: !!locationState.areas,
        _suburbs: !!locationState.locations
      }
      if (field.modelname) {
        if (!f.cache) {
          f.cache = {}
        }
        f.cache[field.modelname] = props.cache[field.modelname]
      }
      if (field.fields) {
        field.fields.forEach(fafield => { // Field arrays
          if (fafield.modelname) {
            if (!f.cache) {
              f.cache = {}
            }
            f.cache[fafield.modelname] = props.cache[fafield.modelname]
          }
        })

        // Populate the child fields if it is a ParkingRatio component:
        if (field.input === 'ParkingRatio') {
          field.fields.forEach(fafield => {
            if (!fafield._value) {
              fafield._value = getIn(props.form.values, fafield.name)
            }
            if (!fafield.defaultValue) {
              fafield.defaultValue = getIn(props.form.initialValues, fafield.name)
            }
          })
        }
      }
      if ([ 'FileDropzone', 'ImageCrop', 'GallerySelector', 'AssetSelector' ].includes(field.input)) {
        // These components rely on meta data that needs to trigger updates as well
        const { values } = props.form
        if (values.id && props.cache[props.config.modelname][values.id] &&
          props.cache[props.config.modelname][values.id].meta &&
          props.cache[props.config.modelname][values.id].meta[field_name]) {
          if (Array.isArray(values[field_name]) && values.id) { // Edit mode and value is array
            values[field_name].forEach(() => {
              f.meta = merge({}, props.cache[props.config.modelname][values.id].meta[field_name])
            })
          } else if (values.id) { // Populate meta for ImageCrop
            f.meta = merge({}, props.cache[props.config.modelname][values.id].meta[field_name])
          }
        } else if ([ 'images', 'documents' ].includes(props.config.modelname)) {
          if (props.cache[props.config.modelname][values.id]) {
            if (!f.meta) {
              f.meta = []
            }
            f.meta.push(props.cache[props.config.modelname][values.id])
          }
        }
      }
      if (field.caches) {
        if (!f.cache) {
          f.cache = {}
        }
        field.caches.forEach(k => {
          f.cache[k] = getIn(props.cache, k)
        })
      }
      if (field.watch) {
        f._related = field.watch.map(val => {
          const wfield = props.config.fields.find(fe => fe.name === val)
          if (wfield) {
            const value = getIn(props.form.values, val)
            const cache = {}
            if (Array.isArray(value)) {
              value.forEach(v => {
                cache[v] = getIn(props.cache, `${wfield.modelname}.${v}`)
              })
            } else if (value) {
              cache[value] = getIn(props.cache, `${wfield.modelname}.${value}`)
            }
            return { value, cache }
          }
          return null
        })
      }
      if ([ 'TextNotes', 'CheckNotes', 'SelectNotes' ].includes(field.input)) {
        f._related = f._related || []
        const note_value = getIn(props.form.values, `${field.notes_name ? field.notes_name : `${field.name}_notes`}`)
        f._related.push({ value: note_value, cache: {} })
      }
      if (field.dependent) {
        f._dependent = getIn(props.form.values, field.dependent)
      }
      if (field.limit) {
        f._limit = props.form.status
      }
      return f
    })
  }

  const getFields = () => {
    const { fields, groupname, user, model } = props
    const newfields = fields.filter(field =>
      field.group === groupname &&
        field.edit &&
        hasPermission(field.permissions, user.permissions)
    ).map(field => {
      if (field.group === 'Publish' && field.name.split('.').length > 2) {
        const parts = field.name.split('.')
        const meta = parts[0]
        const pslug = parts[1]
        if (meta === 'portals') {
          if (!getIn(props, 'active_portals', []).includes(pslug)) { // If portal is not active in agency settings, hide input
            field.edit = false
          }
        }
      }
      if (field.input === 'ContactLookup') {
        field.model = model
      }
      return field
    })
    return newfields
  }

  const renderCopyAddress = (fieldTo, fieldFrom) => (
    <button
      id={`${props.creator}_same_as_physical_address`}
      name="same_as_physical_address"
      onClick={e => {
        e.preventDefault()
        const { form } = props
        const val = form.values[fieldFrom.name]
        form.setFieldValue(fieldTo.name, val).then(() => {
          document.getElementsByName(fieldTo.name)[0].value = val
          form.setFieldTouched(fieldTo.name)
        })
      }}
      className="btn btn-none link"
    >Copy to Postal Address</button>
  )

  const fieldClasses = (field, errors, bulkedit) => {
    const required = field._required === 'true' && !bulkedit
    const read_only = field._disabled === 'true' || field.readonly === true || isConditional(field, 'readonly', false, props.form, props.user, props.cache)
    return classNames({
      field: field.input !== 'FieldArray',
      required: required,
      disabled: read_only,
      quality: field._quality === 'true',
      error: (field.name in errors)
    }, field.cols ? ` col-${field.cols}` : ' col-lg-12')
  }

  const [ overwritten, setOverwritten ] = useState({})
  const [ fields, setFields ] = useState(getFields())
  const [ modelname, setModelname ] = useState(props.modelname)
  const [ locationState, setLocationState ] = useState({ areas: null, locations: null })
  const [ watched, setWatched ] = useState(getWatched(fields, locationState))
  const [ values, setValues ] = useState(props.form.values)


  const containsWebEdit = () => watched.find(field => field._webedit === 'true') !== undefined

  const containsRequired = () => watched.find(field => field._required === 'true') !== undefined

  const containsQuality = () => watched.find(field => field._quality === 'true') !== undefined

  const containsEditable = () => watched.find(field => {
    if (props.required) { return field._edit === 'true' && field._required === 'true' }
    if (props.quality) { return field._edit === 'true' && field._quality === 'true' }
    return field._edit === 'true'
  }) !== undefined

  const renderField = (key, field, errors, label = false) => {
    /* Filter props so that we only pass what an input requires */
    // eslint-disable-next-line no-unused-vars, no-shadow
    const { toggle, group, config, cache, ...newprops } = props
    newprops.modelname = props.config.modelname
    newprops.cache = {}
    newprops.setIgnoreFields = props.setIgnoreFields
    newprops.cache.settings = props.cache.settings // Insert the settings for extraparams lookups when parsing URLs
    const model_id = values.id || props.modelid
    if (model_id && newprops.defaultvalue) { delete newprops.defaultvalue } // No defaults required for edit
    const name = field.name.replace('override-', '')
    if (field.input === 'ResetButton') { return null }
    if (field.modelname || field.fields || [ 'FileDropzone', 'ImageCrop', 'GallerySelector', 'AssetSelector' ].includes(field.input)) { // Related model fields, fieldarrays and dropzones need cache
      if (props.cache[field.modelname] && ![ 'FileDropzone', 'ImageCrop', 'GallerySelector', 'AssetSelector' ].includes(field.input)) {
        newprops.cache[field.modelname] = props.cache[field.modelname]
      } else if (field.fields) { // Field Array
        field.fields.forEach(fafield => {
          if (fafield.modelname && props.cache[fafield.modelname]) {
            newprops.cache[fafield.modelname] = props.cache[fafield.modelname]
          }
        })
      } else if (model_id && (
        getIn(props, `cache.${props.config.modelname}.${model_id}.meta.${name}`))
      ) {
        if (Array.isArray(values[name]) && model_id) { // Edit mode and value is array
          values[name].forEach(() => {
            newprops.meta = merge({}, props.cache[props.config.modelname][model_id].meta[name])
          })
        } else if (model_id) { // Populate meta for ImageCrop
          newprops.meta = merge({}, props.cache[props.config.modelname][model_id].meta[name])
        }
      } else if ([ 'images', 'documents' ].includes(props.config.modelname)) {
        if (props.cache[props.config.modelname][model_id]) {
          if (!newprops.meta) {
            newprops.meta = []
          }
          newprops.meta.push(props.cache[props.config.modelname][model_id])
        }
      }
    }
    if (field.caches) {
      field.caches.forEach(mn => {
        newprops.cache[mn] = { ...props.cache[mn] }
      })
    }

    if (locationState.areas && field.name === 'area') {
      newprops.force_filter = locationState.areas
    }

    if (locationState.locations && field.name === 'suburb') {
      newprops.force_filter = locationState.locations
    }

    if (props.match && field.usemeta && hasIn(props.cache, [ props.config.modelname, props.match.params.id, 'meta' ])) {
      newprops.cache.meta = { ...props.cache[props.config.modelname][props.match.params.id].meta }
    }
    newprops.error = getIn(errors, newprops.name)
    return <FieldComponent {...newprops} key={`field-${field.name}`} field={field} removeLabels={label} />
  }

  const renderFieldGroup = () => {
    /*
     * This renders a group of fields on a card
     * so long as there are editable fields in the group.
    */
    const { errors, touched } = props.form
    const { gidx, bulkedit, config, groupname, group, classes } = props
    const unsetvals = {}
    const newvals = merge({}, props.form.values)
    const reapplyvals = {}
    const outer = watched.filter(field => {
      if ( // We need to unset fields that are no longer editable but have values in the formik state
        Object.keys(touched).length > 0 && // We've been touched
        field._edit === 'false' && // No editing of this field
        field.group !== 'Publish' && // No portal fields should be affected ever
        getIn(values, field.name) && // Field has a value in formik state
        config.fields.filter(cf => cf.name === field.name).length === 1) { // No dupe named fields
        unsetvals[field.name] = null
        delete newvals[field.name]
      }
      if ( // Need to reassign form values if editable __again__ and already set in group state
        Object.keys(touched).length > 0 && // We've been touched
        field._edit === 'true' && // Allow editing of this field
        field.group !== 'Publish' && // No portal fields should be affected ever
        !getIn(values, field.name) && // Field DOESN'T have a value in formik state
        overwritten[field.name] && // Field has a value in the group's state
        config.fields.filter(cf => cf.name === field.name).length === 1) { // No dupe named fields
        reapplyvals[field.name] = overwritten[field.name]
        newvals[field.name] = overwritten[field.name]
      }
      // Unset any uneditable fields in formik or else reapply any editable overwritten fields with their old values
      if (Object.keys(unsetvals).length > 0 || Object.keys(reapplyvals).length > 0) {
        props.form.setValues(newvals, false)
      }

      if (props.webedit) {
        return field._edit === 'true' && field._webedit === 'true'
      }

      if (props.required) {
        return field._edit === 'true' && field._required === 'true'
      }

      if (props.quality) {
        return field._edit === 'true' && field._quality === 'true'
      }
      return field._edit === 'true'
    })
    const outer_hidden = outer.filter(field => field.input === 'Hidden').map(field => (
      <React.Fragment key={`hidden-${field.name}`}>{renderField(`fc-${field.name}`, field, errors)}</React.Fragment>
    ))
    const outer_visible = outer.filter(field => field.input !== 'Hidden').map((field, ind) => {
      const inner = []
      if (Array.isArray(field.name)) { // This is a composite field
        const compositeFields = props.config.fields.filter(subfield => field.name.includes(subfield.name))
        const sub = compositeFields.map(() =>
          renderField(`fc-${field.name}`, field, errors, true)).filter(node => node)
        const label = <label key={`label-${field.name}`} htmlFor={field.label}>{field.label}</label>
        inner.push(<div key={`field-${field.name}`} className='form-group grouped-fields'>{label}{sub}</div>)
      } else if (field.copy) { // This is a copyable field
        const fieldFrom = props.config.fields.find(f => field.copy === f.name)
        const input = renderField(`fc-${field.name}`, { ...field, prefix: renderCopyAddress(field, fieldFrom) }, errors)
        if (!input) { return null }
        inner.push(
          <div
            key={`field-${field.name}`}
            className={fieldClasses(field, errors, bulkedit)}
          >
            {input}
          </div>
        )
      } else { // A normal field component
        const input = renderField(`fc-${field.name}`, field, errors)
        if (!input) { return null }
        inner.push(
          <div
            key={`field-${field.name}`}
            className={fieldClasses(field, errors, bulkedit)}
          >
            {input}
          </div>
        )
      }
      if (props.buttons && (ind + 1) === outer.filter(f => f.input !== 'Hidden').length) {
        inner.push(props.buttons({
          group,
          gidx,
          groupname,
          classes
        }))
      }
      return inner
    }).filter(node => node)
    const groups = [ ...outer_hidden ]
    let current_group = []
    let count = 12
    let counter = 0
    outer_visible.forEach((node, idx) => {
      const node_clases = node.props && node.props.className ? node.props.className : ''
      const regex = /col(-[a-z]+)?-(\d+)/ig
      const matches = regex.exec(node_clases)
      current_group.push(node)
      if (matches) {
        const col = parseInt(matches.pop(), 10)
        count -= col
      }
      if (count <= 0 || idx === outer_visible.length - 1) {
        groups.push(<div key={`cols-${gidx}-${counter}`} className='input-group'>{current_group}</div>)
        current_group = []
        count = 12
        counter += 1
      }
    })
    return groups
  }

  useEffect(() => {
    if (getIn(props, 'match.params.model') === 'location-profiles' && props.fields.some(f => f.input === 'LocationSelect')) {
      new Promise((resolve, reject) => props.actions.fetchLocations({
        values: {
          modelname: 'listings',
          params: {
            status__in__not: [ 'valuation' ]
          }
        },
        resolve,
        reject
      })).then(results => {
        const areas = convertArrayToObject(results.areas)
        const locations = convertArrayToObject(results.locations)
        setLocationState({ areas, locations })
      })
    }

    if (modelname !== props.modelname) {
      setModelname(modelname)
      setWatched([])
      setFields(getFields())
    }
    if (!isEqual(fields, getFields())) {
      setFields(getFields())
    }
    if (!isEqual(values, props.form.values)) {
      setValues(props.form.values)
    }
    let newOverwritten = { ...overwritten }
    fields.forEach(field => {
      if (getIn(values, field.name) !== undefined && !isConditional(field, 'edit', false, props.form, props.user, props.cache)) {
        newOverwritten = setIn(newOverwritten, field.name, getIn(values, field.name))
      } else if (getIn(props, 'match.params.action') === 'add' && getIn(field, 'defaultvalue')) {
        if (![ null, undefined ].includes(field.defaultvalue)) {
          if (field.defaultvalue.replace) {
            newOverwritten = setIn(
              newOverwritten, field.name, parseURL(field.defaultvalue, { agent: props.user.agent })
            )
          } else {
            newOverwritten = setIn(newOverwritten, field.name, field.defaultvalue)
          }
          if (field.defaultvalue === 'Square Metres' && !props.addons.includes('metric_units')) {
            newOverwritten = setIn(newOverwritten, field.name, 'Square Feet')
          }
        }
      }
    })
    if (!isEqual(newOverwritten, overwritten)) {
      setOverwritten(newOverwritten)
    }
    const newWatched = getWatched(getFields(), locationState)
    if (!isEqual(watched, newWatched)) {
      setWatched(newWatched)
    }
  }, [ props.modelname, useCustomCompareMemo(props.form.values), useCustomCompareMemo(props.fields) ])

  const { group, gidx, classes, card } = props
  let { groupname } = props
  if (props.modelname !== modelname) { return null }
  let hidden = false
  if (!containsEditable()) { hidden = true }
  if (props.webedit && !containsWebEdit()) { hidden = true }
  if (props.required && !containsRequired()) { hidden = true }
  if (props.quality && !containsQuality()) { hidden = true }
  if (groupname === 'Seller / Landlord Details' && props.form.values) {
    if (props.form.values.listing_type === 'For Sale') {
      groupname = 'Seller Details'
    } else if (props.form.values.listing_type === 'To Let') {
      groupname = 'Landlord Details'
    }
  }
  if (hidden) {
    return null
  }
  if (props.render) {
    return props.render({
      renderFieldGroup: renderFieldGroup,
      group,
      gidx,
      groupname,
      classes
    })
  }
  if (card !== false) {
    return (
      <Card
        key={`fgc-${gidx}`}
        id={props.id}
        background={true}
        collapsable={true}
        collapsed={props.collapsed}
        classes={classes} // So far only used for no padding on dropzones
        header={
          <h3>{groupname}</h3>
        }
        body={
          <div
            className={classNames('editgroup', { requiredonly: props.required })}
          >
            { renderFieldGroup(group) }
          </div>
        }
      />
    )
  }
  return (
    <div
      className={classNames('editgroup', { requiredonly: props.required, qualityonly: props.quality }, classes)}
    >
      { renderFieldGroup(group) }
    </div>
  )
}

FieldGroup.propTypes = {
  classes: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]),
  groupname: PropTypes.string,
  creator: PropTypes.string,
  group: PropTypes.object,
  render: PropTypes.func,
  gidx: PropTypes.number,
  required: PropTypes.bool,
  webedit: PropTypes.bool,
  quality: PropTypes.bool,
  collapsed: PropTypes.bool,
  config: PropTypes.object,
  cache: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  modelid: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  model: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]),
  active_portals: PropTypes.array,
  actions: PropTypes.object,
  form: PropTypes.object,
  bulkedit: PropTypes.bool,
  fields: PropTypes.array,
  toggle: PropTypes.bool,
  setIgnoreFields: PropTypes.func,
  columns: PropTypes.bool,
  addons: PropTypes.array,
  card: PropTypes.bool,
  user: PropTypes.object,
  modelname: PropTypes.string,
  id: PropTypes.string,
  buttons: PropTypes.func
}


const mapStateToProps = (state, ownProps) => { // Pass only minimal data to the FieldGroup component
  let modelname = ownProps.modelname ? ownProps.modelname : null
  let modelid = ownProps.modelid ? ownProps.modelid : null

  if (ownProps.match && ownProps.match.params) {
    modelname = modelname ? modelname : ownProps.match.params.model
    modelid = modelid ? modelid : ownProps.match.params.id
  }

  const site = SITE(state)
  const minuser = FIELDGROUP_USER(state)
  const siteid = site.get('id')
  const settings = SETTINGS(state, siteid)
  const cache = CACHE(state)
  const location = LOCATION(state)
  const selected = SELECTED(state, modelname)
  const addons = ADDONS(state)
  const config = modelname ? CONFIG(state, modelname) : ownProps.config
  const configs = CONFIGS(state)

  // Minimize cache
  let mincache = Map({ settings: Map({}) }) // We need to send only cache which field group needs
  mincache = mincache.mergeDeepIn([ 'settings', siteid ], settings)// We need settings for the current site
  mincache = mincache.set(`${modelname}`, Map({}))
  if (cache.get(modelname)) {
    if (ownProps.match && ownProps.match.params?.model !== 'settings') {
      mincache = mincache.mergeDeepIn([ modelname, modelid ], cache.getIn([ modelname, modelid ])) // We need the current model data in cache too
    } else { // Pass entire cache if no id
      mincache = mincache.mergeDeepIn([ modelname ], cache.get(modelname))
    }
  }
  ownProps.fields.forEach(field => {
    if (field.modelname) { mincache = mincache.set(field.modelname, cache.get(field.modelname)) }
    if (field.fields) { // Field array
      field.fields.forEach(fafield => {
        if (fafield.modelname) { mincache = mincache.set(fafield.modelname, cache.get(fafield.modelname)) }
      })
    }

    if (field.input === 'Associations') {
      const orig_modelname = ownProps.match ? ownProps.match.params.model : null
      const orig_modelid = ownProps.match ? ownProps.match.params.id : null
      field.associations = configs.getIn([ orig_modelname, 'associations' ]).toJS()
      field.recommendations = []
      if (orig_modelid) {
        field.recommendations = configs.getIn([ orig_modelname, 'fields' ])
          .filter(f => f.get('modelname') && configs.getIn([ orig_modelname, 'associations' ]).includes(f.get('modelname')))
          .map(f => ({ modelname: f.get('modelname'), value: cache.getIn([ orig_modelname, orig_modelid, f.get('name') ]) }))
      } else if (selected.has(orig_modelname) && selected.get(orig_modelname).count() === 1) {
        const model_id = selected.getIn([ orig_modelname, 0 ])
        field.recommendations = configs.getIn([ orig_modelname, 'fields' ])
          .filter(f => f.get('modelname') && configs.getIn([ orig_modelname, 'associations' ]).includes(f.get('modelname')))
          .map(f => ({ modelname: f.get('modelname'), value: cache.getIn([ orig_modelname, model_id, f.get('name') ]) }))
      }
      let recommendations = Map()
      field.recommendations.filter(rec => rec.value).forEach(rec => {
        let vals = List()
        if (recommendations.has(rec.modelname)) {
          if (List.isList(rec.value)) {
            vals = rec.value
          } else {
            vals = List([ rec.value ])
          }
        } else {
          recommendations = recommendations.set(rec.modelname, vals)
          if (List.isList(rec.value)) {
            vals = rec.value
          } else {
            vals = List([ rec.value ])
          }
        }
        recommendations = recommendations.set(rec.modelname, recommendations.get(rec.modelname).merge(vals))
      })
      field.recommendations = recommendations.keySeq().toArray().map(mname => {
        const value = recommendations.get(mname).toJS()
        return { modelname: mname, value }
      })
    }

    if (field.input === 'LocationSelect' || field.container === 'portals') { mincache = mincache.set('branches', cache.get('branches')) }

    if (field.caches) {
      field.caches.forEach(c => {
        if (!mincache.get(c)) {
          mincache = mincache.set(c, cache.get(c))
        }
      })
    }
  })


  if (cache && cache.getIn([ `${modelname}`, `${modelid}`, 'branch' ])) {
    // mincache = mincache.setIn([ 'branches', `${cache.getIn([ `${modelname}`, `${modelid}`, 'branch' ])}` ], cache.getIn([ 'branches', `${cache.getIn([ `${modelname}`, `${modelid}`, 'branch' ])}` ]))
    mincache = mincache.set('branches', cache.get('branches'))
  }

  return {
    cache: mincache,
    config,
    user: minuser,
    addons,
    modelname,
    location
  }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

const mergeProps = (stateProps, dispatchProps, ownProps) =>
  // need to collect actions from passed in props as well
  ({ ...ownProps, ...stateProps, actions: { ...dispatchProps.actions, ...ownProps.actions } })

const ConnectedFieldGroup = connect(
  mapStateToProps, mapDispatchToProps, mergeProps)(withImmutablePropsToJS(FieldGroup)
)

export default formikConnect(ConnectedFieldGroup)

