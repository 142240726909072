/* eslint-disable no-process-env */
/* eslint-disable new-cap */

import { encode } from 'base-64'
import { getIn, List, fromJS } from 'immutable'
import jwt_decode from 'jwt-decode'
import hash from 'object-hash'
import QRious from 'qrious'
import isEqual from 'react-fast-compare'
import { matchPath } from 'react-router'
import { all, call, put, select, takeEvery, takeLatest, join, fork } from 'redux-saga/effects'
import uuidv4 from 'uuid/v4'

import packageinfo from '../package.json'
import QueryBuilder from './components/common/QueryBuilder'
import cfg from './config/config.json'
import Column from './containers/Column'
import db from './db'
import log from './logging'
import * as stored from './selectors'
import { capitalize, generateWebsiteLink, getAllConfigs, isConditional, loadLocations, logEvent, request, resolveError, upload, parseURL } from './utils'


const apigw = cfg.defaultState.app.apigw
const socketwriter = cfg.defaultState.app.socket.writer

const tasks = new Map()

export class PDMSError extends Error {
  constructor(message, status = 400, raw = null) {
    super()
    this.status = status
    this.body = ''
    this.ok = false
    this.error = message
    this.token = null
    if (raw) {
      this.raw = raw
    }
  }
}

export function* reToke() {
  try {
    const t = yield select(stored.TOKEN)
    if (!t) { throw new PDMSError('No token provided') }
    const r = yield call(request, `${apigw}/users/api/v1/validate-request/`, { method: 'HEAD', headers: { Authorization: `Bearer ${t}` } })
    if (r.status && (r.status !== 200 && r.status < 500)) {
      yield put({ type: 'TOKE_ERROR', status: r.status })
    } else if (r.token) {
      const decoded = jwt_decode(r.token)
      yield put({ type: 'TOKE_SUCCESS', r, decoded })
    }
  } catch (e) {
    yield put({ type: 'TOKE_ERROR', message: JSON.stringify(e), status: 403 })
  }
}

export function* applyRetoke(action) {
  const { agents, user, redirect } = action
  const { retoke } = user
  const { siteid, agentid } = retoke
  const agent = agents.find(a => a.site.id === siteid && a.id === agentid)
  localStorage.setItem('token', agent.token)
  localStorage.setItem('site', siteid)
  localStorage.setItem('agent', agentid)
  yield put({ type: 'SELECT_AGENT', agent, user, redirect, noloader: true, resolve: action.resolve, reject: action.reject })
}


export function* fetchOne(action) {
  try {
    const loading = yield select(stored.LOADING)
    if (!loading && (action.wait && !action.noloader)) { yield put({ type: 'SHOW_LOADER', action }) }
    const t = action.token ? action.token : yield select(stored.TOKEN)
    if (!action.modelname) { throw new PDMSError('No modelname provided') }
    const { signal } = action
    const config = yield select(stored.CONFIG, action.modelname)
    let endpoint = config.getIn([ 'endpoint', 'read' ])
    const router = yield select(stored.ROUTER)
    let uri = `${apigw}${endpoint}/${action.id}/`
    if (config.getIn([ 'endpoint', 'parse' ])) {
      const match = matchPath(router.location.pathname, { path: '/secure/:site(\\d+)/:model(residential|commercial|theme-settings|holiday|agents)/:id(\\d+)', exact: false, strict: false })
      endpoint = parseURL(config.getIn([ 'endpoint', 'read' ]), match.params)
      uri = `${apigw}${endpoint}${endpoint.endsWith('/') ? '' : '/'}`
    }
    const qs = new QueryBuilder(uri)
    const fields = config.get('fields')
    const metafields = fields && fields.count() ? fields.filter(
      f => (f.get('modelname') && !f.get('container') && f.get('metafield') !== false) || f.get('name').indexOf('portals.') !== -1 || f.get('metafield')
    ).map(f => {
      if (f.get('name').indexOf('portals.') !== -1) { return f.get('name').split('.').shift() }
      return f.get('name')
    }) : List()
    let result = metafields.toSet().toList()
    if (!result.includes('statistics')) {
      result = result.push('statistics')
    }
    if (result.includes('username')) {
      result = result.set(result.indexOf('username'), 'user')
    }
    const removemeta = [ 'area', 'areas', 'suburbs', 'location' ]
    removemeta.forEach(meta => {
      if (metafields.includes(meta)) {
        result = result.delete(result.indexOf(meta))
      }
    })
    if (metafields) { qs.setParam('meta_fields', result.toJS()) }

    const delta = { }
    let token = t
    const id = Array.isArray(action.id) ? action.id[0] : action.id
    if (action.modelname === 'locations') { // Use local IDB store for locations
      delta[action.modelname] = { }
      delta[action.modelname][id] = yield call(async() => await db.transaction('r', db.suburbs, () => db.suburbs.get(id)))
    } else if (action.modelname === 'areas') { // Use local IDB store for locations
      delta[action.modelname] = { }
      delta[action.modelname][id] = yield call(async() => await db.transaction('r', db.areas, () => db.areas.get(id)))
    } else if (action.modelname === 'provinces') { // Use local IDB store for locations
      delta[action.modelname] = { }
      delta[action.modelname][id] = yield call(async() => await db.transaction('r', db.provinces, () => db.provinces.get(id)))
    } else if (action.modelname === 'countries') { // Use local IDB store for locations
      delta[action.modelname] = { }
      delta[action.modelname][id] = yield call(async() => await db.transaction('r', db.countries, () => db.countries.get(id)))
    } else {
      const r = yield call(request, qs.url(false, true), { method: 'GET', headers: { Authorization: `Bearer ${t}` }, signal: signal || null })
      if (!r.ok) { throw r }
      token = r.token
      delta[action.modelname] = { }
      delta[action.modelname][action.id] = JSON.parse(r.body)
    }
    if (action.modelname === 'leads') {
      const settings = yield select(stored.SETTINGS)
      Object.keys(delta[action.modelname][action.id]).map(f => {
        if ([ 'residential', 'commercial', 'projects', 'holiday' ].includes(f)) {
          if (
            delta[action.modelname][action.id][f]
            && delta[action.modelname][action.id].meta[f]
          ) { // For models such as leads which are linked to a listing type and id (ditch all the null fields)
            delta[action.modelname][action.id].model = capitalize(f)
            delta[action.modelname][action.id].meta.listing = delta[action.modelname][action.id].meta[f]
            delta[action.modelname][action.id].meta.listing.model = f
            delta[action.modelname][action.id].meta.listing.link = `/secure/${settings.get('id')}/${f}/${delta[action.modelname][action.id].meta[f].id}/details`
          }
        }
        return delta[action.modelname][action.id][f]
      })
    }
    if (action.modelname === 'offers') {
      const settings = yield select(stored.SETTINGS)
      Object.keys(delta[action.modelname][action.id].meta.lead.meta).map(f => {
        if ([ 'residential', 'commercial', 'projects', 'holiday' ].includes(f)) {
          if (
            delta[action.modelname][action.id].meta.lead[f]
            && delta[action.modelname][action.id].meta.lead.meta[f]
          ) { // For models such as leads which are linked to a listing type and id (ditch all the null fields)
            delta[action.modelname][action.id].meta.listing = delta[action.modelname][action.id].meta.lead.meta[f]
            delta[action.modelname][action.id].meta.listing.model = f
            delta[action.modelname][action.id].meta.listing.link = `/secure/${settings.get('id')}/${f}/${delta[action.modelname][action.id].meta.lead.meta[f].id}/details`
          }
        }
        return delta[action.modelname][action.id].meta.lead.meta[f]
      })
    }
    if (action.modelname === 'deals') {
      const settings = yield select(stored.SETTINGS)
      if (delta[action.modelname][action.id].meta.listing) {
        delta[action.modelname][action.id].meta.listing.link = `/secure/${settings.get('id')}/${delta[action.modelname][action.id].model_type}/${delta[action.modelname][action.id].model_id}/details`
      }
    }
    if (action.modelname === 'applications') {
      const settings = yield select(stored.SETTINGS)
      Object.keys(delta[action.modelname][action.id]).map(f => {
        if ([ 'residential', 'commercial', 'projects', 'holiday' ].includes(f)) {
          if (
            delta[action.modelname][action.id][f]
            && delta[action.modelname][action.id].meta[f]
          ) { // For models such as leads which are linked to a listing type and id (ditch all the null fields)
            delta[action.modelname][action.id].model = capitalize(f)
            delta[action.modelname][action.id].meta.listing = delta[action.modelname][action.id].meta[f]
            delta[action.modelname][action.id].meta.listing.model = f
            delta[action.modelname][action.id].meta.listing.link = `/secure/${settings.get('id')}/${f}/${delta[action.modelname][action.id].meta[f].id}/details`
          }
        }
        return delta[action.modelname][action.id][f]
      })
    }

    if (![ 'locations', 'areas', 'provinces', 'countries' ].includes(action.modelname)) {
      const record = delta[action.modelname][action.id]
      for (const f in record) {
        if (record[f]) {
          if ([ 'location', 'suburb' ].includes(f) && ![ 'branches', 'franchises' ].includes(action.modelname)) {
            const loc = record[f]
            const location = yield call(async() => await db.transaction('r', db.suburbs, () => db.suburbs.get(loc)))
            if (location) {
              delta[action.modelname][action.id].meta[f] = location
            }
          }
          if ([ 'area' ].includes(f)) {
            const loc = record[f]
            const location = yield call(async() => await db.transaction('r', db.areas, () => db.areas.get(loc)))
            if (location) {
              delta[action.modelname][action.id].meta[f] = location
            }
          }
          if ([ 'province' ].includes(f) || ([ 'location' ].includes(f) && [ 'branches', 'franchises' ].includes(action.modelname))) {
            const loc = record[f]
            const location = yield call(async() => await db.transaction('r', db.provinces, () => db.provinces.get(loc)))
            if (location) {
              delta[action.modelname][action.id].meta[f] = location
            }
          }
          if ([ 'locations', 'suburbs' ].includes(f)) {
            const loc = record[f]
            const location = yield call(async() => await db.transaction('r', db.suburbs, () => db.suburbs.where('id').anyOf(loc).toArray()).catch(e => log.error(e)))
            if (location) {
              delta[action.modelname][action.id].meta[f] = location
            }
          }
          if ([ 'areas' ].includes(f)) {
            const loc = record[f]
            const location = yield call(async() => await db.transaction('r', db.areas, () => db.areas.where('id').anyOf(loc).toArray()))
            if (location) {
              delta[action.modelname][action.id].meta[f] = location
            }
          }
          if ([ 'tags' ].includes(f)) {
            // Hide tags that are not applicable to the current user
            const user = yield select(stored.USER)
            if (!user.get('permissions').includes('is_prop_data_user')) {
              const tags = record.meta[f].filter(tag => {
                if (tag.level === 'User' && tag.agent_id === user.getIn([ 'agent', 'id' ])) {
                  return true
                }
                if (tag.level === 'Branch') {
                  const branches = user.getIn([ 'agent', 'branches' ])
                  if (branches.includes(tag.branch_id)) {
                    return true
                  }
                  if (user.get('permissions').includes('apply_to_all_branches')) {
                    return true
                  }
                }
                if (tag.level === 'Agency') {
                  return true
                }
                return false
              })
              delta[action.modelname][action.id].meta[f] = tags
            }
          }
        }
      }
      if ([ 'residential', 'commercial', 'projects', 'holiday' ].includes(action.modelname)) {
        if (!(record.meta && record.meta.url)) {
          const settings = yield select(stored.SETTINGS)
          let website

          try {
            website = yield generateWebsiteLink(delta[action.modelname][action.id], action.modelname, settings)
          } catch (e) {
            log.error(e)
          }
          const fqdn_website = `https://${settings.get('domain')}${website}`
          record.meta.url = {
            website: fqdn_website, // 'https://foobar.net/results/residential/for-sale/mokopane/impala-park/house/102758/'
            pdms: `/secure/${settings.get('id')}/${action.modelname}/${action.id}/details`,
            /* eslint-disable-next-line max-len */
            branch_site_url: record.meta.branch && record.meta.branch.website_url ? record.meta.branch.website_url + website : null,
            /* eslint-disable-next-line max-len */
            team_site_url: record.meta.team && record.meta.team.website_url ? record.meta.team.website_url + website : null,
            /* eslint-disable-next-line max-len */
            agent_site_url: record.meta.agent && record.meta.agent.website_url ? record.meta.agent.website_url + website : null,
            /* eslint-disable-next-line max-len */
            agent_2_site_url: record.meta.agent_2 && record.meta.agent_2.website_url ? record.meta.agent_2.website_url + website : null,
            /* eslint-disable-next-line max-len */
            agent_3_site_url: record.meta.agent_3 && record.meta.agent_3.website_url ? record.meta.agent_3.website_url + website : null,
            /* eslint-disable-next-line max-len */
            agent_4_site_url: record.meta.agent_4 && record.meta.agent_4.website_url ? record.meta.agent_4.website_url + website : null
          }
        }
        if (config.get('override')) {
          const user = yield select(stored.USER)
          let field = config.get('fields').find(f => f.get('name') === 'unit_number')
          if (field) { field = field.toJS()}
          if (field && field.preferOwn) {
            if (field.edit && Array.isArray(field.edit)) { // Escape hatch for conditional fields like POA / Price
              const mockform = { touched: { [field.name]: true }, values: { [field.name]: record[field.name] } }
              field.edit.map(s => s.map(condition => {
                mockform.values[condition.field] = record[condition.field]
                if (field.preferOwn) {
                  condition.preferOwn = field.preferOwn
                  field.permission_key = {
                    agent: [ `listings_${action.modelname}_update_own` ],
                    agent_2: [ `listings_${action.modelname}_update_own` ],
                    agent_3: [ `listings_${action.modelname}_update_own` ],
                    agent_4: [ `listings_${action.modelname}_update_own` ]
                  }
                  if ([ 'residential', 'commercial' ].includes(action.modelname)) {
                    field.permission_key = {
                      agent: [
                        `listings_${action.modelname}_to_let_view_own`,
                        `listings_${action.modelname}_for_sale_view_own`
                      ],
                      agent_2: [
                        `listings_${action.modelname}_to_let_view_own`,
                        `listings_${action.modelname}_for_sale_view_own`
                      ],
                      agent_3: [
                        `listings_${action.modelname}_to_let_view_own`,
                        `listings_${action.modelname}_for_sale_view_own`
                      ],
                      agent_4: [
                        `listings_${action.modelname}_to_let_view_own`,
                        `listings_${action.modelname}_for_sale_view_own`
                      ]
                    }
                  }
                  mockform.values.agent = record.agent
                  mockform.values.agent_2 = record.agent_2
                  mockform.values.agent_3 = record.agent_3
                  mockform.values.agent_4 = record.agent_4
                }
              }))
              if (!isConditional(field, 'edit', false, mockform, user.toJS())) { delete record.unit_number }
            }
          }
        }
      }
      if ([ 'modules' ].includes(action.modelname) && getIn(record, [ 'parent' ])) {
        const version_ids = [
          getIn(record, [ 'parent' ]),
          ...getIn(record, [ 'versions' ], [])
        ]
        const versions = [
          getIn(record, [ 'meta', 'parent' ], []),
          ...getIn(record, [ 'meta', 'versions' ], [])
        ]
        record.versions = version_ids
        record.meta.versions = versions
      }
    }

    yield put({
      type: 'FETCH_ONE_SUCCESS',
      token,
      delta,
      noloader: action.noloader,
      wait: action.wait
    })
    if (action.resolve) { action.resolve(delta) }
  } catch (e) {
    if (e.status !== 408) {
      console.error(e)
    }
    yield put({ type: 'FETCH_ONE_ERROR', message: JSON.stringify(e) })
    if (action.reject) { action.reject(e) }
  }
}

export function* fetchMany(action) {
  try {
    const { signal, ...data } = action.data.values
    if (!data.modelname) { throw new PDMSError('No modelname provided') }

    const config = yield select(stored.CONFIG, data.modelname)
    const formconfig = yield select(stored.CONFIG, data.formmodel)
    const t = yield select(stored.TOKEN)
    if (!data.endpoint) {
      data.endpoint = config.has('endpoint') ? config.get('endpoint').toJS() : {}
    }
    if (data.endpoint.parse) {
      const model = yield select(stored.CACHEDMODELID, data.formmodel, data.id)
      try {
        data.endpoint.read = parseURL(data.endpoint.read, model.toJS())
      } catch (e) { console.error(e) }
    }
    data.searchkey = config.getIn([ 'search', 'key' ], data.searchkey)
    if (List.isList(data.searchkey)) {
      data.searchkey = data.searchkey.toJS()
    }
    if (data.trigram === undefined || data.trigram === null) { // Must set trigram to false to not use it
      data.trigram = config.getIn([ 'search', 'trigram' ], false)
    }
    if (
      !data.select
      && !data.get_count
      && !(data.params && data.params.get_count)
      && !action.data.noloader
    ) { yield put({ type: 'SHOW_LOADER', action }) } // Only show loader when doing a non-cache search
    const fields = config.get('fields')
    const qs = new QueryBuilder(`${apigw + data.endpoint.read}`)
    if (data.params) {
      qs.setParam(data.params)
      if (qs.hasParam('term')) {
        data.term = qs.getParam('term')
      }
    } else {
      data.params = {}
    }
    if (data.term) { // There is a search
      if (data.trigram) { // It's trigram
        qs.setParam('search', data.term)
        if (data.trigram_fields) { qs.setParam('trigram_fields', data.trigram_fields) }
      } else if (Array.isArray(data.searchkey)) {
        // qs.setParam('search', data.term)
        data.searchkey.forEach(key => { qs.setParam(`${key}__icontains__or`, data.term) })
      } else if (data.searchkey) {
        qs.setParam(`${data.searchkey}__icontains`, data.term)
      }
    }
    if (data.endpoint.parms && Array.isArray(data.endpoint.parms)) { // Endpoint specific parameters ie. projects
      data.endpoint.parms.forEach(p => qs.setParam(p.parm, p.value))
    }
    if (data.hasOwnProperty('nonull')) { qs.setParam(`${data.nonull}__isnull`, false) }
    if (data.hasOwnProperty('fields')) { qs.setParam('fields', data.fields.join()) }
    qs.setParam('limit', data.limit || 20)
    if (data.status) { qs.setParam('status', data.status) }
    if (data.active) { qs.setParam('active', data.active) }
    if (data.params) { qs.setParam(data.params) } // This should be all we need?
    if ((!data.params.offset || data.params.offset === '0') && !data.offset) {
      qs.removeParam('offset')
    }
    qs.removeParam('term')
    if (data.all || getIn(data, [ 'params', 'get_all' ])) {
      qs.setParam('get_all', 1)
      qs.removeParam('limit')
      qs.removeParam('offset')
    }
    // Add meta fields from various sources: params, config and static
    let metafields = List()
    if (data.params && data.params.meta_fields && Array.isArray(data.params.meta_fields)) {
      metafields = List(data.params.meta_fields)
    }
    if (data.modellist) { // Fetch meta for fields in the list view that are applicable
      const moremetafields = fields.filter(f => (f.get('modelname') && !f.get('container') && f.get('metafield') !== false) || f.get('name', '').indexOf('portals.') !== -1 || f.get('metafield')).map(f => f.get('name'))
      const listingmetafields = fields.filter(f =>
        (f.get('modelname') && [ 'residential', 'holiday', 'commercial', 'projects' ].includes(data.modelname) && !f.get('container')) ||
        (f.get('modelname') && List([ 'residential', 'holiday', 'commercial', 'projects' ]).includes(f.get('name')) && !f.get('container'))).map(f => f.get('name'))
      metafields = metafields.merge(moremetafields).merge(listingmetafields)

      if (([ 'residential', 'holiday', 'commercial', 'projects' ].includes(data.modelname))) {
        const removemeta = [ 'private_documents', 'documents', 'listing_images', 'floor_plans' ] // Not needed in list view
        removemeta.forEach(meta => {
          if (metafields.includes(meta)) {
            metafields = metafields.delete(metafields.indexOf(meta))
          }
        })
        metafields = metafields.push('listing_images__0') // Only fetch the initial listing image from the gallery service in list view
      }
    } // End modellist only logic

    metafields = metafields.toSet().toList() // Uniqify

    if (([ 'residential', 'holiday', 'commercial', 'projects' ].includes(data.modelname) && !metafields.includes('portals'))) {
      metafields = metafields.push('portals')
    }
    if (formconfig.get('lock_own_agents')) { // Users only see themselves as options
      if (([ 'residential', 'holiday', 'commercial', 'projects' ].includes(data.formmodel))) { // Check for own perms
        let perms = yield select(stored.PERMISSIONS)
        const agent = yield select(stored.AGENT)
        const ownperms = []
        if (data.formmodel === 'residential' || data.formmodel === 'commercial') {
          ownperms.push(`listings_${data.formmodel}_for_sale_view_own`)
          ownperms.push(`listings_${data.formmodel}_to_let_view_own`)
          ownperms.push(`listings_${data.formmodel}_for_sale_edit_own`)
          ownperms.push(`listings_${data.formmodel}_to_let_edit_own`)
        }
        if (data.formmodel === 'holiday' || data.formmodel === 'projects') {
          ownperms.push(`listings_${data.formmodel}_view_own`)
          ownperms.push(`listings_${data.formmodel}_edit_own`)
        }
        perms = perms.toJSON()
        if (perms.some(p => ownperms.includes(p)) && data.modelname === 'teams') {
          qs.setParam('agents__contains', agent.get('id'))
        }
        if (perms.some(p => ownperms.includes(p)) && data.modelname === 'agents') {
          qs.setParam('id', agent.get('id'))
        }
      }
    }

    if (data.modelname === 'portals') {
      metafields = metafields.push('portal')
    }
    const removemeta = [ 'area', 'areas', 'suburb', 'suburbs', 'location' ]
    removemeta.forEach(meta => {
      if (metafields.includes(meta)) {
        metafields = metafields.delete(metafields.indexOf(meta))
      }
    })

    metafields = metafields.filter(f => f.indexOf('.') === -1)
    if (!metafields.includes('statistics') && data.modellist) {
      metafields = metafields.push('statistics') // Statically add the stats field each time
    }
    qs.setParam('meta_fields', metafields.toJS())
    let values
    let r = { token: t }
    switch (data.modelname) { // Either do the request or query IDB
      case 'locations':
        if (qs.getParam('id__in')) {
          values = yield call(() => db.suburbs.where('id').anyOf(qs.getParam('id__in')).toArray())
        } else {
          r = yield call(request, qs.url(false, true), { method: 'GET', headers: { Authorization: `Bearer ${t}` } }) // false = remove blanks
          if (!r.ok) { throw r }
          values = JSON.parse(r.body)
        }
        break
      case 'areas':
        if (qs.getParam('id__in')) {
          values = yield call(() => db.areas.where('id').anyOf(qs.getParam('id__in')).toArray())
        } else {
          r = yield call(request, qs.url(false, true), { method: 'GET', headers: { Authorization: `Bearer ${t}` } }) // false = remove blanks
          if (!r.ok) { throw r }
          values = JSON.parse(r.body)
        }
        break
      case 'countries':
        if (qs.getParam('id__in') && !data.formmodel === 'settings') {
          values = yield call(() => db.countries.where('id').anyOf(qs.getParam('id__in')).toArray())
        } else {
          r = yield call(request, qs.url(false, true), { method: 'GET', headers: { Authorization: `Bearer ${t}` } }) // false = remove blanks
          if (!r.ok) { throw r }
          values = JSON.parse(r.body)
        }
        break
      case 'provinces':
        if (qs.getParam('id__in')) {
          values = yield call(() => db.provinces.where('id').anyOf(qs.getParam('id__in')).toArray())
        } else {
          r = yield call(request, qs.url(false, true), { method: 'GET', headers: { Authorization: `Bearer ${t}` } }) // false = remove blanks
          if (!r.ok) { throw r }
          values = JSON.parse(r.body)
        }
        break
      default: // Do the request
        r = yield call(request, qs.url(false, true), { method: 'GET', headers: { Authorization: `Bearer ${t}` }, signal }) // false = remove blanks
        if (!r.ok) { throw r }
        values = JSON.parse(r.body)
    }

    const results = ('results' in values) ? values.results : values
    if (Array.isArray(results) && ![ 'locations', 'areas', 'provinces', 'countries', 'lightstonesuburb', 'lightstonetown' ].includes(data.modelname)) {
      for (const record of results) {
        for (const f in record) {
          if (record[f]) {
            if ([ 'location', 'suburb' ].includes(f) && data.modelname !== 'branches') {
              const loc = record[f]
              const location = yield call(() => db.suburbs.get(loc))
              if (location) {
                record.meta[f] = location
              }
            }
            if ([ 'area' ].includes(f)) {
              const loc = record[f]
              const location = yield call(() => db.areas.get(loc))
              if (location) {
                record.meta[f] = location
              }
            }
            if ([ 'province' ].includes(f) || ([ 'location' ].includes(f) && [ 'branches', 'franchises' ].includes(data.modelname))) {
              const loc = record[f]
              const location = yield call(() => db.provinces.get(loc))
              if (location) {
                record.meta[f] = location
              }
            }
            if ([ 'locations', 'suburbs', 'serviced_locations' ].includes(f)) {
              const loc = record[f]
              const location = yield call(() => db.suburbs.where('id').anyOf(loc).toArray())
              if (location) {
                record.meta[f] = location
              }
            }
            if ([ 'areas' ].includes(f)) {
              const loc = record[f]
              const location = yield call(() => db.areas.where('id').anyOf(loc).toArray())
              if (location) {
                record.meta[f] = location
              }
            }
            if ([ 'tags' ].includes(f)) {
              // Hide tags that are not applicable to the current user
              const user = yield select(stored.USER)
              if (!user.get('permissions').includes('is_prop_data_user')) {
                const tags = record.meta[f].filter(tag => {
                  if (tag.level === 'User' && tag.agent_id === user.getIn([ 'agent', 'id' ])) {
                    return true
                  }
                  if (tag.level === 'Branch') {
                    const branches = user.getIn([ 'agent', 'branches' ])
                    if (branches.includes(tag.branch_id)) {
                      return true
                    }
                    if (user.get('permissions').includes('apply_to_all_branches')) {
                      return true
                    }
                  }
                  if (tag.level === 'Agency') {
                    return true
                  }
                  return false
                })
                record.meta[f] = tags
              }
            }
          }
        }
        if ([ 'residential', 'commercial', 'projects', 'holiday' ].includes(data.modelname)) {
          if (!(record.meta && record.meta.url)) {
            if (!record.meta) {
              record.meta = {}
            }
            const settings = yield select(stored.SETTINGS)
            let website
            try {
              website = yield generateWebsiteLink(record, data.modelname, settings)
            } catch (e) {
              log.error(e)
            }
            const fqdn_website = `https://${settings.get('domain')}${website}`
            record.meta.url = {
              website: fqdn_website, // 'https://foobar.net/results/residential/for-sale/mokopane/impala-park/house/102758/'
              pdms: `/secure/${settings.get('id')}/${data.modelname}/${record.id}/details`,
              /* eslint-disable-next-line max-len */
              branch_site_url: record.meta.branch && record.meta.branch.website_url ? record.meta.branch.website_url + website : null,
              /* eslint-disable-next-line max-len */
              team_site_url: record.meta.team && record.meta.team.website_url ? record.meta.team.website_url + website : null,
              /* eslint-disable-next-line max-len */
              agent_site_url: record.meta.agent && record.meta.agent.website_url ? record.meta.agent.website_url + website : null,
              /* eslint-disable-next-line max-len */
              agent_2_site_url: record.meta.agent_2 && record.meta.agent_2.website_url ? record.meta.agent_2.website_url + website : null,
              /* eslint-disable-next-line max-len */
              agent_3_site_url: record.meta.agent_3 && record.meta.agent_3.website_url ? record.meta.agent_3.website_url + website : null,
              /* eslint-disable-next-line max-len */
              agent_4_site_url: record.meta.agent_4 && record.meta.agent_4.website_url ? record.meta.agent_4.website_url + website : null
            }
          }
          if (config.get('override')) {
            const user = yield select(stored.USER)
            let field = config.get('fields').find(f => f.get('name') === 'unit_number')
            if (field) { field = field.toJS()}
            if (field && field.preferOwn) {
              if (field.edit && Array.isArray(field.edit)) { // Escape hatch for conditional fields like POA / Price
                const mockform = { touched: { [field.name]: true }, values: { [field.name]: record[field.name] } }
                field.edit.map(s => s.map(condition => {
                  mockform.values[condition.field] = record[condition.field]
                  if (field.preferOwn) {
                    condition.preferOwn = field.preferOwn
                    field.permission_key = {
                      agent: [ `listings_${action.modelname}_update_own` ],
                      agent_2: [ `listings_${action.modelname}_update_own` ],
                      agent_3: [ `listings_${action.modelname}_update_own` ],
                      agent_4: [ `listings_${action.modelname}_update_own` ]
                    }
                    if ([ 'residential', 'commercial' ].includes(action.modelname)) {
                      field.permission_key = {
                        agent: [
                          `listings_${action.modelname}_to_let_view_own`,
                          `listings_${action.modelname}_for_sale_view_own`
                        ],
                        agent_2: [
                          `listings_${action.modelname}_to_let_view_own`,
                          `listings_${action.modelname}_for_sale_view_own`
                        ],
                        agent_3: [
                          `listings_${action.modelname}_to_let_view_own`,
                          `listings_${action.modelname}_for_sale_view_own`
                        ],
                        agent_4: [
                          `listings_${action.modelname}_to_let_view_own`,
                          `listings_${action.modelname}_for_sale_view_own`
                        ]
                      }
                    }
                    mockform.values.agent = record.agent
                    mockform.values.agent_2 = record.agent_2
                    mockform.values.agent_3 = record.agent_3
                    mockform.values.agent_4 = record.agent_4
                  }
                }))
                if (!isConditional(field, 'edit', false, mockform, user.toJS())) { delete record.unit_number }
              }
            }
          }
        }
        if ([ 'modules' ].includes(data.modelname) && getIn(record, [ 'parent' ])) {
          const version_ids = [
            getIn(record, [ 'parent' ]),
            ...getIn(record, [ 'versions' ], [])
          ]
          const versions = [
            getIn(record, [ 'meta', 'parent' ]),
            ...getIn(record, [ 'meta', 'versions' ], [])
          ]
          record.versions = version_ids
          record.meta.versions = versions
        }
      }
    }

    const delta = { }
    delta[data.modelname] = {}
    if (!Array.isArray(results) && !qs.getParam('get_count')) { throw new PDMSError('No results') }

    if (data.modelname === 'syndication') { // Transmogrify syndication
      const settings = yield select(stored.SETTINGS)
      const portals = settings.portals.global
      results.forEach(res => {
        const trans = {}
        trans.portals = {}
        portals.forEach(p => { trans[p.logo] = {} })
        if (res.portals.length > 0) {
          res.meta.portals.forEach(p => {
            const pname = portals.find(po => po.id === p.portal).logo
            trans.portals[pname] = {
              config: true,
              active: p.active,
              status: p.feed_status,
              modified: p.modified,
              portal: p.portal,
              reference: p.reference,
              expiry_date: p.expiry_date
            }
          })
        } else {
          delete res.portals
        }
        delta[data.modelname][res.id] = { ...res, ...trans }
      })
    } else if (!qs.getParam('get_count')) {
      const settings = yield select(stored.SETTINGS)
      results.forEach(res => {
        if (data.modelname === 'leads') {
          Object.keys(res).forEach(f => {
            if ([ 'residential', 'commercial', 'projects', 'holiday' ].includes(f)) {
              if (res[f] && res.meta[f]) { // For models such as leads which are linked to a listing type and id (ditch all the null fields)
                res.model = capitalize(f)
                res.meta.listing = res.meta[f]
                res.meta.listing.model = f
                res.meta.listing.link = `/secure/${settings.get('id')}/${f}/${res.meta[f].id}/details`
              }
            }
          })
        }
        if (data.modelname === 'offers') {
          Object.keys(res.meta.lead).forEach(async f => {
            if ([ 'residential', 'commercial', 'projects', 'holiday' ].includes(f)) {
              if (res.meta.lead[f] && res.meta.lead.meta[f]) { // For models such as leads which are linked to a listing type and id (ditch all the null fields)
                res.meta.listing = res.meta.lead.meta[f]
                res.meta.listing.model = f
                res.meta.listing.link = `/secure/${settings.get('id')}/${f}/${res.meta.lead.meta[f].id}/details`
                const loc = res.meta.listing.location
                const location = await db.transaction('r', db.suburbs, () => db.suburbs.get(loc))
                if (location) {
                  res.meta.listing.meta = { location }
                }
              }
            }
          })
        }
        if (data.modelname === 'applications') {
          Object.keys(res).forEach(async f => {
            if ([ 'residential', 'commercial', 'projects', 'holiday' ].includes(f)) {
              if (res[f] && res.meta[f]) { // For models such as leads which are linked to a listing type and id (ditch all the null fields)
                res.model = capitalize(f)
                res.meta.listing = res.meta[f]
                res.meta.listing.model = f
                res.meta.listing.link = `/secure/${settings.get('id')}/${f}/${res.meta[f].id}/details`
              }
            }
          })
        }
        if (data.modelname === 'deals') {
          if (res.meta.listing) {
            res.meta.listing.link = `/secure/${settings.get('id')}/${res.model_type}/${res.model_id}/details`
          }
        }
        if (data.modelname === 'lightstonesuburb') {
          Object.keys(res).forEach(f => {
            if (f.includes('cad')) {
              delete (res[f])
            }
          })
        }
        delta[data.modelname][res.id] = { ...res }
      })
    }

    let resolve
    let p
    if (qs.getParam('get_count')) {
      resolve = { ...values }
      p = { type: 'FETCH_MANY_COUNT_SUCCESS', token: r.token, noloader: action.data.noloader }
    } else if (data.modelname === 'globalportals') {
      resolve = { options: results, hasMore: values.next }
      p = { type: 'FETCH_MANY_SUCCESS', delta: {}, select: data.select, token: r.token, noloader: action.data.noloader }
    } else if (action.data.resolve && !data.modellist && !data.conflicts) { // Pass option labels back to AsyncSelect
      resolve = { options: results, hasMore: values.next }
      p = { type: 'FETCH_MANY_SUCCESS', delta, select: data.select, token: r.token, noloader: action.data.noloader }
    } else { // Pass option labels back to AsyncSelect
      p = { type: 'FETCH_MANY_SUCCESS', delta, select: data.select, token: r.token, noloader: action.data.noloader, modellist: data.modellist }
      yield put(p) // Reduce results into cache
      if (data.resolve) {
        resolve = { options: results, hasMore: values.next }
      }
    }
    if (resolve && action.data.resolve && !data.modellist) {
      yield put(p)
      action.data.resolve(resolve)
    }
    if (data.conflicts && data.select && action.data.resolve) {
      resolve = { options: results, hasMore: values.next }
      action.data.resolve(resolve)
    }

    if (data.conflicts && !data.select && action.data.resolve) { action.data.resolve(results) }
    const model_delta = {}
    if (data.modellist) { // Reduce into the model store (ie. search or list view)
      model_delta[data.modelname] = { }
      model_delta[data.modelname].offset = 0
      model_delta[data.modelname].next = values.next || null
      model_delta[data.modelname].previous = values.previous || null
      model_delta[data.modelname].count = values.count || null
      model_delta[data.modelname].params = { ...qs.getAllArgs() }
      delete model_delta[data.modelname].params.meta_fields
      model_delta[data.modelname].index = []
      results.forEach(op => { model_delta[data.modelname].index.push(op.id) })
      yield put({ type: 'INDEX_MODEL_SEARCH', token: r.token, delta: model_delta })
      const result = {
        ...model_delta[data.modelname],
        results: delta[data.modelname]
      }
      if (action.data.resolve) { action.data.resolve(result) }
    }
    tasks.delete(action.hash)
    return { resolve: resolve, put: p }
  } catch (e) {
    if (e.status !== 408) { console.error(e) }
    yield put({ type: 'FETCH_MANY_ERROR', message: JSON.stringify(e) })
    if (action.data.reject) { action.data.reject(e) }
    tasks.delete(action.hash)
    return { error: e }
  }
}

export function* fetchMatches(action) {
  try {
    const t = yield select(stored.TOKEN)
    const { modelname, id, params, suffix, resolve } = action.data
    const config = yield select(stored.CONFIG, modelname)
    const uri = `${apigw + config.getIn([ 'endpoint', 'read' ])}/${id}/matches/${suffix ? suffix : ''}?meta_fields=branch,agent,agent_2,agent_3,agent_4,contact,lead,listing_images__0,portals`
    const qs = new QueryBuilder(uri)
    qs.setParam(params)
    const r = yield call(request, qs.url(false, true), { method: 'GET', headers: { Authorization: `Bearer ${t}` } })
    if (!r.ok) { throw r }
    let body = JSON.parse(r.body)
    let results = null
    if (!params.get_all) {
      results = body.results
    } else {
      results = body
    }
    for (const mid in results) {
      if (results[mid]) {
        const record = results[mid]
        for (const f in record) {
          if (record[f]) {
            if ([ 'location', 'suburb' ].includes(f)) {
              const loc = record[f]
              const location = yield call(async() => await db.transaction('r', db.suburbs, () => db.suburbs.get(loc)))
              if (location) {
                results[mid].meta[f] = location
              }
            }
            if ([ 'area' ].includes(f)) {
              const loc = record[f]
              const location = yield call(async() => await db.transaction('r', db.areas, () => db.areas.get(loc)))
              if (location) {
                results[mid].meta[f] = location
              }
            }
            if ([ 'province' ].includes(f)) {
              const loc = record[f]
              const location = yield call(async() => await db.transaction('r', db.provinces, () => db.provinces.get(loc)))
              if (location) {
                results[mid].meta[f] = location
              }
            }
            if ([ 'locations', 'suburbs' ].includes(f)) {
              const loc = record[f]
              const location = yield call(async() => await db.transaction('r', db.suburbs, () => db.suburbs.where('id').anyOf(loc).toArray()).catch(e => log.error(e)))
              if (location) {
                results[mid].meta[f] = location
              }
            }
            if ([ 'areas' ].includes(f)) {
              const loc = record[f]
              const location = yield call(async() => await db.transaction('r', db.areas, () => db.areas.where('id').anyOf(loc).toArray()))
              if (location) {
                results[mid].meta[f] = location
              }
            }
          }
        }
        if ([ 'residential', 'commercial', 'projects', 'holiday' ].includes(modelname)) {
          if (!(record.meta && record.meta.url)) {
            const settings = yield select(stored.SETTINGS)
            let website
            try {
              website = yield generateWebsiteLink(results[mid], modelname, settings)
            } catch (e) {
              log.error(e)
            }
            record.meta.url = {
              website, // '/results/residential/for-sale/mokopane/impala-park/house/102758/',
              pdms: `/secure/${settings.get('id')}/${modelname}/${id}/details`
            }
          }
          if (config.get('override')) {
            const user = yield select(stored.USER)
            let field = config.get('fields').find(f => f.get('name') === 'unit_number')
            if (field) { field = field.toJS() }
            if (field && field.preferOwn) {
              if (field.edit && Array.isArray(field.edit)) { // Escape hatch for conditional fields like POA / Price
                const mockform = { touched: { [field.name]: true }, values: { [field.name]: record[field.name] } }
                field.edit.map(s => s.map(condition => {
                  mockform.values[condition.field] = record[condition.field]
                  if (field.preferOwn) {
                    condition.preferOwn = field.preferOwn
                    field.permission_key = {
                      agent: [ `listings_${action.modelname}_update_own` ],
                      agent_2: [ `listings_${action.modelname}_update_own` ],
                      agent_3: [ `listings_${action.modelname}_update_own` ],
                      agent_4: [ `listings_${action.modelname}_update_own` ]
                    }
                    if ([ 'residential', 'commercial' ].includes(action.modelname)) {
                      field.permission_key = {
                        agent: [
                          `listings_${action.modelname}_to_let_view_own`,
                          `listings_${action.modelname}_for_sale_view_own`
                        ],
                        agent_2: [
                          `listings_${action.modelname}_to_let_view_own`,
                          `listings_${action.modelname}_for_sale_view_own`
                        ],
                        agent_3: [
                          `listings_${action.modelname}_to_let_view_own`,
                          `listings_${action.modelname}_for_sale_view_own`
                        ],
                        agent_4: [
                          `listings_${action.modelname}_to_let_view_own`,
                          `listings_${action.modelname}_for_sale_view_own`
                        ]
                      }
                    }
                    mockform.values.agent = record.agent
                    mockform.values.agent_2 = record.agent_2
                    mockform.values.agent_3 = record.agent_3
                    mockform.values.agent_4 = record.agent_4
                  }
                }))
                if (!isConditional(field, 'edit', false, mockform, user.toJS())) { delete record.unit_number }
              }
            }
          }
        }
      }
    }
    if (!params.get_all) {
      body.results = results
    } else {
      body = { results }
    }
    yield put({
      type: 'FETCH_MATCHES_SUCCESS',
      token: r.token,
      id,
      modelname,
      body
    })
    if (resolve) { resolve(body) }
  } catch (e) {
    yield put({ type: 'FETCH_MATCHES_ERROR', message: JSON.stringify(e) })
    if (action.data.reject) { action.data.reject(e) }
  }
}

export function* fetchProfileMatches(action) {
  try {
    const t = yield select(stored.TOKEN)
    const { id, resolve } = action
    const uri = `${apigw}/mashup/api/v1/profiles/${id}/matches/`
    const r = yield call(request, uri, { method: 'GET', headers: { Authorization: `Bearer ${t}` } })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    if (body.results) {
      for (const item in body.results) {
        if (item.location) {
          const loc = item.location
          const location = yield call(async() => await db.transaction('r', db.suburbs, () => db.suburbs.get(loc)))
          if (location) {
            item.meta.location = location
          }
        }
      }
    }
    yield put({
      type: 'FETCH_PROFILE_MATCHES_SUCCESS',
      token: r.token,
      id,
      body
    })
    if (resolve) { resolve(body) }
  } catch (e) {
    yield put({ type: 'FETCH_PROFILE_MATCHES_ERROR', message: JSON.stringify(e) })
    if (action.reject) { action.reject(e) }
  }
}

export function* emailProfileMatches(action) {
  try {
    const t = yield select(stored.TOKEN)
    const { data } = action
    const { resolve, values } = data

    const loading = yield select(stored.LOADING)
    logEvent('EMAIL_PROFILE_MATCHES', { body: values })
    if (!loading) { yield put({ type: 'SHOW_LOADER', action }) } // Only show loader when doing a non-cache search
    const uri = `${apigw}/contacts/api/v1/alerts/profile-matches/`
    const r = yield call(request, uri, { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(values) })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    yield put({
      type: 'EMAIL_PROFILE_MATCHES_SUCCESS',
      token: r.token,
      body
    })
    logEvent('EMAIL_PROFILE_MATCHES_SUCCESS')
    yield put({ type: 'NOTIFY', data: { title: 'Profile Matches', body: 'Email sent successfully', type: 'success' } })
    if (resolve) { resolve(body) }
  } catch (e) {
    yield put({ type: 'EMAIL_PROFILE_MATCHES_ERROR', message: JSON.stringify(e) })
    yield put({ type: 'NOTIFY', data: { title: 'Error', body: JSON.stringify(e), type: 'error' } })
    if (action.reject) { action.reject(e) }
  }
}


export function* emailTemplate(action) {
  try {
    const t = yield select(stored.TOKEN)
    const { data } = action
    const { resolve, values } = data

    logEvent('EMAIL_TEMPLATE', { body: values })
    const uri = `${apigw}/mail/api/v1/mail/`
    const r = yield call(request, uri, { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(values) })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    yield put({
      type: 'EMAIL_TEMPLATE_SUCCESS',
      token: r.token,
      body
    })
    logEvent('EMAIL_TEMPLATE_SUCCESS')
    if (resolve) { resolve(body) }
  } catch (e) {
    yield put({ type: 'EMAIL_TEMPLATE_ERROR', message: JSON.stringify(e) })
    yield put({ type: 'NOTIFY', data: { title: 'Error', body: JSON.stringify(e), type: 'error' } })
    if (action.reject) { action.reject(e) }
  }
}


export function* fetchHighlights(action) {
  try {
    const t = yield select(stored.TOKEN)
    const { modelname, id, params } = action.data
    const config = yield select(stored.CONFIG, modelname)
    const uri = `${apigw + config.getIn([ 'endpoint', 'read' ])}/${id}/matches/?highlights=1&meta_fields=agent,lead,contact,branch`
    const qs = new QueryBuilder(uri)
    qs.setParam(params)
    const r = yield call(request, qs.url(false, true), { method: 'GET', headers: { Authorization: `Bearer ${t}` } })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    const results = body.results ? body.results : body
    for (const mid in results) {
      if (results[mid]) {
        const record = results[mid]
        for (const f in record) {
          if (record[f]) {
            if ([ 'location', 'suburb' ].includes(f)) {
              const loc = record[f]
              const location = yield call(async() => await db.transaction('r', db.suburbs, () => db.suburbs.get(loc)))
              if (location) {
                results[mid].meta[f] = location
              }
            }
            if ([ 'area' ].includes(f)) {
              const loc = record[f]
              const location = yield call(async() => await db.transaction('r', db.areas, () => db.areas.get(loc)))
              if (location) {
                results[mid].meta[f] = location
              }
            }
            if ([ 'province' ].includes(f)) {
              const loc = record[f]
              const location = yield call(async() => await db.transaction('r', db.provinces, () => db.provinces.get(loc)))
              if (location) {
                results[mid].meta[f] = location
              }
            }
            if ([ 'locations', 'suburbs' ].includes(f)) {
              const loc = record[f]
              const location = yield call(async() => await db.transaction('r', db.suburbs, () => db.suburbs.where('id').anyOf(loc).toArray()).catch(e => log.error(e)))
              if (location) {
                results[mid].meta[f] = location
              }
            }
            if ([ 'areas' ].includes(f)) {
              const loc = record[f]
              const location = yield call(async() => await db.transaction('r', db.areas, () => db.areas.where('id').anyOf(loc).toArray()))
              if (location) {
                results[mid].meta[f] = location
              }
            }
          }
        }
        if ([ 'residential', 'commercial', 'projects', 'holiday' ].includes(modelname)) {
          if (!(record.meta && record.meta.url)) {
            const settings = yield select(stored.SETTINGS)
            let website
            try {
              website = yield generateWebsiteLink(results[mid], modelname, settings)
            } catch (e) {
              log.error(e)
            }
            record.meta.url = {
              website, // '/results/residential/for-sale/mokopane/impala-park/house/102758/',
              pdms: `/secure/${settings.get('id')}/${modelname}/${action.modelid}/details`
            }
          }
          if (config.get('override')) {
            const user = yield select(stored.USER)
            let field = config.get('fields').find(f => f.get('name') === 'unit_number')
            if (field) { field = field.toJS()}
            if (field && field.preferOwn) {
              if (field.edit && Array.isArray(field.edit)) { // Escape hatch for conditional fields like POA / Price
                const mockform = { touched: { [field.name]: true }, values: { [field.name]: record[field.name] } }
                field.edit.map(s => s.map(condition => {
                  mockform.values[condition.field] = record[condition.field]
                  if (field.preferOwn) {
                    condition.preferOwn = field.preferOwn
                    field.permission_key = {
                      agent: [ `listings_${action.modelname}_update_own` ],
                      agent_2: [ `listings_${action.modelname}_update_own` ],
                      agent_3: [ `listings_${action.modelname}_update_own` ],
                      agent_4: [ `listings_${action.modelname}_update_own` ]
                    }
                    if ([ 'residential', 'commercial' ].includes(action.modelname)) {
                      field.permission_key = {
                        agent: [
                          `listings_${action.modelname}_to_let_view_own`,
                          `listings_${action.modelname}_for_sale_view_own`
                        ],
                        agent_2: [
                          `listings_${action.modelname}_to_let_view_own`,
                          `listings_${action.modelname}_for_sale_view_own`
                        ],
                        agent_3: [
                          `listings_${action.modelname}_to_let_view_own`,
                          `listings_${action.modelname}_for_sale_view_own`
                        ],
                        agent_4: [
                          `listings_${action.modelname}_to_let_view_own`,
                          `listings_${action.modelname}_for_sale_view_own`
                        ]
                      }
                    }
                    mockform.values.agent = record.agent
                    mockform.values.agent_2 = record.agent_2
                    mockform.values.agent_3 = record.agent_3
                    mockform.values.agent_4 = record.agent_4
                  }
                }))
                if (!isConditional(field, 'edit', false, mockform, user.toJS())) { delete record.unit_number }
              }
            }
          }
        }
      }
    }
    yield put({
      type: 'FETCH_HIGHLIGHTS_SUCCESS',
      token: r.token,
      id,
      modelname,
      body
    })
    if (action.data && action.data.resolve) { action.data.resolve(body) }
  } catch (e) {
    yield put({ type: 'FETCH_HIGHLIGHTS_ERROR', message: JSON.stringify(e) })
    if (action.data && action.data.reject) { action.data.reject(e) }
  }
}

export function* highlightMatch(action) {
  try {
    const t = yield select(stored.TOKEN)
    const uri = `${apigw}/contacts/api/v1/profile-highlights/`
    const agent = yield select(stored.AGENT)
    const data = { profile: action.data.match.id, agent: agent.get('id') }
    data[action.data.match.listing_model] = action.data.modelid
    data.listing_model = action.data.match.listing_model
    const r = yield call(request, uri, { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(data) })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    yield put({
      type: 'HIGHLIGHT_MATCH_SUCCESS',
      token: r.token,
      modelid: action.data.modelid,
      matchid: action.data.match.id,
      highlight: body.id,
      modelname: action.data.modelname
    })
    if (action.data.resolve) {
      action.data.resolve(body)
    }
  } catch (e) {
    if (action.data.reject) {
      action.data.reject(e)
    }
    yield put({ type: 'HIGHLIGHT_MATCH_ERROR', message: JSON.stringify(e) })
  }
}

export function* unhighlightMatch(action) {
  try {
    const t = yield select(stored.TOKEN)
    const uri = `${apigw}/contacts/api/v1/profile-highlights/${action.data.match.highlight}/`
    const r = yield call(request, uri, { method: 'DELETE', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' } })
    if (!r.ok) { throw r }
    if (r.status === 204) {
      yield put({
        type: 'UNHIGHLIGHT_MATCH_SUCCESS',
        token: r.token,
        modelid: action.data.modelid,
        score: action.data.match.score,
        matchid: action.data.match.id,
        modelname: action.data.modelname
      })
      if (action.data.resolve) {
        action.data.resolve(action.data.match.highlight)
      }
    } else {
      throw new PDMSError('Error deleting')
    }
  } catch (e) {
    if (action.data.reject) {
      action.data.reject(e)
    }
    yield put({ type: 'UNHIGHLIGHT_MATCH_ERROR', message: JSON.stringify(e) })
  }
}

export function* fetchActivity(action) {
  try {
    const loading = yield select(stored.LOADING)
    if (!loading && !(action.data && action.data.noloader)) { yield put({ type: 'SHOW_LOADER', action }) }
    const t = yield select(stored.TOKEN)
    if (!action.data) { throw new PDMSError('No data provided') }
    const { params, id, modelname, resolve } = action.data
    const config = yield select(stored.CONFIG, 'activity')
    const uri = `${apigw + config.getIn([ 'endpoint', 'read' ])}/${modelname}/${id}/`
    const qs = new QueryBuilder(uri)
    let fordata
    if (params) {
      // eslint-disable-next-line no-unused-vars
      const { for: fd, modelname: mn, id: mid, ...parms } = params
      fordata = fd
      qs.setParam(parms)
    }
    const r = yield call(request, qs.url(false, true), { method: 'GET', headers: { Authorization: `Bearer ${t}` } })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    if (fordata) {
      yield put({
        type: 'FETCH_ACTIVITY_SUCCESS',
        token: r.token,
        id: fordata.obj_id,
        modelname: fordata.modelname,
        body
      })
    } else {
      yield put({
        type: 'FETCH_ACTIVITY_SUCCESS',
        token: r.token,
        id,
        modelname,
        body
      })
    }
    if (resolve) {
      resolve(body)
    }
  } catch (e) {
    if (action.data && action.data.reject) { action.data.reject(e) }
    yield put({ type: 'FETCH_ACTIVITY_ERROR', message: JSON.stringify(e) })
  }
}

export function* fetchFeedLogs(action) {
  try {
    const loading = yield select(stored.LOADING)
    if (!loading && !(action.data && action.data.noloader)) { yield put({ type: 'SHOW_LOADER', action }) }
    const t = yield select(stored.TOKEN)
    const { params, listing_id, modelname, resolve, portal } = action.data
    const uri = `${apigw}/syndication/api/v1/logs/${modelname}/${portal}/${listing_id}/`
    const qs = new QueryBuilder(uri)
    let fordata
    if (params) {
      // eslint-disable-next-line no-unused-vars
      const { for: fd, modelname: mn, id: mid, ...parms } = params
      fordata = fd
      qs.setParam(parms)
    }
    const r = yield call(request, qs.url(false, true), { method: 'GET', headers: { Authorization: `Bearer ${t}` } })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    if (fordata) {
      yield put({
        type: 'FETCH_FEED_LOGS_SUCCESS',
        token: r.token,
        id: fordata.obj_id,
        modelname: fordata.modelname,
        body
      })
    } else {
      yield put({
        type: 'FETCH_FEED_LOGS_SUCCESS',
        token: r.token,
        id: listing_id,
        modelname,
        body
      })
    }
    if (resolve) {
      resolve(body)
    }
  } catch (e) {
    if (action.data && action.data.reject) { action.data.reject(e) }
    yield put({ type: 'FETCH_FEED_LOGS_ERROR', message: JSON.stringify(e) })
  }
}


export function* fetchGlobalPortals(action) { // Action run when agent selected
  try {
    const loading = yield select(stored.LOADING)
    if (!loading && !action.noloader) { yield put({ type: 'SHOW_LOADER', action }) }
    const t = action.token
    const uri = `${apigw}/syndication/api/v1/portals/?get_all=1`
    const r = yield call(request, uri, { method: 'GET', headers: { Authorization: `Bearer ${t}` } })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    yield put({
      type: 'FETCH_GLOBAL_PORTALS_SUCCESS',
      portals: body,
      noloader: action.noloader,
      site: action.siteid,
      token: r.token
    })
  } catch (e) {
    yield put({ type: 'FETCH_GLOBAL_PORTALS_ERROR', message: JSON.stringify(e) })
  }
}

export function* fetchAgencyPortals(action) { // Action run when agent selected
  try {
    const loading = yield select(stored.LOADING)
    if (!loading && !action.noloader) { yield put({ type: 'SHOW_LOADER', action }) }
    const t = action.token
    const uri = `${apigw}/syndication/api/v1/portals/?active__in=1&limit=100`
    const r = yield call(request, uri, { method: 'GET', headers: { Authorization: `Bearer ${t}` } })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    yield put({
      type: 'FETCH_AGENCY_PORTALS_SUCCESS',
      portals: body.results,
      noloader: action.noloader,
      site: action.siteid,
      token: r.token
    })
    // This result set is stored in the 'portals' key in model cache
    const portalidx = {
      portals: {
        offset: 0,
        next: null,
        previous: null,
        count: 1,
        params: { limit: 100, offset: 0 },
        index: body.results.map(p => p.id)
      }
    }
    yield put({ type: 'INDEX_MODEL_SEARCH', token: r.token, delta: portalidx })
  } catch (e) {
    yield put({ type: 'FETCH_AGENCY_PORTALS_ERROR', message: JSON.stringify(e) })
  }
}

export function* fetchBranchPortals(action) { // Action run when agent selected
  try {
    const loading = yield select(stored.LOADING)
    if (!loading && !action.noloader) { yield put({ type: 'SHOW_LOADER', action }) }
    const t = action.token
    const uri = `${apigw}/syndication/api/v1/branch-configs/?active__in=1&get_all=1`
    const r = yield call(request, uri, { method: 'GET', headers: { Authorization: `Bearer ${t}` } })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    yield put({
      type: 'FETCH_BRANCH_PORTALS_SUCCESS',
      portals: body,
      noloader: action.noloader,
      site: action.siteid,
      token: r.token
    })
    const p = {}
    const portals = yield select(state => state.cache.settings[action.siteid].portals)
    // Decorate agency portals with portal meta
    portals.agency.forEach(portal => {
      const portal_conf = portals.global.find(a => a.id === portal.portal)
      p[portal.id] = { ...portal }
      p[portal.id].meta = {}
      p[portal.id].meta.portal = portal_conf
      const branch = body.filter(b => b.portal === portal.agencyid)
      p[portal.id].branch = [ ...branch ]
    })
    yield put({ type: 'FETCH_PORTALS_SUCCESS', token: r.token, delta: p })
  } catch (e) {
    yield put({ type: 'FETCH_BRANCH_PORTALS_ERROR', message: JSON.stringify(e) })
  }
}

export function* fetchPortalLogs(action) { // Action run when agent selected
  try {
    const t = yield select(stored.TOKEN)
    // eslint-disable-next-line max-len
    const uri = `${apigw}/syndication/api/v1/logs/?portal=${action.portalid}&${action.modelname}=${action.modelid}&limit=5`
    const r = yield call(request, uri, { method: 'GET', headers: { Authorization: `Bearer ${t}` } })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    yield put({
      type: 'FETCH_PORTAL_LOGS_SUCCESS',
      modelname: action.modelname,
      modelid: action.modelid,
      portalid: action.portalid,
      token: r.token,
      body: body.results
    })
  } catch (e) {
    yield put({ type: 'FETCH_PORTAL_LOGS_ERROR', message: JSON.stringify(e) })
  }
}

/*
export function* createPortalConfig(action) {
  try {

    const loading = yield select(stored.Loading)
    if (!loading) { yield put({ type: 'SHOW_LOADER', action }) }
    const t = yield select(stored.TOKEN)
    const portals = yield select(stored.Portals)
    const portalid = portals.find(p => p.logo === action.data.field).id // The field name needs to equal the portal slug / logo
    const uri = `${apigw}/listings/api/v1/portal-configs/`
    let payload
    if (action.data.value) { // Toggling existing portal config
      payload = { id: action.data.value, active: false }
    } else { // Create a new portal config
      payload = { active: true, portal: portalid }
    }
    const r = yield call(request, uri, { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(payload) })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    yield put({ type: 'CREATE_PORTAL_CONFIG_SUCCESS', body: body })
    if (action.data.resolve) { action.data.resolve(body) }
  } catch (e) {
    yield put({ type: 'CREATE_PORTAL_CONFIG_ERROR', message: JSON.stringify(e) })
    yield put({ type: 'NOTIFY', data: { title: 'Error', body: JSON.stringify(e), type: 'error' } })
    if (action.data.resolve) { action.data.reject() }
  }
}
*/

export function* createBranchPortalConfig(action) {
  try {
    const loading = yield select(stored.LOADING)
    if (!loading) { yield put({ type: 'SHOW_LOADER', action }) }
    const t = yield select(stored.TOKEN)
    const uri = `${apigw}/syndication/api/v1/branch-configs/`
    const r = yield call(request, uri, { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(action.b) })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    body.agencyid = action.b.portal // Thanks again Jono
    body.bidx = action.b.bidx
    body.portal = action.b.portalid // This could have been so much simpler!
    yield put({ type: 'CREATE_BRANCH_PORTAL_CONFIG_SUCCESS', body: body })
    if (action.resolve) { action.resolve(body) }
  } catch (e) {
    yield put({ type: 'CREATE_BRANCH_PORTAL_CONFIG_ERROR', message: JSON.stringify(e) })
    if (action.resolve) { action.reject() }
  }
}

export function* updateBranchPortalConfig(action) {
  try {
    const loading = yield select(stored.LOADING)
    if (!loading) { yield put({ type: 'SHOW_LOADER', action }) }
    const t = yield select(stored.TOKEN)
    const uri = `${apigw}/syndication/api/v1/branch-configs/${action.b.id}/`
    const r = yield call(request, uri, { method: 'PATCH', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(action.b) })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    yield put({ type: 'UPDATE_BRANCH_PORTAL_CONFIG_SUCCESS', body: body, bidx: action.bidx, pid: action.b.portalid })
    if (action.resolve) { action.resolve(body) }
  } catch (e) {
    yield put({ type: 'UPDATE_BRANCH_PORTAL_CONFIG_ERROR', message: JSON.stringify(e) })
    if (action.resolve) { action.reject() }
  }
}

export function* toggleSitePortal(action) {
  try {
    const t = yield select(stored.TOKEN)
    const { data } = action
    const payload = { active: !data.active }
    const uri = `${apigw}/syndication/api/v1/portals/${data.portal}/`
    const r = yield call(request, uri, { method: 'PATCH', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(payload) })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    yield put({
      type: 'TOGGLE_SITE_PORTAL_SUCCESS',
      token: r.token,
      body: body
    })
    yield put({ type: 'NOTIFY', data: { title: data.name, body: 'Portal setting updated', type: 'success' } })
  } catch (e) {
    yield put({ type: 'TOGGLE_SITE_PORTAL_ERROR', message: JSON.stringify(e) })
    yield put({ type: 'NOTIFY', data: { title: 'Error', body: JSON.stringify(e), type: 'error' } })
  }
}

export function* createSitePortal(action) {
  try {
    const t = yield select(stored.TOKEN)
    const { data } = action
    const payload = { portal: data.id }
    const uri = `${apigw}/syndication/api/v1/portals/`
    const r = yield call(request, uri, { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(payload) })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    yield put({
      type: 'CREATE_SITE_PORTAL_SUCCESS',
      token: r.token,
      body: body
    })
    yield put({ type: 'NOTIFY', data: { title: data.name, body: 'Portal setting updated', type: 'success' } })
  } catch (e) {
    yield put({ type: 'CREATE_SITE_PORTAL_ERROR', message: JSON.stringify(e) })
    yield put({ type: 'NOTIFY', data: { title: 'Error', body: JSON.stringify(e), type: 'error' } })
  }
}

export function* syndicatePortalItem(action) {
  try {
    const t = yield select(stored.TOKEN)
    const { data } = action
    const uri = `${apigw}/syndication/api/v1/syndicate-item/`
    const sane_item_type = [ 'residential', 'commercial', 'holiday' ].includes(data.item_type) ? 'Listing' : capitalize(data.item_type)
    const r = yield call(request, uri, { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(data) })
    if (!r.ok) { throw r }
    yield put({ type: 'SYNDICATE_PORTAL_ITEM_SUCCESS' })
    if (!data.multi === true) {
      yield put({ type: 'NOTIFY', data: { title: 'Success', body: `${sane_item_type} queued for syndication.`, type: 'success' } })
    }
  } catch (e) {
    yield put({ type: 'SYNDICATE_PORTAL_ITEM_ERROR', message: JSON.stringify(e) })
    yield put({ type: 'NOTIFY', data: { title: 'Error', body: e.raw.detail, type: 'error' } })
  }
}

export function* syndicateItems(action) {
  try {
    const { args, modelname } = action.data
    let selected
    const modeltype = args.item_type === 'agent' ? 'agents' : args.item_type
    switch (args.scope) {
      case 'model': { // All items on page
        selected = yield select(stored.MODEL, `${modelname}${modeltype}`)
        selected = selected.get('index') && List.isList(selected.get('index')) ? selected.get('index') : null
        break
      }
      case 'selected': { // Only selected items
        selected = yield select(stored.SELECTED, `${modelname}${modeltype}`)
        selected = selected && List.isList(selected) ? selected : [ action.data.id ]
        break
      }
      default:
        break
    }
    for (const modelid of selected.toJS()) {
      const model = yield select(stored.CACHEDMODELID, `${modelname}${modeltype}`, modelid)
      const portals = model.getIn([ 'meta', 'portals' ]).toJS()
      if (portals.length > 0) {
        for (const p of portals) {
          if (p.active && p[args.item_type] && [ 1, 2 ].includes(p.portal)) {
            yield put({ type: 'SYNDICATE_PORTAL_ITEM', data: { portal: p.portal, item_id: modelid, item_type: args.item_type, multi: true } })
          }
        }
      }
    }
    yield put({ type: 'SYNDICATE_ITEMS_SUCCESS' })
    yield put({ type: 'NOTIFY', data: { title: 'Success', body: 'Queued for syndication.', type: 'success' } })
  } catch (e) {
    yield put({ type: 'SYNDICATE_ITEMS_ERROR', message: JSON.stringify(e) })
    yield put({ type: 'NOTIFY', data: { title: 'Error', body: JSON.stringify(e), type: 'error' } })
  }
}

export function* createPreference(action) {
  try {
    const loading = yield select(stored.LOADING)
    if (!loading) { yield put({ type: 'SHOW_LOADER', action }) }
    const t = yield select(stored.TOKEN)
    const agent = yield select(stored.AGENT)
    if (!action.data.values) { throw new PDMSError('No form data provided') }
    const { modelname } = action.data.values
    let { data } = action.data.values
    const uri = `${apigw}/users/api/v1/preferences/`
    const f = {
      view: modelname,
      column_preferences: data.map(col => new Column(col).export()),
      name: action.data.values.name.trim(),
      agent: agent.get('id')
    }
    const r = yield call(request, uri, { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(f) })
    if (!r.ok) { throw r }
    data = JSON.parse(r.body)

    yield put({ type: 'CREATE_PREFERENCE_SUCCESS', modelname, data, token: r.token })
    action.data.resolve(data)
  } catch (e) {
    if (e.raw && e.raw.name) {
      action.data.reject(e.raw.name.pop())
    } else {
      action.data.reject(e.error)
    }
    yield put({ type: 'CREATE_PREFERENCE_ERROR', message: JSON.stringify(e) })
  }
}

export function* updatePreference(action) {
  try {
    const loading = yield select(stored.LOADING)
    if (!loading) { yield put({ type: 'SHOW_LOADER', action }) }
    const t = yield select(stored.TOKEN)
    const modelname = action.data.modelname
    if (!modelname) { throw new PDMSError('No modelname provided') }
    if (!action.data.hasOwnProperty('id')) { throw new PDMSError('No id provided') }
    const user = yield select(stored.USER)
    let { id, active } = action.data
    if (parseInt(id, 10) === 0) { // Disable the active preference
      const active_pref = user.getIn([ 'preferences', modelname ])
      if (active_pref) {
        id = active_pref.get('id')
        active = false
      }
    }
    const uri = `${apigw}/users/api/v1/preferences/${id}/`
    const r = yield call(request, uri, { method: 'PATCH', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify({ active }) })
    if (!r.ok) { throw r }
    const data = JSON.parse(r.body)
    yield put({ type: 'UPDATE_PREFERENCE_SUCCESS', modelname, data, token: r.token })
    action.data.resolve(action.id)
  } catch (e) {
    yield put({ type: 'UPDATE_PREFERENCE_ERROR', message: JSON.stringify(e) })
    action.data.reject(e)
  }
}

export function* deletePreference(action) {
  try {
    const loading = yield select(stored.LOADING)
    if (!loading) { yield put({ type: 'SHOW_LOADER', action }) }
    const t = yield select(stored.TOKEN)
    if (!action.data.hasOwnProperty('id')) { throw new PDMSError('No id provided') }
    const uri = `${apigw}/users/api/v1/preferences/${action.data.id}/`
    const r = yield call(request, uri, { method: 'DELETE', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' } })
    if (!r.ok) { throw r }

    yield put({ type: 'DELETE_PREFERENCE_SUCCESS', data: { id: action.data.id, modelname: action.data.modelname }, token: r.token })
    action.data.resolve(action.id)
  } catch (e) {
    action.data.reject(e)
    yield put({ type: 'DELETE_PREFERENCE_ERROR', message: JSON.stringify(e) })
  }
}

export function* createModel(action) {
  try {
    const loading = yield select(stored.LOADING)
    const user = yield select(stored.USER)
    if (!loading) { yield put({ type: 'SHOW_LOADER', action }) }
    if (!action.data.values) { throw new PDMSError('No form data provided') }
    // eslint-disable-next-line no-unused-vars
    const { modelname, endpoint, source_ref, noparse, ...f } = action.data.values
    const t = yield select(stored.TOKEN)
    if (!modelname) { throw new PDMSError('No modelname provided') }
    logEvent(`CREATE_MODEL: ${modelname.toUpperCase()}`, { body: f })
    let write = endpoint?.write
    if (!endpoint?.write) {
      const config = yield select(stored.CONFIG, modelname)
      write = config.getIn([ 'endpoint', 'write' ])
      if (config.getIn([ 'endpoint', 'parse' ])) {
        const router = yield select(stored.ROUTER)
        const match = matchPath(router.location.pathname, { path: '/secure/:site(\\d+)/:model(agents)/:id(\\d+)', exact: false, strict: false })
        if (match) {
          write = parseURL(config.getIn([ 'endpoint', 'write' ]), match.params)
        }
      }
    }
    const qs = new QueryBuilder(`${apigw}${write}`)
    const r = yield call(request, qs.url(false, true), { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(f) })
    if (!r.ok) { throw r }
    let body = r.body
    if (!noparse) {
      body = JSON.parse(r.body)
      body.stats = {} // Create an empty stats container
      if (modelname !== 'portals') {
        yield put({ type: 'CREATE_MODEL_SUCCESS', token: r.token })
        logEvent(`CREATE_MODEL_SUCCESS: ${modelname.toUpperCase()}`)
        if (action.data.autosaved) {
          yield put({ type: 'AUTOSAVE_DISCARD', data: { mode: 'add', modelname, userid: user.getIn([ 'agent', 'id' ]) } })
        }
        if (getIn(f, [ 'category' ]) !== 'Comment' && body.id) {
          yield put({ type: 'FETCH_ONE', modelname: modelname, id: body.id })
        }
      } else { // Dealing with portals
        const portal = { portals: {} }
        const globalportals = yield select(stored.GLOBALPORTALS)
        const meta = globalportals.filter(p => p.id === body.portal)
        portal.portals[body.id] = body
        portal.portals[body.id].branch = []
        portal.portals[body.id].meta = {}
        portal.portals[body.id].meta.portal = meta[0]
        yield put({ type: 'CREATE_PORTAL_SUCCESS', token: r.token, cache: portal, modelid: body.id }) // Creates the cache entry for new portal
        logEvent(`CREATE_PORTAL_SUCCESS: ${modelname.toUpperCase()}`)
      }
    } else {
      yield put({ type: 'CREATE_MODEL_SUCCESS', token: r.token })
    }
    action.data.resolve(body)
    return body // Used for populating AsyncCreateSelect etc.
  } catch (e) {
    const modelname = action.data.values ? action.data.values.modelname : false
    if (modelname) {
      const config = yield select(stored.CONFIG, modelname)
      yield put({ type: 'NOTIFY', data: { title: 'Error', body: resolveError(e, config.get('fields').toJS()) || 'Unresolvable error', type: 'error' } })
    }
    yield put({ type: 'CREATE_MODEL_ERROR', message: JSON.stringify(e) })
    logEvent(`CREATE_MODEL_ERROR: ${modelname ? modelname.toUpperCase() : ''}`, { message: JSON.stringify(e) })
    action.data.reject(e)
    return false
  }
}

export function* createLeadInteraction(action) {
  try {
    if (!action.data.values) { throw new PDMSError('No form data provided') }
    // eslint-disable-next-line no-unused-vars
    const t = yield select(stored.TOKEN)
    logEvent('CREATE_LEAD_INTERACTION', { lead: action.data.modelid, body: action.data.values })
    const uri = `${apigw}/contacts/api/v1/leads/${action.data.modelid}/interactions/`
    const r = yield call(request, uri, { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(action.data.values) })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    yield put({ type: 'CREATE_LEAD_INTERACTION_SUCCESS', token: r.token })
    logEvent('CREATE_LEAD_INTERACTION_SUCCESS')
    action.data.resolve(body)
    return r.body // Used for populating AsyncCreateSelect etc.
  } catch (e) {
    yield put({ type: 'CREATE_LEAD_INTERACTION_ERROR', message: JSON.stringify(e) })
    action.data.reject(e)
    return false
  }
}

export function* updateLeadInteraction(action) {
  try {
    if (!action.data.values) { throw new PDMSError('No form data provided') }
    // eslint-disable-next-line no-unused-vars
    const t = yield select(stored.TOKEN)
    logEvent('UPDATE_LEAD_INTERACTION', { lead: action.data.modelid, body: action.data.values })
    const { id, ...values } = action.data.values
    const uri = `${apigw}/contacts/api/v1/leads/${action.data.modelid}/interactions/${id}/`
    const r = yield call(request, uri, { method: 'PATCH', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(values) })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    yield put({ type: 'UPDATE_LEAD_INTERACTION_SUCCESS', token: r.token })
    logEvent('UPDATE_LEAD_INTERACTION_SUCCESS')
    action.data.resolve(body)
    return r.body // Used for populating AsyncCreateSelect etc.
  } catch (e) {
    yield put({ type: 'UPDATE_LEAD_INTERACTION_ERROR', message: JSON.stringify(e) })
    action.data.reject(e)
    return false
  }
}

export function* fetchViewingFeedback(action) {
  try {
    // eslint-disable-next-line no-unused-vars
    const { data } = action
    const t = yield select(stored.TOKEN)
    const uri = `${apigw}/mashup/api/v1/leads/${data.modelname}/${data.modelid}/${data.action}/`

    const qs = new QueryBuilder(uri)
    if (data.params) {
      qs.setParam(data.params)
      if (qs.hasParam('term')) {
        data.term = qs.getParam('term')
      }
    } else {
      data.params = {}
    }

    const r = yield call(request, qs.url(false, true), { method: 'GET', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' } })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    yield put({ type: 'FETCH_VIEWING_FEEDBACK_SUCCESS', token: r.token })
    logEvent('FETCH_VIEWING_FEEDBACK_SUCCESS')
    action.data.resolve(body)
    return r.body // Used for populating AsyncCreateSelect etc.
  } catch (e) {
    yield put({ type: 'FETCH_VIEWING_FEEDBACK_ERROR', message: JSON.stringify(e) })
    action.data.reject(e)
    return false
  }
}

export function* checkDelete(action) { // Rarely used (ie. media only)
  try {
    if (!action.data.values) { throw new PDMSError('No form data provided') }
    const { modelname, selected } = action.data.values
    const t = yield select(stored.TOKEN)
    if (!modelname) { throw new PDMSError('No modelname provided') }
    if (!selected.length === 1) { throw new PDMSError('Select only 1 record') }
    logEvent(`DELETE_MODEL: ${modelname.toUpperCase()}`, { selected })
    const config = yield select(stored.CONFIG, modelname)
    const id = selected[0]
    const uri = `${apigw + config.getIn([ 'endpoint', 'write' ])}/${id}/check-delete/`
    const r = yield call(request, uri, { method: 'GET', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' } })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    action.data.resolve({ body })
    yield put({ type: 'CHECK_DELETE_MODEL_SUCCESS', ids: selected })
    logEvent(`CHECK_DELETE_MODEL_SUCCESS: ${modelname.toUpperCase()}`)
  } catch (e) {
    yield put({ type: 'CHECK_DELETE_DELETE_MODEL_ERROR', message: JSON.stringify(e) })
    if (Array.isArray(e.raw)) {
      const id = getIn(action, [ 'data', 'values', 'selected', 0 ])
      if (id) {
        const settings = yield select(stored.SETTINGS)
        const report = e.raw.map(error => {
          const error_model = Object.keys(error)[0]
          switch (error_model) {
            case 'residential':
              return { url: `/secure/${settings.get('id')}/residential?agent__or=${id}&agent_2__or=${id}&agent_3__or=${id}&agent_4__or=${id}`, count: error[error_model] }
            case 'commercial':
              return { url: `/secure/${settings.get('id')}/commercial?agent__or=${id}&agent_2__or=${id}&agent_3__or=${id}&agent_4__or=${id}`, count: error[error_model] }
            case 'project':
              return { url: `/secure/${settings.get('id')}/projects?agent__or=${id}&agent_2__or=${id}&agent_3__or=${id}&agent_4__or=${id}`, count: error[error_model] }
            case 'holiday':
              return { url: `/secure/${settings.get('id')}/holiday?agent__or=${id}&agent_2__or=${id}&agent_3__or=${id}&agent_4__or=${id}`, count: error[error_model] }
            case 'leads':
              return { url: `/secure/${settings.get('id')}/leads?agent=${id}`, count: error[error_model] }
            case 'profiles':
              return { url: `/secure/${settings.get('id')}/profiles?agent=${id}`, count: error[error_model] }
            case 'subscribers':
              return { url: `/secure/${settings.get('id')}/subscribers?agent=${id}`, count: error[error_model] }
            case 'contacts':
              return { url: `/secure/${settings.get('id')}/contacts?contact_agents__in=${id}`, count: error[error_model] }
            case 'teams':
              return { url: `/secure/${settings.get('id')}/teams?agents__overlap__or={${id}}`, count: error[error_model] }
            default:
              return error
          }
        })
        action.data.reject(report)
      }
    } else {
      action.data.reject(e)
    }
  }
}

export function* mergeModel(action) { // Rarely used (ie. media only)
  try {
    const loading = yield select(stored.LOADING)
    const agent = yield select(stored.AGENT)
    if (!loading) { yield put({ type: 'SHOW_LOADER', action }) }
    if (!action.data.values) { throw new PDMSError('No form data provided') }
    const { modelname, primary_id, record_ids } = action.data.values
    const t = yield select(stored.TOKEN)
    if (!modelname) { throw new PDMSError('No modelname provided') }
    logEvent(`MERGE_MODEL: ${modelname.toUpperCase()} ${primary_id}`, { record_ids })
    const config = yield select(stored.CONFIG, modelname)
    const uri = `${apigw + config.getIn([ 'endpoint', 'write' ])}/merge/`
    const r = yield call(request, uri, { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(action.data.values) })
    if (!r.ok) { throw r }
    const redirect = `/secure/${agent.getIn([ 'site', 'id' ])}/${config.get('modelname')}/${primary_id}`
    yield put({ type: 'MERGE_MODEL_SUCCESS', primary_id: primary_id, records_ids: record_ids, redirect })
    logEvent(`MERGE_MODEL_SUCCESS: ${modelname.toUpperCase()} ${primary_id}`)
    yield put({ type: 'NOTIFY', data: { title: 'Merge', body: 'Items successfully merged.', type: 'success' } })
    action.data.resolve()
  } catch (e) {
    const modelname = action.data.values ? action.data.values.modelname : false
    if (modelname) {
      const config = yield select(stored.CONFIG, modelname)
      yield put({ type: 'NOTIFY', data: { title: 'Error', body: resolveError(e, config.get('fields').toJS()) || 'Unresolvable error', type: 'error' } })
    }
    yield put({ type: 'MERGE_MODEL_ERROR', message: JSON.stringify(e) })
    action.data.reject(e)
  }
}

export function* deleteModel(action) { // Rarely used (ie. media only)
  try {
    const loading = yield select(stored.LOADING)
    if (!loading) { yield put({ type: 'SHOW_LOADER', action }) }
    if (!action.data.values) { throw new PDMSError('No form data provided') }
    const { modelname, selected } = action.data.values
    const t = yield select(stored.TOKEN)
    if (!modelname) { throw new PDMSError('No modelname provided') }
    logEvent(`DELETE_MODEL: ${modelname.toUpperCase()}`, { selected })
    const config = yield select(stored.CONFIG, modelname)
    for (const id of selected) {
      const uri = `${apigw + config.getIn([ 'endpoint', 'write' ])}/${id}/`
      const r = yield call(request, uri, { method: 'DELETE', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' } })
      if (!r.ok) { throw r }
    }
    yield put({ type: 'DELETE_MODEL_SUCCESS', ids: selected })
    logEvent(`DELETE_MODEL_SUCCESS: ${modelname.toUpperCase()}`)
    yield put({ type: 'NOTIFY', data: { title: 'Deleted', body: 'Item permanently removed.', type: 'success' } })
    action.data.resolve()
  } catch (e) {
    const modelname = action.data.values ? action.data.values.modelname : false
    if (modelname) {
      const config = yield select(stored.CONFIG, modelname)
      yield put({ type: 'NOTIFY', data: { title: 'Error', body: resolveError(e, config.get('fields').toJS()) || 'Unresolvable error', type: 'error' } })
    }
    yield put({ type: 'DELETE_MODEL_ERROR', message: JSON.stringify(e) })
    action.data.reject(e)
  }
}

export function* bulkeditModel(action) {
  const { modelname, toarray, noloader, nonotify, payload_key, ...f } = action.data.values
  try {
    const loading = yield select(stored.LOADING)
    if (!loading && !noloader) { yield put({ type: 'SHOW_LOADER', action }) }
    const t = yield select(stored.TOKEN)
    if (!action.data.values) { throw new PDMSError('No data provided') }
    // eslint-disable-next-line no-unused-vars
    let payload = f
    if (!modelname) { throw new PDMSError('No modelname provided') }
    if (toarray) {
      payload = Object.keys(payload).map(k => payload[k])
    }
    if (payload_key) {
      payload = payload[payload_key]
    }
    logEvent(`BULK_EDIT_MODEL: ${modelname.toUpperCase()}`, { body: f })
    const config = yield select(stored.CONFIG, modelname)
    const uri = `${apigw + config.getIn([ 'endpoint', 'direct' ])}/bulk-edit/`
    const r = yield call(request, uri, { method: 'PATCH', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(payload) })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    const cache = { [modelname]: { [body.id]: body } }
    action.data.resolve(body)
    yield put({ type: 'BULK_EDIT_MODEL_SUCCESS', token: r.token, modelname: modelname, body: body, cache: cache })
    logEvent(`BULK_EDIT_MODEL_SUCCESS: ${modelname.toUpperCase()}`)
  } catch (e) {
    if (modelname && !nonotify) {
      const config = yield select(stored.CONFIG, modelname)
      yield put({ type: 'NOTIFY', data: { title: 'Error', body: resolveError(e, config.get('fields').toJS()) || 'Unresolvable error', type: 'error' } })
    }
    yield put({ type: 'BULK_EDIT_MODEL_ERROR', message: JSON.stringify(e) })
    action.data.reject(e)
  }
}

export function* updateModel(action) {
  try {
    const loading = !action.data.noloader ? yield select(stored.LOADING) : true
    const user = yield select(stored.USER)
    if (!loading) { yield put({ type: 'SHOW_LOADER', action }) }
    const t = yield select(stored.TOKEN)
    if (!action.data.values) { throw new PDMSError('No data provided') }
    // eslint-disable-next-line no-unused-vars
    const { modelname, endpoint, noloader, quiet, id, ...f } = action.data.values
    if (!modelname) { throw new PDMSError('No modelname provided') }
    logEvent(`UPDATE_MODEL: ${modelname.toUpperCase()}`, { id, body: { ...f } })
    const config = yield select(stored.CONFIG, modelname)
    const router = yield select(stored.ROUTER)
    let uri = `${apigw + config.getIn([ 'endpoint', 'write' ])}/${id}/`
    if (endpoint?.write) {
      uri = `${apigw + endpoint.write}/${id}/`
    }
    if (config.getIn([ 'endpoint', 'parse' ])) {
      const match = matchPath(router.location.pathname, { path: '/secure/:site(\\d+)/:model(theme-settings|agents)/:id(\\d+)', exact: false, strict: false })
      if (match) {
        const new_endpoint = parseURL(config.getIn([ 'endpoint', 'write' ]), match.params)
        uri = `${apigw}${new_endpoint}/`
      }
    }
    const r = yield call(request, uri, { method: 'PATCH', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(f) })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    const cache = { [modelname]: { [body.id]: body } }
    if (action.data.resolve) {
      action.data.resolve(body.id)
    }
    yield put({ type: 'UPDATE_MODEL_SUCCESS', token: r.token, modelname: modelname, body: body, cache: cache })
    logEvent(`UPDATE_MODEL_SUCCESS: ${modelname.toUpperCase()}`)
    if (action.data.autosaved) {
      yield put({ type: 'AUTOSAVE_DISCARD', data: { mode: 'edit', modelname, modelid: id, userid: user.getIn([ 'agent', 'id' ]) } })
    }
    if (modelname === 'notes') {
      yield* fetchOne({ type: 'FETCH_ONE', modelname: 'notes', id: body.id, token: r.token, noloader: true })
    }
    if (!quiet) {
      yield put({ type: 'NOTIFY', data: { title: 'Success', body: 'Your update was successful.', type: 'success' } })
    }
  } catch (e) {
    // eslint-disable-next-line no-unused-vars
    const { modelname, endpoint, noloader, quiet, id, ...f } = action.data.values
    if (modelname) {
      const config = yield select(stored.CONFIG, modelname)
      let user = yield select(stored.USER)
      user = user.toJS()
      const fields = config.get('fields').toJS().filter(field => isConditional(field, 'edit', false, { values: f, touched: f }, user))
      yield put({ type: 'NOTIFY', data: { title: 'Error', body: resolveError(e, fields) || 'Unresolvable error', type: 'error' } })
    }
    yield put({ type: 'UPDATE_MODEL_ERROR', message: JSON.stringify(e) })
    if (action.data.reject) {
      action.data.reject(e)
    }
  }
}

export function* updateHomePage(action) {
  try {
    const t = yield select(stored.TOKEN)
    if (!action.data.values) { throw new PDMSError('No data provided') }
    // eslint-disable-next-line no-unused-vars
    const { modelname, endpoint, noloader, quiet, id, ...f } = action.data.values
    if (!modelname) { throw new PDMSError('No modelname provided') }
    logEvent(`UPDATE_HOME_PAGE: ${modelname.toUpperCase()}`, { id, body: { ...f } })
    const uri = `${apigw + endpoint}`
    const r = yield call(request, uri, { method: 'PATCH', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(f) })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    const cache = { [modelname]: { [body.id]: body } }
    if (action.data.resolve) {
      action.data.resolve(body.id)
    }
    yield put({ type: 'UPDATE_HOME_PAGE_SUCCESS', token: r.token, modelname: modelname, body: body, cache: cache })
    logEvent(`UPDATE_HOME_PAGE_SUCCESS: ${modelname.toUpperCase()}`)
  } catch (e) {
    const modelname = action.data.values ? action.data.values.modelname : false
    if (modelname) {
      const config = yield select(stored.CONFIG, modelname)
      yield put({ type: 'NOTIFY', data: { title: 'Error', body: resolveError(e, config.get('fields').toJS()) || 'Unresolvable error', type: 'error' } })
    }
    yield put({ type: 'UPDATE_HOME_PAGE_ERROR', message: JSON.stringify(e) })
    if (action.data.reject) {
      action.data.reject(e)
    }
  }
}

export function* dismissAlert(action) {
  try {
    const t = yield select(stored.TOKEN)
    if (!action.data.callback_id) { throw new PDMSError('No data provided') }
    const uri = `${apigw + socketwriter}/dissmiss-notification/`
    const body = { callback_id: action.data.callback_id }
    const r = yield call(request, uri, { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(body) })
    if (!r.ok) { throw r }
  } catch (e) {
    log.error(e)
  }
}

export function* generateQR(action) {
  const { modelname, id, name } = action.data
  let { url } = action.data
  const model = yield select(stored.CACHEDMODELID, modelname, id)
  try {
    if (!model && !url) { throw new PDMSError('QR Code: "model" not found') }
    if (!url) {
      url = getIn(model, [ 'meta', 'url', 'website' ])
    }
    const qr = new QRious({ value: url, size: 512 })
    const image = new Image(512, 512)
    image.src = qr.toDataURL()
    image.setAttribute('style', 'width: 1px; height: 1px; opacity: 0;')
    const filename = name ? `${name}-QR.png` : `${model.get('web_ref')}-QR.png`
    const doc = document.body || document.documentElement
    doc.appendChild(image)
    image.addEventListener('load', () => {
      try {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        canvas.width = 512
        canvas.height = 512
        context.drawImage(image, 0, 0)
        canvas.toBlob(blob => {
          window.URL = window.URL || window.webkitURL
          const a = document.createElement('a')
          const blobUrl = window.URL.createObjectURL(blob)
          a.href = blobUrl
          a.target = '_parent'
          if ('download' in a) { a.download = filename }
          doc.appendChild(a)
          a.click()
          a.remove()
          image.remove()
          URL.revokeObjectURL(a.href)
        }, 'image/png')
      } catch (r) {
        image.src = qr.toDataURL()
        const w = window.open(qr.toDataURL())
        w.document.write('<html><head><title>QR Code</title></head><body></body></html>')
        w.document.body.appendChild(image)
        // Force download of QR code - NOT OPTIMAL as it fails on certain browsers but works in Chrome.
        const a = w.document.createElement('a')
        a.href = qr.toDataURL()
        a.download = filename
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    })
  } catch (e) {
    log.error(e)
    yield put({ type: 'GENERATE_QR_ERROR', message: JSON.stringify(e) })
  }
}

export function* downloadImages(action) {
  try {
    const t = yield select(stored.TOKEN)
    if (!action.data.values) { throw new PDMSError('No form data provided') }
    const { args, modelname, ...values } = action.data.values
    let modeltype = ''
    if (args && args.item_type) {
      modeltype = args.item_type === 'agent' ? 'agents' : args.item_type
    }
    let selected = values.selected
    if (modelname === 'documents') {
      let selected_documents
      switch (args.scope) {
        case 'model': { // All items on page
          selected_documents = yield select(stored.MODEL, `${modelname}${modeltype}`)
          selected_documents = selected_documents.get('index') && List.isList(selected_documents.get('index')) ? selected_documents.get('index') : null
          break
        }
        case 'selected': { // Only selected items
          selected_documents = yield select(stored.SELECTED, `${modelname}${modeltype}`)
          selected_documents = selected_documents && List.isList(selected_documents) ? (
            selected_documents
          ) : [ values.id ]
          break
        }
        default:
          break
      }
      if (selected_documents) {
        selected = []
        for (const id of selected_documents) {
          if (id) {
            const selected_document = yield select(stored.CACHEDMODELID, 'documents', id)
            selected.push(getIn(selected_document, [ 'document' ]))
          }
        }
      }
    }
    const r = yield call(request, `${apigw}/gallery/api/v1/files/download/`, { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify({ id__in: selected, filename: values.filename }) })
    if (!r.ok) { throw r }
    action.data.resolve(r.body)
    yield put({
      type: 'DOWNLOAD_IMAGES_SUCCESS',
      token: r.token
    })
  } catch (e) {
    yield put({ type: 'DOWNLOAD_IMAGES_ERROR', message: JSON.stringify(e) })
    action.data.reject(e)
  }
}


export function* changeCaption(action) {
  try {
    const t = yield select(stored.TOKEN)
    if (!action.data.values) { throw new PDMSError('No form data provided') }
    const r = yield call(request, `${apigw}/gallery/api/v1/files/${action.data.values.id}/`, { method: 'PATCH', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(action.data.values) })
    if (!r.ok) { throw r }
    action.data.resolve(JSON.parse(r.body))
    yield put({
      type: 'CHANGE_CAPTION_SUCCESS',
      token: r.token,
      body: r.body
    })
  } catch (e) {
    yield put({ type: 'CHANGE_CAPTION_ERROR', message: JSON.stringify(e) })
    action.data.reject(e)
  }
}

export function* changeDate(action) {
  try {
    const t = yield select(stored.TOKEN)
    if (!action.data.values) { throw new PDMSError('No form data provided') }
    const r = yield call(request, `${apigw}/gallery/api/v1/files/${action.data.values.id}/`, { method: 'PATCH', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(action.data.values) })
    if (!r.ok) { throw r }
    action.data.resolve(JSON.parse(r.body))
    yield put({
      type: 'CHANGE_DATE_SUCCESS',
      token: r.token,
      body: r.body
    })
  } catch (e) {
    yield put({ type: 'CHANGE_DATE_ERROR', message: JSON.stringify(e) })
    action.data.reject(e)
  }
}

export function* rotateImage(action) {
  try {
    const t = yield select(stored.TOKEN)
    if (!action.data.values) { throw new PDMSError('No form data provided') }
    const payload = { direction: action.data.values.direction }
    const r = yield call(request, `${apigw}/gallery/api/v1/files/${action.data.values.id}/rotate/`, { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(payload) })
    if (!r.ok) { throw r }
    action.data.resolve(JSON.parse(r.body))
    yield put({
      type: 'ROTATE_IMAGE_SUCCESS',
      token: r.token,
      body: JSON.parse(r.body)
    })
  } catch (e) {
    yield put({ type: 'ROTATE_IMAGE_ERROR', message: JSON.stringify(e) })
    action.data.reject(e)
  }
}

export function* uploadFile(action) {
  try {
    const t = yield select(stored.TOKEN)
    if (!action.data.values) { throw new PDMSError('No form data provided') }
    const f = new FormData()
    let file = {}
    if (typeof action.data.values.file === 'object' && action.data.values.file.length > 0) {
      file = action.data.values.file[0]
    } else {
      file = action.data.values.file
    }
    f.append('file', file)
    f.append('file_type', action.data.values.filetype || 'file')
    f.append('caption', action.data.values.caption || '')
    f.append('ordinal', action.data.values.ordinal || 0)
    f.append('model', action.data.values.model)
    f.append('private', action.data.values.private ? 1 : 0)
    const r = yield call(upload, `${apigw}/gallery/api/v1/files/`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${t}`,
          'Content-Disposition': `form-data; name="${file.name}"; filename="${file.name}"`
        },
        body: f
      },
      e => {
        const done = e.position || e.loaded, total = e.totalSize || e.total
        const completed = done / total * 100
        if (action.data.progress) { action.data.progress(completed) }
      }
    )
    if (!r.ok) { throw r }
    action.data.resolve(r.body)
    yield put({ type: 'UPLOAD_FILE_SUCCESS', token: r.token })
  } catch (e) {
    action.data.reject(e)
    yield put({ type: 'UPLOAD_FILE_ERROR', message: JSON.stringify(e) })
  }
}

export function* alertAgentPropertyLead(action) {
  try {
    const loading = yield select(stored.LOADING)
    if (!loading) { yield put({ type: 'SHOW_LOADER', action }) }
    if (!action.data.values) { throw new PDMSError('No data provided') }
    const t = yield select(stored.TOKEN)
    const user = yield select(stored.USER)
    const payload = {
      ...action.data.values,
      user: user.getIn([ 'id' ]),
      agent: user.getIn([ 'agent', 'id' ])
    }
    const r = yield call(request, `${apigw}/contacts/api/v1/alerts/`, { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(payload) })
    const params = { offset: 0, limit: 100, meta_fields: [ 'contact', 'profile' ] }
    if (action.data.values.residential) {
      params.residential = action.data.values.residential
    }
    if (action.data.values.commercial) {
      params.commercial = action.data.values.commercial
    }
    yield put({ type: 'FETCH_MANY', data: { values: { modelname: 'alerts', params, modellist: true } } })
    if (!r.ok) { throw r.body || r }
    const body = JSON.parse(r.body)
    // body.profile = action.data.values.profile
    yield put({ type: 'NOTIFY', data: { title: 'Email sent', body: 'The lead has been notified by email.', type: 'success' } })
    yield put({ type: 'ALERT_AGENTPROPERTYLEAD_SUCCESS', body })
    action.data.resolve(JSON.parse(r.body))
  } catch (e) {
    yield put({ type: 'NOTIFY', data: { title: 'Error', body: JSON.stringify(e), type: 'success' } })
    yield put({ type: 'ALERT_AGENTPROPERTYLEAD_ERROR', message: JSON.stringify(e) })
    action.data.reject(e)
  }
}

export function* notifyReferralRequest(action) {
  try {
    const loading = yield select(stored.LOADING)
    if (!loading) { yield put({ type: 'SHOW_LOADER', action }) }
    if (!action.data.values) { throw new PDMSError('No data provided') }
    const t = yield select(stored.TOKEN)
    const user = yield select(stored.USER)
    const payload = {
      ...action.data.values,
      user: user.getIn([ 'id' ]),
      sending_agent: user.getIn([ 'agent', 'id' ])
    }
    const r = yield call(request, `${apigw}/contacts/api/v1/referrals/referral-request/`, { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(payload) })
    if (!r.ok) { throw r.body || r }
    const body = JSON.parse(r.body)
    yield put({ type: 'NOTIFY', data: { title: 'Email sent', body: 'Your request for referral has been sent.', type: 'success' } })
    yield put({ type: 'NOTIFY_REFERRALREQUEST_SUCCESS', body })
    action.data.resolve(JSON.parse(r.body))
  } catch (e) {
    yield put({ type: 'NOTIFY', data: { title: 'Error', body: JSON.stringify(e), type: 'success' } })
    yield put({ type: 'NOTIFY_REFERRALREQUEST_ERROR', message: JSON.stringify(e) })
    action.data.reject(e)
  }
}

/* No authentication required */


export function* doLogin(action) {
  try {
    const loading = yield select(stored.LOADING)
    const router = yield select(stored.ROUTER)
    if (!loading) { yield put({ type: 'SHOW_LOADER', action }) }
    if (!action.data.values.username && !action.data.values.code) { throw new PDMSError('Email is required') }
    if (!action.data.values.password && !action.data.values.code) { throw new PDMSError('A password is required') }
    let a
    let sso_login
    if (!action.data.values.code) {
      a = `Basic ${encode(`${action.data.values.username}:${action.data.values.password}`)}`
      sso_login = false
    } else {
      a = `Basic ${encode(`${action.data.values.state}:${action.data.values.code}`)}`
      sso_login = true
    }
    const r = yield call(request, `${apigw}/users/public-api/login/`, { method: 'GET', headers: { Authorization: a } })
    if (!r.ok) { throw r }
    let body = false
    try { body = JSON.parse(r.body) } catch (e) { throw new PDMSError('Invalid login') }
    if (body.agents.length === 0) { throw new PDMSError('Invalid login') } // No active agents
    const search = yield select(stored.SEARCH)
    yield put({ type: 'LOGIN_SUCCESS', body })
    let agent
    const redirect = router.location.pathname
    const match = matchPath(redirect, { path: '/secure/:site(\\d+)', exact: false, strict: false })
    if (body.agents.length === 1) {
      agent = body.agents[0]
      yield put({ type: 'SELECT_AGENT', agent, user: body, redirect: redirect ? redirect : null })
    } else if (match && match.params.site) {
      const site = parseInt(match.params.site, 10)
      agent = body.agents.find(ag => ag.site.id === site)
      if (agent) {
        yield put({ type: 'SELECT_AGENT', agent, user: body, redirect: redirect ? redirect : null })
      }
    } else if (search) {
      const qs = new QueryBuilder(search)
      if (qs.getParam('site')) {
        agent = body.agents.find(ag => ag.site.domain === qs.getParam('site'))
        if (agent) {
          yield put({ type: 'SELECT_AGENT', agent, user: body, redirect: redirect ? redirect : null })
        }
      }
    }
    let event_name = 'LOGIN_SUCCESS'
    if (sso_login) {
      event_name = 'SSO_LOGIN_SUCCESS'
    }
    logEvent(event_name, { user: body.email, id: body.id })
    action.data.resolve(r)
  } catch (e) {
    yield put({ type: 'LOGIN_ERROR', message: JSON.stringify(e) })
    action.data.reject(e)
    log.error(e)
  }
}

export function* selectAgent(action) {
  try {
    const loading = yield select(stored.LOADING)
    if (!loading && !action.noloader) { yield put({ type: 'SHOW_LOADER', action }) }
    let r = false
    if (!action.user) { throw new PDMSError('No user provided') }
    if (typeof action.agent === 'undefined' || action.agent === false) { throw new PDMSError('No agent selected') }
    let agent = action.agent
    if ((action.agent.id === null || action.agent.id === undefined || action.agent.id === 0) && !action.user.is_prop_data_user) { throw new PDMSError('No agent provided') }
    if (action.agent.id) { // We call the mashup microservice to get the agent image amongst other things
      const uri = `${apigw}/mashup/api/v1/agents/${action.agent.id}/?meta_fields=branches`
      r = yield call(request, uri, { method: 'GET', headers: { Authorization: `Bearer ${action.agent.token}` } })
      if (!r.ok) { throw r }
      const body = JSON.parse(r.body)
      delete body.site
      agent = { ...action.agent, ...body }
    }
    const newuser = { alerts: [], ...action.user }

    const sentry = log.transports.find(transport => transport.name === 'winston-sentry')
    if (sentry && agent) {
      sentry.sentryClient.configureScope(scope => {
        scope.setTag('domain', agent.site.domain)
        const permissions = [ ...Object.keys(agent.permissions).filter(k => agent.permissions[k]) ]
        if (action.user.is_prop_data_user) { permissions.push('is_prop_data_user') }
        scope.setExtra('agent', { ...agent, permissions, token: '*****' })
        scope.setUser({
          email: action.user.email,
          id: action.user.id,
          username: `${agent.first_name} ${agent.last_name}`
        })
      })
    }
    // We need to sequence this call as almost everything relies on the site settings.

    const all_configs = yield getAllConfigs(agent.site.region)
    yield put({ type: 'MERGED_CONFIGS_SUCCESS', configs: all_configs })

    yield* fetchOne({ type: 'FETCH_ONE', modelname: 'settings', id: agent.site.id, token: action.agent.token, noloader: true })

    const settings = yield select(stored.SETTINGS, agent.site.id)
    const supported_countries = settings.get('supported_countries')
    try { // Try/catch because jest can't use dexie
      for (let i = 0; i < supported_countries.count(); i++) {
        const csvdb = `${process.env.PUBLIC_URL}/locations/${supported_countries.get(i)}.csv`
        if (process.env.REACT_APP_ENV !== 'e2e') { // Cant use IDB in puppeteer
          // eslint-disable-next-line no-loop-func
          yield request(csvdb).then(response => {
            const etag = localStorage.getItem(`dbTag${supported_countries.get(i)}`)
            const dbverno = window.localStorage.getItem('dbverno')
            if (response.headers.get('ETag') && etag === response.headers.get('ETag') && dbverno === packageinfo.idbversion) {
              return
            }

            const worker = new Worker(new URL('./locations.worker.js', import.meta.url))
            worker.postMessage(response.body)
            worker.addEventListener('message', ev => {
              localStorage.setItem(`dbTag${supported_countries.get(i)}`, response.headers.get('ETag'))
              window.localStorage.setItem('dbverno', ev.data)
              worker.terminate()
            })
          })
        } else { // E2E, development, test etc.
          // eslint-disable-next-line no-console
          console.log('E2E detected - loading locations')
          yield loadLocations(csvdb)
          yield new Promise(sleep => setTimeout(sleep, 1000))
        }
      }
    } catch (e) {
      log.warn(e)
    } finally {
      // Calculate where to send user. Defaults to :
      // - The previous location (LoadUser.js) then..
      // - The outcome of the below permissions based redirect then..
      // - The branch list view (from Login.js) - all users can access this.
      const router = yield select(stored.ROUTER)
      let redirect = yield select(stored.REDIRECT)
      if (!redirect || redirect.get('pathname') === '/login') {
        if (router.location && router.location.pathname.indexOf('/secure/') !== -1) {
          const params = router.location.search || ''
          const h = router.location.hash
          redirect = `${router.location.pathname}${params}${h}`
        } else if (agent.default_home_page && agent.default_home_page !== '/') {
          redirect = `/secure/${agent.site.id}${agent.default_home_page}`
        } else {
          redirect = `/secure/${agent.site.id}`
        }
        if (!redirect) {
          redirect = `/secure/${agent.site.id}`
        }
      }
      const decoded = action.agent.token ? jwt_decode(action.agent.token) : null

      yield put({ type: 'SELECT_AGENT_SUCCESS', agent, user: newuser, redirect, settings, decoded })
      logEvent('SELECT_AGENT_SUCCESS', {
        agent_name: `${agent.first_name} ${agent.last_name}`,
        agent_email: agent.email,
        site_id: agent.site.id,
        agent_avatar: agent.image_url,
        domain: agent.site.domain,
        is_prop_data_user: newuser.is_prop_data_user
      })
    }
  } catch (e) {
    console.error(e)
    yield put({ type: 'SELECT_AGENT_ERROR', message: JSON.stringify(e) })
  }
}


export function* unselectAgent() {
  yield put({ type: 'DISCONNECT_WEBSOCKET' })
}


export function* loadToke(action) {
  try {
    const loading = yield select(stored.LOADING)
    if (!loading && !action.noloader) { yield put({ type: 'SHOW_LOADER', action }) }
    const t = action.data ? action.data.token : null
    if (!t) { throw new PDMSError('No token provided') }
    const r = yield call(request, `${apigw}/users/api/v1/renew-token/`, { method: 'GET', headers: { Authorization: `Bearer ${t}` } })
    if (!r.token) { throw new PDMSError('Session expired') }
    const body = JSON.parse(r.body)
    yield put({ type: 'RELOGIN_SUCCESS', body })
    let agent
    if (body.agents.length > 1 && action.data && action.data.agent !== false) {
      agent = body.agents.find(a => {
        if (a.id === parseInt(action.data.agent, 10) && a.site.id === parseInt(action.data.site, 10)) {
          return true
        } else if (a.id === null && action.data.agent === 'null' && a.site.id === parseInt(action.data.site, 10)) {
          return true
        } else if (body.is_prop_data_user && a.id === null && a.site.id === parseInt(action.data.site, 10)) {
          return true
        }
        return false
      })
      if (agent) {
        yield call(selectAgent, { type: 'SELECT_AGENT', agent, user: body, redirect: action.data.redirect })
      }
    } else if (body.agents.length === 1) {
      agent = body.agents[0]
      yield call(selectAgent, { type: 'SELECT_AGENT', agent: body.agents[0], user: body, redirect: action.data.redirect })
    }
    logEvent('RELOGIN_SUCCESS', { user: body, id: body.id, agent })
    if (action.data && action.data.resolve) { action.data.resolve(r) }
  } catch (e) {
    yield put({ type: 'LOAD_TOKE_ERROR', message: JSON.stringify(e), status: 403 })
    if (action.data && action.data.reject) { action.data.reject(e) }
  }
}


export function* renewToken(action) {
  try {
    const loading = yield select(stored.LOADING)
    if (!loading && !action.noloader) { yield put({ type: 'SHOW_LOADER', action }) }
    const t = yield select(stored.TOKEN)
    if (!t) { throw new PDMSError('No token provided') }
    const r = yield call(request, `${apigw}/users/api/v1/renew-token/`, { method: 'GET', headers: { Authorization: `Bearer ${t}` } })
    if (!r.token) { throw new PDMSError('Session expired') }
    const decoded = jwt_decode(r.token)
    yield put({ type: 'RENEW_TOKEN_SUCCESS', r, decoded })
    if (action.data && action.data.resolve) { action.data.resolve(r) }
  } catch (e) {
    yield put({ type: 'RENEW_TOKEN_ERROR', message: JSON.stringify(e), status: 403 })
    if (action.data && action.data.reject) { action.data.reject(e) }
  }
}

export function* sendActivation(action) {
  try {
    if (!action.data.values.email) { throw new PDMSError('Please enter an email address') }
    if (!action.data.values.agent_id) { throw new PDMSError('An agent is required') }
    const t = yield select(stored.TOKEN)

    const loading = yield select(stored.LOADING)
    if (!loading) { yield put({ type: 'SHOW_LOADER', action }) }
    if (!t) { throw new PDMSError('No token provided') }
    logEvent('SEND_ACTIVATION', { body: { ...action.data.values } })
    const r = yield call(request, `${apigw}/users/api/v1/agents/${action.data.values.agent_id}/send-reset/`, { method: 'GET', headers: { Authorization: `Bearer ${t}` } })
    if (!r.ok) { throw r.body || r }
    yield put({ type: 'SEND_ACTIVATION_SUCCESS', body: JSON.parse(r.body) })
    yield put({ type: 'NOTIFY', data: { title: 'Activation sent', body: 'Please check your email.', type: 'info' } })
    action.data.resolve(JSON.parse(r.body))
    const sentry = log.transports.find(transport => transport.name === 'winston-sentry')
    if (sentry) {
      sentry.sentryClient.configureScope(scope => {
        scope.setUser({ email: action.data.values.email })
      })
    }
    logEvent('SEND_ACTIVATION_SUCCESS')
  } catch (e) {
    yield put({ type: 'SEND_ACTIVATION_ERROR', message: JSON.stringify(e) })
    yield put({ type: 'NOTIFY', data: { title: 'Activation error', body: 'Could not send email.', type: 'error' } })
    action.data.reject(e)
    log.error(e)
  }
}

export function* activateUser(action) {
  try {
    if (!action.data.values.hash) { throw new PDMSError('Invalid activation key') }
    const loading = yield select(stored.LOADING)
    if (!loading) { yield put({ type: 'SHOW_LOADER', action }) }
    const r = yield call(request, `${apigw}/users/public-api/accounts/activate/`, { method: 'POST', headers: { 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(action.data.values) })
    if (!r.ok) { throw r.body || r }
    yield put({ type: 'ACTIVATION_SUCCESS', body: JSON.parse(r.body) })
    action.data.resolve(JSON.parse(r.body))
    logEvent('ACTIVATION_SUCCESS', { hash: action.data.values.hash })
  } catch (e) {
    yield put({ type: 'ACTIVATION_ERROR', message: JSON.stringify(e) })
    action.data.reject(e)
    log.error(e)
  }
}

export function* sendReset(action) {
  try { // No auth required to send password reset request
    if (!action.data.values.username) { throw new PDMSError('Email is required') }
    const loading = yield select(stored.LOADING)
    if (!loading) { yield put({ type: 'SHOW_LOADER', action }) }
    const r = yield call(request, `${apigw}/users/public-api/forgot-password/`, { method: 'POST', headers: { 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(action.data.values) })
    if (!r.ok) { throw r }
    const user = yield select(stored.USER)
    if (user.get('agent')) { // User is logged in and needs to be notified
      yield put({ type: 'NOTIFY', data: { title: 'Reset sent', body: 'Please check your email.', type: 'info' } })
    }
    const sentry = log.transports.find(transport => transport.name === 'winston-sentry')
    if (sentry) {
      sentry.sentryClient.configureScope(scope => {
        scope.setUser({ email: user.get('email'), id: user.get('id'), username: `${user.getIn([ 'agent', 'first_name' ])} ${user.getIn([ 'agent', 'last_name' ])}` })
      })
    }
    yield put({ type: 'SEND_RESET_SUCCESS', body: JSON.parse(r.body) })
    logEvent('SEND_RESET_SUCCESS', { user: action.data.values.username })
    action.data.resolve(JSON.parse(r.body))
  } catch (e) {
    yield put({ type: 'SEND_RESET_ERROR', message: JSON.stringify(e) })
    action.data.reject(e)
    log.error(e)
  }
}

export function* doReset(action) {
  try {
    const loading = yield select(stored.LOADING)
    if (!loading) { yield put({ type: 'SHOW_LOADER', action }) }
    if (!action.data.values) { throw new PDMSError('No data provided') }
    const r = yield call(request, `${apigw}/users/public-api/accounts/reset/`, { method: 'POST', headers: { 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(action.data.values) })
    if (!r.ok) { throw r }
    yield put({ type: 'DO_RESET_SUCCESS' })
    logEvent('RESET_SUCCESS', { user: action.data.values.username })
    action.data.resolve(r)
  } catch (e) {
    yield put({ type: 'DO_RESET_ERROR', message: JSON.stringify(e) })
    action.data.reject(e)
    log.error(e)
  }
}

export function* selectOne(action) {
  try {
    const data = action.values ? action.values : action
    if (!data.modelname) { throw new PDMSError('No modelname provided') }
    if (!data.id) { throw new PDMSError('No id provided') }
    yield put({ type: 'SELECT_ONE_SUCCESS', modelname: data.modelname, select: data.select, id: data.id })
  } catch (e) {
    yield put({ type: 'SELECT_ONE_ERROR', message: JSON.stringify(e) })
  }
}

export function* selectAll(action) {
  try {
    if (!action.modelname) { throw new PDMSError('No modelname provided') }
    const model = yield select(stored.MODEL, action.modelname)
    const selected = model ? model.get('index') : []
    yield put({ type: 'SELECT_ALL_SUCCESS', selected, modelname: action.modelname })
  } catch (e) {
    yield put({ type: 'SELECT_ALL_ERROR', message: JSON.stringify(e) })
  }
}

export function* addTableField(action) {
  try {
    if (!action.data.values) { throw new PDMSError('No form data provided') }
    yield put({ type: 'ADD_TABLE_FIELD_SUCCESS', ...action.data.values })
    action.data.resolve(action.values)
  } catch (e) {
    yield put({ type: 'ADD_TABLE_CONFIG_FIELD_ERROR', message: JSON.stringify(e) })
    action.data.reject(e)
  }
}

export function* removeTableField(action) {
  try {
    if (!action.data.values) { throw new PDMSError('No form data provided') }
    yield put({ type: 'REMOVE_TABLE_FIELD_SUCCESS', ...action.data.values })
    action.data.resolve(action.values)
  } catch (e) {
    yield put({ type: 'REMOVE_TABLE_CONFIG_FIELD_ERROR', message: JSON.stringify(e) })
    action.data.reject(e)
  }
}

export function* exportData(action) {
  if (!action.data.modelname) { throw new PDMSError('No modelname provided') }
  const { args, label, params } = action.data
  let parms = {}

  if (params) {
    parms = { ...params }
  }
  const { modelname } = action.data
  const { fields: useFields, format } = args
  let { scope, filters, template, userfields } = args
  logEvent(`EXPORT_DATA: ${args.action.toUpperCase()}`, {
    args: args,
    params: parms,
    useFields,
    fields: userfields,
    scope: scope,
    id: action.data.id
  })
  try {
    if (!filters) { filters = {} }
    if (scope) {
      switch (scope) {
        case 'model': { // All items on page
          let selected = yield select(stored.MODEL, modelname)
          selected = selected.get('index') && List.isList(selected.get('index')) ? selected.get('index').map(String).join(',') : null
          filters.id__in = selected
          delete parms.offset
          delete parms.limit
          break
        }
        case 'selected': { // Only selected items
          let selected = yield select(stored.SELECTED, modelname)
          if (selected && selected.size === 1 && template.startsWith(':')) {
            const model = yield select(stored.CACHEDMODELID, modelname, getIn(selected, [ 0 ]))
            template = parseURL(template, model.toJS())
          }
          selected = selected && List.isList(selected) ? selected.map(String).join(',') : null
          filters.id__in = selected ? selected : [ action.data.id ]
          delete parms.offset
          delete parms.limit
          break
        }
        default:
          break
      }
    } else {
      scope = {}
      delete parms.offset
      delete parms.limit
    }
    let reportfields = [] // Initialise the final report fields array as empty
    const config = yield select(stored.CONFIG, modelname)
    if (useFields) { // We need to send the current user fields for this model as well which allows presentation based on field selection
      if (!userfields) {
        userfields = yield select(stored.PREFERENCES, modelname)
      } else {
        userfields = fromJS(userfields)
      }
      const configfields = config.get('fields').map(f => f.get('name'))
      if (userfields) {
        userfields.forEach(f => {
          if (List.isList(f.get('name')) && [ 'undefined', false, undefined ].includes(
            f.get('permissions') && f.get('permissions').includes('is_prop_data_user') || f.get('protected'))
          ) {
            if (configfields.includes(f.get('name'))) {
              reportfields.push(f.get('name'))
            }
            f.get('name').forEach(cf => { // Extract composite field name
              if (configfields.includes(cf)) { reportfields.push(cf) } // Exclude non-config fields (like ',' or '/')
            })
          } else if (f.get('children')) {
            f.get('children').forEach(cf => { // Extract child field name
              if (cf.get('container') && configfields.includes(cf.get('name')) && [ 'undefined', false, undefined ].includes(
                cf.get('permissions') && cf.get('permissions').includes('is_prop_data_user') || cf.get('protected'))
              ) {
                if ([ 'residential', 'commercial', 'project', 'holiday' ].includes(modelname) && cf.get('container') !== 'portals') {
                  reportfields.push(`${cf.get('container') === 'stats' ? 'statistics' : cf.get('container')}.${cf.get('name')}`)
                } else if ([ 'residential', 'commercial', 'project', 'holiday' ].includes(modelname) && cf.get('container') === 'portals') {
                  reportfields.push(cf.get('name')) // fix portal fields for listings
                } else if (![ 'residential', 'commercial', 'project', 'holiday' ].includes(modelname)) {
                  reportfields.push(`${cf.get('container') === 'stats' ? 'statistics' : cf.get('container')}.${cf.get('name')}`)
                }
              } else if (configfields.includes(cf.get('name')) && [ 'undefined', false, undefined ].includes(
                cf.get('permissions') && cf.get('permissions').includes('is_prop_data_user') || cf.get('protected'))
              ) {
                reportfields.push(cf.get('name')) // Exclude non-config fields (like ',' or '/')
              }
            })
          } else if (f.get('container') && [ 'undefined', false, undefined ].includes(f.get('permissions') &&
            f.get('permissions').includes('is_prop_data_user') || f.get('protected'))
          ) {
            if ([ 'residential', 'commercial', 'project', 'holiday' ].includes(modelname) && f.get('container') !== 'portals') {
              reportfields.push(`${f.get('container') === 'stats' ? 'statistics' : f.get('container')}.${f.get('name')}`)
            } else if ([ 'residential', 'commercial', 'project', 'holiday' ].includes(modelname) && f.get('container') === 'portals') {
              reportfields.push(f.get('name')) // fix portal fields for listings
            } else if (![ 'residential', 'commercial', 'project', 'holiday' ].includes(modelname)) {
              reportfields.push(`${f.get('container') === 'stats' ? 'statistics' : f.get('container')}.${f.get('name')}`)
            }
          } else if (f.get('exportable') || [ 'undefined', false, undefined ].includes(
            f.get('permissions') && f.get('permissions').includes('is_prop_data_user') || f.get('protected'))
          ) {
            reportfields.push(f.get('name'))
          }
        })
      }
    } else { // We need to send all the fields in the order of the add / edit form
      config.get('fieldgroups').keySeq().toArray().forEach(group => {
        config.get('fields').filter(field => field.get('group') === group && [ 'undefined', false, undefined ].includes(
          field.get('permissions') && field.get('permissions').includes('is_prop_data_user') || field.get('protected')) || field.get('exportable')
        ).forEach(f => {
          if (f.get('container') && ([ 'residential', 'commercial', 'project', 'holiday' ].includes(modelname) && f.get('container') !== 'portals')) {
            reportfields.push(`${f.get('container') === 'stats' ? 'statistics' : f.get('container')}.${f.get('name')}`)
          } else {
            reportfields.push(f.get('name'))
          }
        })
      })
      if (!reportfields.includes('id')) {
        reportfields = [ 'id', ...reportfields ]
      }
    }
    if (args.action === 'brochure' && !action.data.no_loader) { yield put({ type: 'SHOW_LOADER', action }) }
    let rid = uuidv4()
    if (action.data.retry) {
      rid = action.data.rid
    }
    const created = new Date().toISOString()
    Object.keys(parms).filter(f => f.endsWith('_overlap')).forEach(f => {
      if (Array.isArray(parms[f])) {
        parms[f] = `{${parms[f].join(',')}}`
      } else {
        parms[f] = `{${parms[f]}}`
      }
    })
    const data = {
      token: yield select(stored.TOKEN),
      callback_id: rid,
      action: args.action,
      name: label,
      created,
      payload: {
        model: config.get('servicename'),
        filters: { ...filters, ...parms },
        template,
        format: format,
        useFields,
        fields: reportfields
      }
    }

    yield put({ type: 'DISPATCH_WEBSOCKET_REQUEST', payload: data })
    if (!action.data.noalert) {
      if (!action.data.retry) {
        yield put({
          type: 'NOTIFY',
          data: {
            title: label,
            body: 'Generating, please be patient.',
            type: 'info'
          }
        })
      }
      yield put({ // for messages that generate an alert
        type: 'PUSH_ALERT',
        data: {
          payload: {
            show: true,
            callback_id: rid,
            name: label,
            model: modelname,
            text: 'Generating...',
            created,
            filters: { ...filters, ...params },
            template
          },
          resolve: action.data.resolve,
          callback: action.data.callback
        }
      })
    } else {
      yield put({ // for messages that do not generate an alert
        type: 'PUSH_MESSAGE',
        data: {
          payload: {
            show: true,
            callback_id: rid,
            name: label,
            model: modelname,
            text: 'Generating...',
            created,
            filters: { ...filters, ...params },
            template,
            resolve: action.data.resolve,
            reject: action.data.reject,
            callback: action.data.callback
          }
        }
      })
    }
  } catch (e) {
    console.error(e)
    yield put({ type: 'EXPORT_DATA_ERROR', message: JSON.stringify(e) })
    if (action.data.reject) { action.data.reject(e) }
  }
}

export function* getLeadsAnalysisData(action) {
  try {
    if (!action.data.modelname) { throw new PDMSError('No modelname provided') }
    const { args, label, params } = action.data
    const { ...parms } = params
    const { modelname } = action.data
    const { template } = args
    let { scope, filters } = args
    if (!filters) { filters = {} }
    if (scope) {
      switch (scope) {
        case 'model': { // All items on page
          let selected = yield select(stored.MODEL, modelname)
          selected = selected.get('index') && List.isList(selected.get('index')) ? selected.get('index').map(String).join(',') : null
          filters.id__in = selected
          break
        }
        case 'selected': { // Only selected items
          let selected = yield select(stored.SELECTED, modelname)
          selected = selected && Array.isArray(selected) ? selected.map(String).join(',') : action.data.id
          filters.id__in = selected
          delete parms.offset
          delete parms.limit
          break
        }
        default:
          break
      }
    } else {
      scope = {}
      delete parms.offset
      delete parms.limit
    }
    Object.keys(parms).filter(f => f.endsWith('_overlap')).forEach(f => {
      if (Array.isArray(parms[f])) {
        parms[f] = `{${parms[f].join(',')}}`
      } else {
        parms[f] = `{${parms[f]}}`
      }
    })
    const config = yield select(stored.CONFIG, modelname)
    const rid = uuidv4()
    const created = new Date().toISOString()
    const data = {
      token: yield select(stored.TOKEN),
      callback_id: rid,
      action: args.action,
      created,
      name: label,
      payload: {
        model: config.get('servicename'),
        filters: { ...filters, ...parms },
        template
      }
    }
    yield put({
      type: 'NOTIFY',
      data: {
        title: label,
        body: 'Generating, please be patient.',
        type: 'info'
      }
    })
    yield put({ // for messages that generate an alert
      type: 'PUSH_MESSAGE',
      data: {
        payload: {
          show: true,
          callback_id: rid,
          name: label,
          model: modelname,
          text: 'Generating...',
          created,
          filters: { ...filters, ...params },
          template
        },
        resolve: action.data.resolve
      }
    })
    yield put({ type: 'DISPATCH_WEBSOCKET_REQUEST', payload: data })
  } catch (e) {
    yield put({ type: 'GET_LEADS_ANALYSIS_ERROR', message: JSON.stringify(e) })
    if (action.data.reject) { action.data.reject(e) }
  }
}

export function* getLeadsBreakdown(action) {
  const { params, resolve, reject, signal, ...fields } = action.data
  try {
    const t = yield select(stored.TOKEN)
    const uri = `${apigw}/mashup/api/v1/leads/analysis/?fields=leads&subsets=leads.source_counts`
    const filters = {}
    Object.keys(params).forEach(k => {
      if (params[k]) {
        filters[k] = params[k]
      }
    })
    const payload = { filters }

    const qs = new QueryBuilder(uri)
    qs.setParam(fields)
    const r = yield call(request, qs.url(false, true), { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(payload), signal })
    if (!r.ok) { throw r }
    const s = JSON.parse(r.body)
    yield put({ type: 'GET_LEADS_BREAKDOWN_SUCCESS', results: s })
    resolve(s)
  } catch (e) {
    log.error(e)
    yield put({ type: 'GET_LEADS_BREAKDOWN_ERROR', message: JSON.stringify(e) })
    if (action.data.reject) { reject(e) }
  }
}

export function* getAlertsBreakdown(action) {
  const { params, resolve, reject } = action.data
  try {
    const t = yield select(stored.TOKEN)
    const uri = `${apigw}/contacts/api/v1/alerts/type-counts/`
    const qs = new QueryBuilder(uri)
    qs.setParam(params)
    const r = yield call(request, qs.url(false, true), { method: 'GET', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' } })
    if (!r.ok) { throw r }
    const s = JSON.parse(r.body)
    yield put({ type: 'GET_ALERTS_BREAKDOWN_SUCCESS', results: s })
    resolve(s)
  } catch (e) {
    log.error(e)
    yield put({ type: 'GET_ALERTS_BREAKDOWN_ERROR', message: JSON.stringify(e) })
    if (action.data.reject) { reject(e) }
  }
}


export function* fetchModelStats(action) {
  try {
    const loading = yield select(stored.LOADING)
    if (!loading && !action.noloader) { yield put({ type: 'SHOW_LOADER', action }) }
    const t = yield select(stored.TOKEN)
    const uri = `${apigw}/mashup/api/v1/${action.modelname}/?order_by=-${action.orderby}`
    const r = yield call(request, uri, { method: 'GET', headers: { Authorization: `Bearer ${t}` } })
    if (!r.ok) { throw r }
    const s = JSON.parse(r.body)
    const stats = s.results.map(a => {
      const name = a.name ? a.name : a.meta.full_name
      const modelid = a.id
      return ({
        ...a.meta.statistics,
        name,
        modelid
      })
    })
    const delta = { [action.modelname]: [ ...stats ] }
    yield put({ type: 'FETCH_MODEL_STATS_SUCCESS', delta, token: r.token })
  } catch (e) {
    yield put({ type: 'FETCH_MODEL_STATS_ERROR', message: JSON.stringify(e) })
  }
}

export function* fetchLocations(action) {
  try {
    const t = yield select(stored.TOKEN)
    const { values, resolve } = action.data
    const uri = `${apigw}/listings/api/v1/${values.modelname}/locations/`
    if (!values.params) { throw 'No search params' }
    const qs = new QueryBuilder(uri)
    qs.setParam(values.params)
    const r = yield call(request, qs.url(false, true), { method: 'GET', headers: { Authorization: `Bearer ${t}` } })
    if (!r.ok) { throw r }
    const s = JSON.parse(r.body)
    yield put({ type: 'FETCH_LOCATIONS_SUCCESS' })
    resolve(s)
  } catch (e) {
    yield put({ type: 'FETCH_LOCATIONS_ERROR', message: JSON.stringify(e) })
  }
}

export function* fetchServicedAttributes(action) {
  try {
    const t = yield select(stored.TOKEN)
    const { values, resolve } = action.data
    const payload = {
      areas: values.areas,
      suburbs: values.suburbs,
      listing_types: values.listing_types,
      property_types: values.property_types,
      property_zoning: values.property_zoning,
      model: values.modelname
    }
    const uri = `${apigw}/users/api/v1/serviced-locations/`
    const r = yield call(request, uri, { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(payload) })
    if (!r.ok) { throw r }
    const s = JSON.parse(r.body)
    yield put({ type: 'FETCH_SERVICED_ATTRIBUTES_SUCCESS' })
    resolve(s)
  } catch (e) {
    yield put({ type: 'FETCH_SERVICED_ATTRIBUTES_ERROR', message: JSON.stringify(e) })
    action.data.reject(e)
  }
}

export function* fetchReferralContactMatch(action) {
  try {
    const t = yield select(stored.TOKEN)
    const { values, resolve } = action.data
    const payload = {
      contact: values.contact,
      branch: values.branch
    }
    const uri = `${apigw}/contacts/api/v1/referrals/contact-match/`
    const r = yield call(request, uri, { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(payload) })
    if (!r.ok) { throw r }
    const s = JSON.parse(r.body)
    yield put({ type: 'FETCH_REFERRAL_CONTACT_MATCH_SUCCESS' })
    resolve(s)
  } catch (e) {
    yield put({ type: 'FETCH_REFERRAL_CONTACT_MATCH_ERROR', message: JSON.stringify(e) })
  }
}

export function* fetchDeeds(action) {
  const { data } = action
  const { resolve, reject, vals } = data
  yield put({ type: 'SHOW_LOADER', action })
  try {
    const t = yield select(stored.TOKEN)
    const uri = `${apigw}/listings/api/v1/deeds-search/`
    const r = yield call(request, uri, { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(vals) })
    if (!r.ok) { throw r }
    const s = JSON.parse(r.body)
    yield put({ type: 'FETCH_DEEDS_SUCCESS', s, token: r.token })
    resolve(s)
  } catch (e) {
    yield put({ type: 'FETCH_DEEDS_ERROR', message: JSON.stringify(e) })
    reject(e)
  }
}

export function* checkVersion() {
  try {
    const uri = '/'
    const r = yield call(request, uri, { method: 'HEAD' })
    let newversion = packageinfo.version
    if (
      r.headers.get('x-amz-meta-pdms') !== null &&
      r.headers.get('x-amz-meta-pdms') !== packageinfo.version
      && process.env.NODE_ENV !== 'development' // Puppeteer - comment this line to test version checking in dev
    ) {
      newversion = r.headers.get('x-amz-meta-pdms')
      yield put({ type: 'NOTIFY', data: { title: 'New version available', body: 'Click here to update Prop Data Manage', force: true, link: window.location.href, type: 'info' } })
    }
    yield put({ type: 'VERSION_CHECK_SUCCESS', newversion, oldversion: packageinfo.version })
  } catch (e) {
    yield put({ type: 'VERSION_CHECK_ERROR', message: JSON.stringify(e) })
  }
}

export function* autosaveForm(action) {
  const { data } = action
  let key = false
  try {
    const t = yield select(stored.TOKEN)
    if (!t) { throw new PDMSError('No token provided') }
    const r = yield call(request, `${apigw}/users/api/v1/renew-token/`, { method: 'GET', headers: { Authorization: `Bearer ${t}` } })
    if (!r.token) { throw new PDMSError('Session expired') }
    if (data.mode === 'add') {
      key = `autosave:${data.userid}:${data.modelname}:${data.mode}`
    } else {
      key = `autosave:${data.userid}:${data.modelname}:${data.mode}:${data.modelid}`
    }
    window.localStorage.setItem(key, JSON.stringify(data.values))
    yield put({ type: 'AUTOSAVE_FORM_SUCCESS', token: r.token })
  } catch (e) {
    yield put({ type: 'AUTOSAVE_FORM_ERROR', message: JSON.stringify(e) })
  }
}

export function* recalculateCommission(action) {
  const { field, form } = action.data
  try {
    const { mandate_commission_amount, mandate_commission_percentage, price } = form.values
    let amount
    let percent
    if (field.name === 'mandate_commission_amount') {
      // eslint-disable-next-line max-len
      percent = ((parseFloat(mandate_commission_amount) / parseFloat(price)) * 100).toFixed(2)
    } else if (field.name === 'mandate_commission_percentage') {
      // eslint-disable-next-line max-len
      amount = ((parseFloat(mandate_commission_percentage) / 100) * parseFloat(price)).toFixed(0)
    } else if (field.name === 'price') {
      // eslint-disable-next-line max-len
      amount = ((parseFloat(mandate_commission_percentage) / 100) * parseFloat(price)).toFixed(0)
    }
    if (!isNaN(amount) && parseFloat(amount) !== parseFloat(mandate_commission_amount)) {
      form.setFieldValue('mandate_commission_amount', amount)
    }
    if (!isNaN(percent) && parseFloat(percent) !== parseFloat(mandate_commission_percentage)) {
      form.setFieldValue('mandate_commission_percentage', percent)
    }
    yield put({ type: 'RECALCULATE_COMMISSION_SUCCESS', values: { percent, amount } })
  } catch (e) {
    yield put({ type: 'RECALCULATE_COMMISSION_ERROR', message: JSON.stringify(e) })
  }
}

export function* autosaveCheck(action) {
  const { data } = action
  let key = false
  try {
    if (data.mode === 'add') {
      key = `autosave:${data.userid}:${data.modelname}:${data.mode}`
    } else {
      key = `autosave:${data.userid}:${data.modelname}:${data.mode}:${data.modelid}`
    }
    const values = window.localStorage.getItem(key)
    if (values && (!isEqual(JSON.parse(values), data.form.initialValues))) {
      yield put({ type: 'NOTIFY', data: {
        title: 'You have unsaved work',
        body: 'An autosaved version is available',
        dismiss: {
          action: 'autosaveDiscard',
          args: {
            label: 'Discard',
            userid: data.userid,
            modelname: data.modelname,
            mode: data.mode,
            modelid: data.modelid
          }
        },
        link: {
          action: 'autosaveApply',
          args: {
            label: 'Restore',
            setInitVals: data.setInitVals,
            form: data.form,
            userid: data.userid,
            modelname: data.modelname,
            mode: data.mode,
            modelid: data.modelid
          }
        },
        type: 'info' }
      })
      yield put({ type: 'AUTOSAVE_FOUND' })
    }
    yield put({ type: 'AUTOSAVE_CHECK_SUCCESS' })
  } catch (e) {
    yield put({ type: 'AUTOSAVE_CHECK_ERROR', message: JSON.stringify(e) })
  }
}

export function* autosaveApply(action) {
  const { data } = action
  let key = false
  try {
    if (data.mode === 'add') {
      key = `autosave:${data.userid}:${data.modelname}:${data.mode}`
    } else {
      key = `autosave:${data.userid}:${data.modelname}:${data.mode}:${data.modelid}`
    }
    const values = JSON.parse(window.localStorage.getItem(key))
    data.setInitVals(values)
    // Object.keys(values).forEach(k => data.form.setFieldTouched(k, true, false))
    yield put({ type: 'AUTOSAVE_APPLY_SUCCESS', data })
  } catch (e) {
    yield put({ type: 'AUTOSAVE_APPLY_ERROR', message: JSON.stringify(e) })
  }
}

export function* autosaveDiscard(action) {
  const { data } = action
  let key = false
  try {
    if (data.mode === 'add') {
      key = `autosave:${data.userid}:${data.modelname}:${data.mode}`
    } else { // Edit mode
      key = `autosave:${data.userid}:${data.modelname}:${data.mode}:${data.modelid}`
    }
    window.localStorage.removeItem(key)
    yield put({ type: 'AUTOSAVE_DISCARD_SUCCESS', data })
  } catch (e) {
    yield put({ type: 'AUTOSAVE_DISCARD_ERROR', message: JSON.stringify(e) })
  }
}

export function* fetchListingHistory(action) {
  const { data } = action
  try {
    const t = yield select(stored.TOKEN)
    const uri = `${apigw}/statistics/api/v1/${data.modelname}/history/${data.id ? `${data.id}/` : ''}`
    const qs = new QueryBuilder(uri)
    if (data.params) {
      qs.setParam(data.params)
    }
    const r = yield call(request, qs.url(), { method: 'GET', headers: { Authorization: `Bearer ${t}` }, signal: data.signal })
    if (!r.ok) { throw r }
    const s = JSON.parse(r.body)
    yield put({ type: 'FETCH_STATISTICS_HISTORY_SUCCESS', data: s })
    data.resolve(s)
  } catch (e) {
    yield put({ type: 'FETCH_STATISTICS_HISTORY_ERROR', message: JSON.stringify(e) })
    data.reject(e)
  }
}

export function* fetchAgentStatistics(action) {
  const { data } = action
  try {
    const t = yield select(stored.TOKEN)
    const { action: endpoint, signal } = data
    const { ...params } = data.params
    let uri = `${apigw}/statistics/api/v1/agents/`
    if (endpoint === 'totals') {
      uri = `${uri}totals/${params.start_date}/${params.end_date}/`
    } else {
      uri = `${uri}${endpoint}/`
    }
    const qs = new QueryBuilder(uri)
    if (params) {
      qs.setParam(params)
    }
    const r = yield call(request, qs.url(), { method: 'GET', headers: { Authorization: `Bearer ${t}` }, signal })
    if (!r.ok) { throw r }
    const s = JSON.parse(r.body)
    yield put({ type: 'FETCH_AGENT_STATISTICS_SUCCESS', data: s })
    data.resolve(s)
  } catch (e) {
    yield put({ type: 'FETCH_AGENT_STATISTICS_ERROR', message: JSON.stringify(e) })
    data.reject(e)
  }
}

export function* fetchBranchStatistics(action) {
  const { data } = action
  try {
    const t = yield select(stored.TOKEN)
    const { action: endpoint, params, signal } = data
    let uri = `${apigw}/statistics/api/v1/branches/`
    if (endpoint === 'totals') {
      uri = `${uri}totals/${params.start_date}/${params.end_date}/`
    } else {
      uri = `${uri}${endpoint}/`
    }
    const qs = new QueryBuilder(uri)
    if (params) {
      qs.setParam(params)
    }
    const r = yield call(request, qs.url(false, true), { method: 'GET', headers: { Authorization: `Bearer ${t}` }, signal })
    if (!r.ok) { throw r }
    const s = JSON.parse(r.body)
    yield put({ type: 'FETCH_BRANCH_STATISTICS_SUCCESS', data: s })
    data.resolve(s)
  } catch (e) {
    yield put({ type: 'FETCH_BRANCH_STATISTICS_ERROR', message: JSON.stringify(e) })
    data.reject(e)
  }
}

export function* fetchListingAnalysis(action) {
  const { data } = action
  try {
    const t = yield select(stored.TOKEN)
    const { params, signal } = data
    const uri = `${apigw}/listings/api/v1/statistics/analysis/`
    const qs = new QueryBuilder(uri)
    if (params) {
      qs.setParam(params)
    }
    const r = yield call(request, qs.url(), { method: 'GET', headers: { Authorization: `Bearer ${t}` }, signal })
    if (!r.ok) { throw r }
    const s = JSON.parse(r.body)
    yield put({ type: 'FETCH_LISTING_ANALYSIS_SUCCESS', data: s })
    data.resolve(s)
  } catch (e) {
    yield put({ type: 'FETCH_LISTING_ANALYSIS_ERROR', message: JSON.stringify(e) })
    data.reject(e)
  }
}

export function* fetchTemplateConfig(action) {
  const { data } = action
  try {
    const t = yield select(stored.TOKEN)
    const { params } = data
    let uri = `${apigw}/reports/api/v1/templates/parse/`
    if (data.endpoint) {
      uri = `${apigw}/${data.endpoint}`
    }
    const qs = new QueryBuilder(uri)
    if (params) {
      qs.setParam(params)
    }
    const r = yield call(request, qs.url(), { method: 'GET', headers: { Authorization: `Bearer ${t}` } })
    if (!r.ok) { throw r }
    const s = JSON.parse(r.body)
    yield put({ type: 'FETCH_TEMPLATE_CONFIG_SUCCESS', data: s })
    data.resolve(s)
  } catch (e) {
    yield put({ type: 'FETCH_TEMPLATE_CONFIG_ERROR', message: JSON.stringify(e) })
    data.reject(e)
  }
}

export function* fetchPageTemplateConfig(action) {
  const { data } = action
  try {
    const t = yield select(stored.TOKEN)
    const { params } = data
    const uri = `${apigw}/content/api/v1/pagetemplates/parse/`
    const qs = new QueryBuilder(uri)
    if (params) {
      qs.setParam(params)
    }
    const r = yield call(request, qs.url(), { method: 'GET', headers: { Authorization: `Bearer ${t}` } })
    if (!r.ok) { throw r }
    const s = JSON.parse(r.body)
    yield put({ type: 'FETCH_PAGE_TEMPLATE_CONFIG_SUCCESS', data: s })
    data.resolve(s)
  } catch (e) {
    yield put({ type: 'FETCH_PAGE_TEMPLATE_CONFIG_ERROR', message: JSON.stringify(e) })
    data.reject(e)
  }
}

export function* updateTemplatePreview(action) {
  const { data } = action
  try {
    const t = yield select(stored.TOKEN)
    // eslint-disable-next-line no-unused-vars
    const { endpoint, model, resolve, reject, ...params } = data
    const uri = `${apigw}${endpoint.write}`
    const qs = new QueryBuilder(uri)
    const r = yield call(request, qs.url(), { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(params) })
    if (!r.ok) { throw r }
    const s = r.body
    yield put({ type: 'UPDATE_TEMPLATE_PREVIEW_SUCCESS', data: s })
    resolve(s)
  } catch (e) {
    yield put({ type: 'UPDATE_TEMPLATE_PREVIEW_ERROR', message: JSON.stringify(e) })
    data.reject(e)
  }
}

export function* updateTemplateConfig(action) {
  const { data } = action
  try {
    const t = yield select(stored.TOKEN)
    // eslint-disable-next-line no-unused-vars
    const { endpoint, model, resolve, reject, ...params } = data
    const uri = `${apigw}${endpoint.write}`
    const qs = new QueryBuilder(uri)
    const r = yield call(request, qs.url(), { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(params) })
    if (!r.ok) { throw r }
    const s = r.body
    yield put({ type: 'UPDATE_TEMPLATE_PREVIEW_SUCCESS', data: s })
    resolve(s)
  } catch (e) {
    yield put({ type: 'UPDATE_TEMPLATE_PREVIEW_ERROR', message: JSON.stringify(e) })
    data.reject(e)
  }
}

export function* fetchManyAccumulator(action) {
  // eslint-disable-next-line no-unused-vars
  const { signal, ...a } = action.data.values
  const h = hash(a)
  const running = tasks.get(h)
  if (running) {
    const r = yield join(running)
    if (r) {
      if (r.put) { yield put(r.put) }
      if (r.resolve && action.data.resolve) { action.data.resolve(r.resolve) }
      if (r.error && action.data.reject) { action.data.reject(r.error) }
    }
  } else {
    action.hash = h
    const task = yield fork(fetchMany, action)
    tasks.set(h, task)
  }
}

export function* fetchP24Urls(action) {
  try {
    const t = yield select(stored.TOKEN)
    // eslint-disable-next-line no-unused-vars
    const { modelname, endpoint, ...data } = action.data
    const uri = `${apigw}${endpoint.read}`
    const qs = new QueryBuilder(uri)
    const r = yield call(request, qs.url(), { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(data) })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    action.resolve(body)
    yield put({
      type: 'FETCH_P24_URLS_SUCCESS',
      token: r.token,
      body
    })
  } catch (e) {
    yield put({ type: 'FETCH_P24_URLS_ERROR', message: JSON.stringify(e) })
    action.reject(e)
  }
}

export function* fetchCleverCompose(action) {
  try {
    const t = yield select(stored.TOKEN)
    // eslint-disable-next-line no-unused-vars
    const { modelname, endpoint, ...data } = action.data
    const config = yield select(stored.CONFIG, modelname)
    data.listing_model = config.get('servicename')
    const uri = `${apigw}/listings/api/v1/clever-compose/`
    const qs = new QueryBuilder(uri)
    const r = yield call(request, qs.url(), { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(data) })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    action.resolve(body)
    yield put({
      type: 'FETCH_CLEVER_COMPOSE_SUCCESS',
      token: r.token,
      body
    })
  } catch (e) {
    console.error(e)
    yield put({ type: 'FETCH_CLEVER_COMPOSE_ERROR', message: JSON.stringify(e) })
    action.reject(e)
  }
}

export function* fetchVacancyPro(action) {
  try {
    const t = yield select(stored.TOKEN)
    // eslint-disable-next-line no-unused-vars
    const { suburb, ...data } = action.data

    if (suburb) {
      const search_location = yield call(() => db.suburbs.get(suburb))
      data.suburb_id = search_location.property_24_id
    }
    const uri = `${apigw}/listings/api/v1/vacancy-pro/`
    const qs = new QueryBuilder(uri)
    const r = yield call(request, qs.url(), { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(data) })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)

    if (Array.isArray(body)) {
      for (const record of body) {
        for (const f in record) {
          if (record[f]) {
            if ([ 'suburb_id' ].includes(f)) {
              const loc = record[f]
              const location = yield call(() => db.suburbs.filter(s => s.property_24_id === loc).first())
              if (location) {
                record.suburb_id = location.id
                record.meta = { suburb_id: location }
              }
            }
          }
        }
      }
    }
    action.resolve(body)
    yield put({
      type: 'FETCH_VACANCY_PRO_SUCCESS',
      token: r.token,
      body
    })
    const delta = {}
    delta.vacancypro = {}
    body.forEach(row => { delta.vacancypro[row.id] = row })
    yield put({
      type: 'FETCH_MANY_SUCCESS',
      modellist: true,
      token: r.token,
      delta
    })
  } catch (e) {
    console.error(e)
    yield put({ type: 'FETCH_VACANCY_PRO_ERROR', message: JSON.stringify(e) })
    action.reject(e)
  }
}

export function* fetchListingCounts(action) {
  try {
    const t = yield select(stored.TOKEN)
    // eslint-disable-next-line no-unused-vars
    const { values: { params }, resolve, reject, ...data } = action.data

    const uri = `${apigw}/listings/api/v1/listings/status-counts/`
    const qs = new QueryBuilder(uri)
    qs.setParam(params)
    const r = yield call(request, qs.url(), { method: 'GET', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' } })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)

    resolve(body)
    yield put({
      type: 'FETCH_LISTING_COUNTS_SUCCESS',
      token: r.token,
      body
    })
  } catch (e) {
    console.error(e)
    yield put({ type: 'FETCH_LISTING_COUNTS_ERROR', message: JSON.stringify(e) })
    action.data.reject(e)
  }
}

export function* calculateLeaseValue(action) {
  const { form } = action.data
  try {
    if (form.values.lease_period === 'Negotiable') {
      throw new PDMSError('Cannot calculate negotiable value')
    }
    if (form.values.rental_commission && form.values.price && form.values.lease_period) {
      // eslint-disable-next-line no-unused-vars
      const [ int, period, ...rest ] = form.values.lease_period.split(' ')
      const lease_period = period === 'year' ? parseInt(int, 10) * 12 : parseInt(int, 10)
      const leaseValue = Math.round((form.values.rental_commission / 100) * form.values.price * lease_period)
      form.setFieldValue('lease_value', leaseValue)
    }
    yield put({ type: 'CALCULATE_LEASE_VALUE_SUCCESS' })
  } catch (e) {
    console.error(e)
    yield put({ type: 'CALCULATE_LEASE_VALUE_ERROR', message: JSON.stringify(e) })
  }
}

export function* fetchFieldDefault(action) {
  const { field, template, modelname, id } = action.data
  try {
    if (!field) {
      throw new PDMSError('No field provided')
    }

    const t = yield select(stored.TOKEN)
    const uri = `${apigw}/reports/api/v1/templates/fetch-field/${modelname}/${id}/`
    const r = yield call(request, uri, { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify({ field, template }) })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    action.resolve(body)

    yield put({ type: 'FETCH_FIELD_DEFAULT_SUCCESS' })
  } catch (e) {
    console.error(e)
    yield put({ type: 'FETCH_FIELD_DEFAULT_ERROR', message: JSON.stringify(e) })
    action.reject(e)
  }
}

export function* fetchVacancyProSuburbs(action) {
  try {
    const t = yield select(stored.TOKEN)
    // eslint-disable-next-line no-unused-vars
    const { suburb, ...data } = action.data

    if (suburb) {
      const search_location = yield call(() => db.suburbs.get(suburb))
      data.suburb_id = search_location.property_24_id
    }
    const uri = `${apigw}/locations/api/v1/locations/vacancy-pro-populated-suburbs/`
    const qs = new QueryBuilder(uri)
    const r = yield call(request, qs.url(), { method: 'GET', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' } })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    action.resolve(body)
    yield put({
      type: 'FETCH_VACANCY_PRO_SUBURBS_SUCCESS',
      token: r.token,
      body
    })
  } catch (e) {
    console.error(e)
    yield put({ type: 'FETCH_VACANCY_PRO_SUBURBS_ERROR', message: JSON.stringify(e) })
    action.reject(e)
  }
}

export function* fetchDealFinancialStructure(action) {
  const { id } = action.data
  try {
    if (!id) {
      throw new PDMSError('No id provided')
    }

    const t = yield select(stored.TOKEN)
    const uri = `${apigw}/listings/api/v1/deals/${id}/financial-structure/`
    const r = yield call(request, uri, { method: 'GET', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' } })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    action.resolve(body)

    yield put({ type: 'FETCH_DEAL_FINANCIAL_STRUCTURE_SUCCESS' })
  } catch (e) {
    console.error(e)
    yield put({ type: 'FETCH_DEAL_FINANCIAL_STRUCTURE_ERROR', message: JSON.stringify(e) })
    action.reject(e)
  }
}

export function* updateDealFinancialStructure(action) {
  const { id, ...rest } = action.data
  try {
    if (!id) {
      throw new PDMSError('No id provided')
    }

    const t = yield select(stored.TOKEN)
    const uri = `${apigw}/listings/api/v1/deals/${id}/financial-structure/`
    const r = yield call(request, uri, { method: 'PATCH', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(rest) })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    action.resolve(body)

    yield put({ type: 'FETCH_DEAL_FINANCIAL_STRUCTURE_SUCCESS' })
  } catch (e) {
    console.error(e)
    yield put({ type: 'FETCH_DEAL_FINANCIAL_STRUCTURE_ERROR', message: JSON.stringify(e) })
    action.reject(e)
  }
}

export function* sendNewsletterTest(action) {
  try {
    const t = yield select(stored.TOKEN)
    const { data } = action
    const { resolve, values } = data

    logEvent('EMAIL_NEWSLETTER_TEST', { body: { values, send: true } })
    const uri = `${apigw}/content/api/v1/newsletter-templates/preview/`
    const r = yield call(request, uri, { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(values) })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    logEvent('EMAIL_NEWSLETTER_TEST_SUCCESS')
    if (resolve) { resolve(body) }
    yield put({ type: 'NOTIFY', data: { title: 'Newsletter Test', body: 'Email sent successfully', type: 'success' } })
  } catch (e) {
    yield put({ type: 'EMAIL_NEWSLETTER_TEST_ERROR', message: JSON.stringify(e) })
    yield put({ type: 'NOTIFY', data: { title: 'Error', body: JSON.stringify(e), type: 'error' } })
    if (action.reject) { action.reject(e) }
  }
}

export function* validateSendgridDomains(action) {
  try {
    const t = yield select(stored.TOKEN)
    const { data } = action
    logEvent('VALIDATE_SENDGRID_DOMAINS', { body: { domain_id: data } })
    const uri = `${apigw}/config/api/v1/domains/validate-sendgrid/`
    const r = yield call(request, uri, { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify({ domain_id: data }) })
    if (!r.ok) { throw r }
    logEvent('VALIDATE_SENDGRID_DOMAINS_SUCCESS')
    yield put({ type: 'NOTIFY', data: { title: 'SendGrid validation', body: 'Validation in progress', type: 'success' } })
  } catch (e) {
    yield put({ type: 'VALIDATE_SENDGRID_DOMAINS_ERROR', message: JSON.stringify(e) })
    yield put({ type: 'NOTIFY', data: { title: 'Error', body: JSON.stringify(e), type: 'error' } })
    if (action.reject) { action.reject(e) }
  }
}

export function* updateMailRecipients(action) {
  try {
    const t = yield select(stored.TOKEN)
    const { data } = action
    const { resolve, values } = data

    logEvent('UPDATE_MAIL_RECIPIENTS', { body: { values } })
    const uri = `${apigw}/mail/api/v1/marketing-emails/update-recipients/`
    const r = yield call(request, uri, { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(values) })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    logEvent('UPDATE_MAIL_RECIPIENTS_SUCCESS')
    yield put({ type: 'NOTIFY', data: { title: 'Recipients', body: 'List generated successfully', type: 'success' } })
    if (resolve) { resolve(body) }
  } catch (e) {
    yield put({ type: 'UPDATE_MAIL_RECIPIENTS_ERROR', message: JSON.stringify(e) })
    yield put({ type: 'NOTIFY', data: { title: 'Error', body: JSON.stringify(e), type: 'error' } })
    if (action.data.reject) { action.data.reject(e) }
  }
}

export function* sendMarketingEmail(action) {
  try {
    const t = yield select(stored.TOKEN)
    const { data, form } = action
    const { args } = data
    const { values } = form
    if (args.test) {
      values.test = true
    }

    logEvent('SEND_MARKETING_EMAIL', { body: { values, send: true } })
    const uri = `${apigw}/mail/api/v1/marketing-email/send/`
    const r = yield call(request, uri, { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(values) })
    if (!r.ok) { throw r }
    logEvent('SEND_MARKETING_EMAIL_SUCCESS')
    yield put({ type: 'NOTIFY', data: { title: 'Email Test', body: 'Test email sent successfully', type: 'success' } })
  } catch (e) {
    yield put({ type: 'SEND_MARKETING_EMAIL_ERROR', message: JSON.stringify(e) })
    yield put({ type: 'NOTIFY', data: { title: 'Error', body: JSON.stringify(e), type: 'error' } })
  }
}

export function* loginCreditCheck(action) {
  try {
    const t = yield select(stored.TOKEN)
    const { data } = action
    const { resolve, values } = data

    logEvent('LOGIN_CREDIT_CHECK', { body: { values } })
    const uri = `${apigw}/contacts/api/v1/applications/credit-check-login/`
    const r = yield call(request, uri, { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(values) })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    logEvent('LOGIN_CREDIT_CHECK_SUCCESS')
    if (resolve) { resolve(body) }
  } catch (e) {
    yield put({ type: 'LOGIN_CREDIT_CHECK_ERROR', message: JSON.stringify(e) })
    if (action.data.reject) { action.data.reject(e) }
  }
}

export function* fetchCreditCheckModules(action) {
  try {
    const t = yield select(stored.TOKEN)
    const { data } = action
    const { resolve, values } = data

    logEvent('FETCH_CREDIT_CHECK', { body: { values } })
    const uri = `${apigw}/contacts/api/v1/applications/credit-check-modules/`
    const r = yield call(request, uri, { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(values) })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    logEvent('FETCH_CREDIT_CHECK_SUCCESS')
    if (resolve) { resolve(body) }
  } catch (e) {
    yield put({ type: 'FETCH_CREDIT_CHECK_ERROR', message: JSON.stringify(e) })
    if (action.data.reject) { action.data.reject(e) }
  }
}

export function* fetchCreditCheck(action) {
  try {
    const t = yield select(stored.TOKEN)
    const { data } = action
    const { resolve, values } = data

    logEvent('FETCH_CREDIT_CHECK', { body: { values } })
    const uri = `${apigw}/contacts/api/v1/applications/credit-check/`
    const r = yield call(request, uri, { method: 'POST', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' }, body: JSON.stringify(values) })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    logEvent('FETCH_CREDIT_CHECK_SUCCESS')
    if (resolve) { resolve(body) }
  } catch (e) {
    yield put({ type: 'FETCH_CREDIT_CHECK_ERROR', message: JSON.stringify(e) })
    yield put({ type: 'NOTIFY', data: { title: 'Error', body: JSON.stringify(e.raw), type: 'error' } })
    if (action.data.reject) { action.data.reject(e) }
  }
}

export function* resendApplication(action) {
  try {
    const t = yield select(stored.TOKEN)
    const { data } = action
    const { resolve, selected } = data

    logEvent('RESEND_APPLICATION', { body: { data } })
    const uri = `${apigw}/contacts/api/v1/applications/${selected[0]}/resend/`
    const r = yield call(request, uri, { method: 'GET', headers: { Authorization: `Bearer ${t}`, 'Content-Type': 'application/json; charset=utf8' } })
    if (!r.ok) { throw r }
    const body = JSON.parse(r.body)
    logEvent('RESEND_APPLICATION_SUCCESS')
    yield put({ type: 'NOTIFY', data: { title: 'Success', body: body.detail, type: 'success' } })
    if (resolve) { resolve(body) }
  } catch (e) {
    yield put({ type: 'RESEND_APPLICATION_ERROR', message: JSON.stringify(e) })
    yield put({ type: 'NOTIFY', data: { title: 'Error', body: JSON.stringify(e), type: 'error' } })
    if (action.reject) { action.reject(e) }
  }
}

// eslint-disable-next-line require-yield
export function* copyTo(action) {
  const from = getIn(action.form.values, [ action.args.from ])
  action.setInitVals({ ...action.form.values, [action.args.to]: from })
  action.form.setFieldTouched(action.args.to, true)
  yield put({ type: `${action.type}_SUCCESS` })
}

export function* copyToDisplayOnBranches(action) {
  const { form } = action.data
  try {
    if (!form.values.id) {
      let selected_branches = getIn(form.values, [ 'branches' ])
      if (selected_branches && selected_branches.length !== 1) {
        selected_branches = []
      }
      form.setFieldValue('display_on_branches', selected_branches).then(() => {
        form.setFieldTouched('display_on_branches', false, false)
      })
      yield put({ type: 'COPY_TO_DISPLAY_ON_BRANCHES_SUCCESS' })
    }
  } catch (e) {
    yield put({ type: 'COPY_TO_DISPLAY_ON_BRANCHES_ERROR', message: JSON.stringify(e) })
  }
}

export function* watchAll() {
  yield takeLatest('SEND_RESET', sendReset)
  yield takeLatest('SEND_ACTIVATION', sendActivation)
  yield takeLatest('ACTIVATE_USER', activateUser)
  yield takeLatest('DO_RESET', doReset)
  yield takeLatest('DO_LOGIN', doLogin)
  yield takeLatest('SELECT_AGENT', selectAgent)
  yield takeLatest('UNSELECT_AGENT', unselectAgent)
  yield takeLatest('RENEW_TOKEN', renewToken)
  yield takeLatest('RE_TOKE', reToke)
  yield takeLatest('APPLY_RETOKE', applyRetoke)
  yield takeLatest('LOAD_TOKE', loadToke)
  yield takeEvery('UPLOAD_FILE', uploadFile)
  yield takeEvery('CHANGE_CAPTION', changeCaption)
  yield takeEvery('CHANGE_DATE', changeDate)
  yield takeEvery('ROTATE_IMAGE', rotateImage)
  yield takeEvery('FETCH_ONE', fetchOne)
  yield takeEvery('FETCH_ACTIVITY', fetchActivity)
  yield takeEvery('FETCH_FEED_LOGS', fetchFeedLogs)
  yield takeEvery('FETCH_MANY', fetchManyAccumulator)
  yield takeLatest('SELECT_ALL', selectAll)
  yield takeEvery('SELECT_ONE', selectOne)
  yield takeLatest('BULK_EDIT_MODEL', bulkeditModel)
  yield takeLatest('UPDATE_MODEL', updateModel)
  yield takeLatest('UPDATE_HOME_PAGE', updateHomePage)
  yield takeLatest('CREATE_MODEL', createModel)
  yield takeLatest('DELETE_MODEL', deleteModel)
  yield takeLatest('CHECK_DELETE_MODEL', checkDelete)
  yield takeLatest('UPDATE_PREFERENCE', updatePreference)
  yield takeLatest('CREATE_PREFERENCE', createPreference)
  yield takeLatest('DELETE_PREFERENCE', deletePreference)
  yield takeLatest('ADD_TABLE_CONFIG_FIELD', addTableField)
  yield takeLatest('REMOVE_TABLE_CONFIG_FIELD', removeTableField)
  yield takeLatest('EXPORT_DATA', exportData)
  yield takeLatest('GET_LEADS_ANALYSIS', getLeadsAnalysisData)
  yield takeEvery('GET_LEADS_BREAKDOWN', getLeadsBreakdown)
  yield takeEvery('FETCH_MATCHES', fetchMatches)
  yield takeLatest('FETCH_HIGHLIGHTS', fetchHighlights)
  yield takeLatest('HIGHLIGHT_MATCH', highlightMatch)
  yield takeLatest('UNHIGHLIGHT_MATCH', unhighlightMatch)
  // yield takeLatest('CREATE_PORTAL_CONFIG', createPortalConfig)
  yield takeEvery('CREATE_BRANCH_PORTAL_CONFIG', createBranchPortalConfig)
  yield takeEvery('UPDATE_BRANCH_PORTAL_CONFIG', updateBranchPortalConfig)
  // yield takeLatest('DELETE_BRANCH_PORTAL_CONFIG', deleteBranchPortalConfig)
  yield takeLatest('TOGGLE_SITE_PORTAL', toggleSitePortal)
  yield takeLatest('CREATE_SITE_PORTAL', createSitePortal)
  yield takeLatest('SYNDICATE_PORTAL_ITEM', syndicatePortalItem)
  yield takeLatest('SYNDICATE_ITEMS', syndicateItems)
  yield takeEvery('FETCH_GLOBAL_PORTALS', fetchGlobalPortals)
  yield takeEvery('FETCH_PORTAL_LOGS', fetchPortalLogs)
  yield takeLatest('GENERATE_QR', generateQR)
  yield takeLatest('DISMISS_ALERT', dismissAlert)
  yield takeLatest('ALERT_AGENTPROPERTYLEAD', alertAgentPropertyLead)
  yield takeLatest('NOTIFY_REFERRALREQUEST', notifyReferralRequest)
  yield takeEvery('FETCH_PROFILE_MATCHES', fetchProfileMatches)
  yield takeEvery('EMAIL_PROFILE_MATCHES', emailProfileMatches)
  yield takeLatest('FETCH_LOCATIONS', fetchLocations)
  yield takeLatest('FETCH_SERVICED_ATTRIBUTES', fetchServicedAttributes)
  yield takeLatest('FETCH_REFERRAL_CONTACT_MATCH', fetchReferralContactMatch)
  yield takeLatest('FETCH_DEEDS', fetchDeeds)
  yield takeLatest('CHECK_VERSION', checkVersion)
  yield takeLatest('AUTOSAVE_FORM', autosaveForm)
  yield takeLatest('AUTOSAVE_CHECK', autosaveCheck)
  yield takeLatest('AUTOSAVE_APPLY', autosaveApply)
  yield takeLatest('AUTOSAVE_DISCARD', autosaveDiscard)
  yield takeEvery('DOWNLOAD_IMAGES', downloadImages)
  yield takeEvery('FETCH_LISTING_HISTORY', fetchListingHistory)
  yield takeEvery('FETCH_AGENT_STATISTICS', fetchAgentStatistics)
  yield takeEvery('FETCH_BRANCH_STATISTICS', fetchBranchStatistics)
  yield takeLatest('FETCH_LISTING_ANALYSIS', fetchListingAnalysis)
  yield takeLatest('GET_ALERTS_BREAKDOWN', getAlertsBreakdown)
  yield takeLatest('CREATE_LEAD_INTERACTION', createLeadInteraction)
  yield takeLatest('UPDATE_LEAD_INTERACTION', updateLeadInteraction)
  yield takeEvery('FETCH_VIEWING_FEEDBACK', fetchViewingFeedback)
  yield takeEvery('FETCH_TEMPLATE_CONFIG', fetchTemplateConfig)
  yield takeEvery('FETCH_PAGE_TEMPLATE_CONFIG', fetchPageTemplateConfig)
  yield takeLatest('UPDATE_TEMPLATE_PREVIEW', updateTemplatePreview)
  yield takeEvery('EMAIL_TEMPLATE', emailTemplate)
  yield takeEvery('RECALCULATE_COMMISSION', recalculateCommission)
  yield takeEvery('CALCULATE_LEASE_VALUE', calculateLeaseValue)
  yield takeEvery('FETCH_P24_URLS', fetchP24Urls)
  yield takeEvery('FETCH_CLEVER_COMPOSE', fetchCleverCompose)
  yield takeEvery('FETCH_VACANCY_PRO', fetchVacancyPro)
  yield takeLatest('FETCH_LISTING_COUNTS', fetchListingCounts)
  yield takeEvery('FETCH_FIELD_DEFAULT', fetchFieldDefault)
  yield takeLatest('FETCH_DEAL_FINANCIAL_STRUCTURE', fetchDealFinancialStructure)
  yield takeLatest('UPDATE_DEAL_FINANCIAL_STRUCTURE', updateDealFinancialStructure)
  yield takeEvery('FETCH_VACANCY_PRO_SUBURBS', fetchVacancyProSuburbs)
  yield takeLatest('SEND_NEWSLETTER_TEST', sendNewsletterTest)
  yield takeLatest('SEND_MARKETING_EMAIL', sendMarketingEmail)
  yield takeLatest('UPDATE_MAIL_RECIPIENTS', updateMailRecipients)
  yield takeLatest('VALIDATE_SENDGRID_DOMAINS', validateSendgridDomains)
  yield takeLatest('MERGE_MODEL', mergeModel)
  yield takeLatest('LOGIN_CREDIT_CHECK', loginCreditCheck)
  yield takeLatest('FETCH_CREDIT_CHECK', fetchCreditCheck)
  yield takeLatest('FETCH_CREDIT_CHECK_MODULES', fetchCreditCheckModules)
  yield takeLatest('RESEND_APPLICATION', resendApplication)
  yield takeLatest('copyTo', copyTo)
  yield takeEvery('COPY_TO_DISPLAY_ON_BRANCHES', copyToDisplayOnBranches)
}

export function* rootSaga() {
  yield all([
    watchAll()
  ])
}

export default rootSaga
