/* eslint-disable new-cap */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import { Map } from 'immutable'

import { hasPermission, parseURL, hasAddons } from '../../utils'

import ModelReport from '../../components/common/ModelReport'
import { CACHE, UI, MINUSER, MODELS, CONFIG, ADDONS } from '../../selectors'


class ReportContainer extends React.Component {
  componentDidMount() {
    if (this.props.config
      && this.props.cache.settings
      && Object.keys(this.props.cache.settings).length && !this.hasViewPermission()
    ) { // If we're not allowed to be here, redirect to designated redirect
      const redirect = parseURL(this.props.routeConfig.report.redirect)
      this.props.actions.registerRedirect(redirect)
    }
  }

  componentDidUpdate() {
    if (this.props.config
      && this.props.cache.settings
      && Object.keys(this.props.cache.settings).length && !this.hasViewPermission()
    ) { // If we're not allowed to be here, redirect to designated redirect
      const redirect = parseURL(this.props.routeConfig.report.redirect)
      this.props.actions.registerRedirect(redirect)
    }
  }

  renderRelated(reportField, modelname, modelField, relatedFieldName) {
    // Renders related field data from a foreign model by id
    let data = false
    const newdata = []
    const field = this.props.config.fields.find(f => f.name === modelField)
    if (this.props.cache[modelname]) {
      if (Array.isArray(this.state.filters[reportField])) {
        this.state.filters[reportField].forEach(id => {
          if (this.props.cache[modelname][id]) {
            const cached_data = this.props.cache[modelname][id]
            if (Array.isArray(relatedFieldName) && cached_data) {
              const oneof = relatedFieldName.map(name => {
                if (cached_data[name]) { return cached_data[name] }
                return false
              }).filter(name => name)
              newdata.push(oneof.join(field.labelseparator || ' '))
              if (newdata.length) { data = newdata }
            } else {
              newdata.push(this.props.cache[modelname][id][relatedFieldName])
              if (newdata.length) { data = newdata.join(', ') }
            }
          }
        })
      } else if (this.props.cache[modelname][this.state.filters[reportField]]) {
        if (Array.isArray(relatedFieldName)) {
          const id = this.props.cache[modelname][this.state.filters[reportField]]
          if (id) {
            relatedFieldName.forEach(name => {
              if (id[name]) {
                newdata.push(id[name])
              }
            })
            if (newdata.length) { data = newdata }
          }
        } else {
          data = this.props.cache[modelname][this.state.filters[reportField]][relatedFieldName]
        }
      }
    } else if (this.state[modelname]) {
      if (Array.isArray(this.state.filters[reportField])) {
        this.state.filters[reportField].forEach(id => {
          if (this.state[modelname][id]) {
            const stated_data = this.state[modelname][id]
            if (Array.isArray(relatedFieldName) && stated_data) {
              const oneof = relatedFieldName.map(name => {
                if (stated_data[name]) { return stated_data[name] }
                return false
              }).filter(name => name)
              newdata.push(oneof.join(modelField.labelseparator || ' '))
              if (newdata.length) { data = newdata }
            } else {
              newdata.push(this.state[modelname][id][relatedFieldName])
              if (newdata.length) { data = newdata.join(', ') }
            }
          }
        })
      } else if (this.state[modelname][this.state.filters[reportField]]) {
        if (Array.isArray(relatedFieldName)) {
          const id = this.state[modelname][this.state.filters[reportField]]
          if (id) {
            relatedFieldName.forEach(name => {
              if (id[name]) {
                newdata.push(id[name])
              }
            })
            if (newdata.length) { data = newdata }
          }
        } else {
          data = this.state[modelname][this.state.filters[reportField]][relatedFieldName]
        }
      }
    }
    return data
  }

  hasViewPermission() {
    const { user, config, addons } = this.props
    if (config.addons) { return hasAddons(config.addons, addons) } // Entire module is disabled
    const requiredPermissions = this.props.routeConfig.report.permissions
    if (this.props.user.permissions.includes('is_prop_data_user')) { return true }
    if (!requiredPermissions) { return true }
    if (hasPermission(requiredPermissions, user.permissions)) { return true } // Implicit permissions
    return false
  }

  render() {
    const p = { ...this.props }
    p.actions = {
      ...this.props.actions,
      renderRelated: this.renderRelated
    }
    return <ModelReport {...p} />
  }
}


function mapStateToProps(state, ownProps) {
  const { model: modelname, report } = ownProps.match.params
  const addons = ADDONS(state)
  const user = MINUSER(state)
  return {
    models: MODELS(state),
    report,
    config: CONFIG(state, modelname),
    user: Map({
      permissions: user.get('permissions'),
      agent: user.get('agent')
    }),
    ui: UI(state),
    cache: CACHE(state),
    addons
  }
}

ReportContainer.propTypes = {
  report: PropTypes.object,
  config: PropTypes.object,
  user: PropTypes.object,
  ui: PropTypes.object,
  cache: PropTypes.object,
  actions: PropTypes.object,
  routeConfig: PropTypes.object,
  addons: PropTypes.array
}

export default connect(mapStateToProps, null)(withImmutablePropsToJS(ReportContainer))
