/* eslint-disable no-process-env */
import classNames from 'classnames'
import merge from 'deepmerge'
import { Formik, getIn, setIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import isEqual from 'react-fast-compare'
import { NavLink } from 'react-router-dom'

import { history } from '../../store'
import { hasAddons, hasPermission, isConditional, isInViewport, parseURL, slugify, sortBy, handleSubmitError, breakpoint } from '../../utils'
import validate from '../../validate'
import { Button } from '../ui/Button'
import CustomForm from './forms/CustomForm'
import FieldGroup from './forms/FieldGroup'
import ModelActions from './ModelActions'
import Step from './Step'
import Card from './Card'
import HorizontalTabs from './tabs/HorizontalTabs'
import Tab from './tabs/Tab'


class ModelEdit extends React.PureComponent {
  constructor(props) {
    super(props)
    this.timers = {}
    this.state = {
      ...props.state,
      required: false,
      quality: false,
      webedit: [ 'residential', 'commercial', 'holiday' ].includes(props.config.modelname) ? getIn(props.cache.settings, `${props.user.agent.site.id}.default_listing_webedit`, false) : false,
      collapsed: false,
      collapsable: false,
      redirect: false,
      initvals: false,
      inittouched: {},
      url: false,
      scrollTop: null,
      selectedGroup: {},
      currentGroup: {},
      showActions: breakpoint.matches,
      showJump: breakpoint.matches,
      active_portals: Object.keys(props.portals).filter(pk => getIn(props, `portals.${pk}.active`)).map(pk => getIn(props, `portals.${pk}.meta.portal.slug`)).filter(slug => slug)
    }
    const conditional_fields = {}
    props.config.fields.filter(field => {
      if (Array.isArray(field.edit)) {
        return true
      }
      return false
    }).forEach(field => {
      for (const group of field.edit) {
        for (const conditions of group) {
          if (!conditional_fields[conditions.field]) {
            conditional_fields[conditions.field] = [ field.name ]
          }
          if (!conditional_fields[conditions.field].includes(field.name)) {
            conditional_fields[conditions.field].push(field.name)
          }
        }
      }
    })
    this.state.conditional_fields = conditional_fields
    this.isConditional = isConditional.bind(this)
    this.toggleWebEdit = this.toggleWebEdit.bind(this)
    this.toggleRequired = this.toggleRequired.bind(this)
    this.toggleQuality = this.toggleQuality.bind(this)
    this.toggleCollapsed = this.toggleCollapsed.bind(this)
    this.initModel = this.initModel.bind(this)
    this.hasEditPermission = this.hasEditPermission.bind(this)
    this.redirectSchema = this.redirectSchema.bind(this)
    this.removeImage = this.removeImage.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSubmitError = handleSubmitError.bind(this)
    this.assignTeamAgents = this.assignTeamAgents.bind(this)
    this.renderGroups = this.renderGroups.bind(this)
    this.renderTabs = this.renderTabs.bind(this)
    this.renderList = this.renderList.bind(this)
    this.setIgnoreFields = this.setIgnoreFields.bind(this)
    this.scrollTo = this.scrollTo.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.hashChanged = this.hashChanged.bind(this)
    this.hashLinkScroll = this.hashLinkScroll.bind(this)
    this.getCurrentElement = this.getCurrentElement.bind(this)
    this.toggleActions = this.toggleActions.bind(this)
    this.updateScroller = this.updateScroller.bind(this)
    this.cancelOtherScrolls = this.cancelOtherScrolls.bind(this)
    this.setInitVals = this.setInitVals.bind(this)
    this.assignListingFields = this.assignListingFields.bind(this)
    this.actions = {
      ...props.actions,
      setInitVals: this.setInitVals,
      assignTeamAgents: this.assignTeamAgents,
      assignListingFields: this.assignListingFields,
      toggleWebEdit: this.toggleWebEdit,
      toggleRequired: this.toggleRequired,
      toggleQuality: this.toggleQuality,
      toggleCollapsed: this.toggleCollapsed
    }
    this.ignored_fields = []
    this.AbortController = new AbortController()
    this._is_mounted = true
  }

  componentDidMount() {
    breakpoint.addEventListener('change', this.toggleActions)
    window.addEventListener('hashchange', this.hashChanged, false)
    // const { model, modelname, modelid } = this.props
    this.fetchModel()
    const tab_el = document.querySelector('.viewhead')
    if (tab_el) {
      const offset = tab_el.getBoundingClientRect().top + tab_el.getBoundingClientRect().height
      if (this.state.offset !== offset) {
        this.setState({ offset })
      }
    }
    this.hashLinkScroll()
    const options = {
      root: this.scroller,
      threshold: [ 0.0, 0.5, 1.0 ],
      rootMargin: '20px'
    }
    this.observer = new IntersectionObserver(() => {
      this.getCurrentElement()
    }, options)
    this.jumpobserver = new IntersectionObserver(() => {
      this.handleUpdate()
    }, options)
    if (document.querySelector('.cardtoggle-jump')) {
      this.jumpobserver.observe(document.querySelector('.cardtoggle-jump'))
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { modelid, cache, actions, match, routeConfig, model, config, user, portals } = this.props
    if (
      (!this.state.initvals && model) || // We got a model for the first time
      (this.state.url !== match.url && model) // Check to see if jump from one edit to another edit
    ) {
      this.setState({
        initvals: this.initModel(model),
        url: match.url
      })
    }
    if ((cache.branches && Object.keys(cache.branches).length !== Object.keys(prevProps.cache.branches).length)) {
      const form_values = this.form?.values ? this.form.values : {}
      this.setState({
        initvals: this.initModel({ ...model, ...form_values })
      })
    }
    if (prevProps.match.params.tab !== this.props.match.params.tab) {
      let newcollapsable = false
      const newtab = this.props.config.tabs.find(t => slugify(t) === this.props.match.params.tab)
      if (newtab) {
        const groups = Object.values(this.props.config.fieldgroups).filter(g => g.tab === newtab)
        if (groups && groups.some(g => g.card)) { newcollapsable = true }
      }
      const form_values = this.form?.values ? this.form.values : {}
      this.setState({ collapsable: newcollapsable, initvals: this.initModel({ ...model, ...form_values }) })
    }
    if (this.props.steps.new || this.props.steps.new !== false) {
      const n = this.props.steps.selected[this.props.steps.new]
      this.setState({ new: false })
      actions.registerRedirect(`/secure/${this.props.match.params.site}/${config.modelname}/${n}/edit`)
    }
    if (modelid && cache && user.agent && Object.keys(cache.settings).length) { // wait for data to load
      if (!this.hasEditPermission()) { // If we're not allowed to be here, redirect to designated redirect
        const redirect = parseURL(routeConfig.edit.redirect, {
          ...model,
          site: this.props.match.params.site,
          match: this.props.match
        })
        actions.registerRedirect(redirect)
      }
    }
    if (!isEqual(prevProps.portals, portals)) {
      const active_portals = Object.keys(portals)
        .filter(pk => portals[pk] && portals[pk].meta).map(pk => portals[pk].meta.portal.slug)
      this.setState({ active_portals })
    }
    const tab_el = document.querySelector('.viewhead')
    if (tab_el) {
      const offset = tab_el.getBoundingClientRect().top + tab_el.getBoundingClientRect().height
      if (this.state.offset !== offset) {
        this.setState({ offset })
      }
    }
    if (document.querySelector('.cardtoggle-jump')) {
      this.jumpobserver.unobserve(document.querySelector('.cardtoggle-jump'))
      this.jumpobserver.observe(document.querySelector('.cardtoggle-jump'))
    }
    if (this.state.webedit !== prevState.webedit) {
      const visibleGroups = Object.keys(config.fieldgroups).filter(group => document.querySelector(`#${slugify(group)}`))
      this.setState({ visibleGroups })
    }
  }

  componentWillUnmount() {
    breakpoint.removeEventListener('change', this.toggleActions)
    window.removeEventListener('hashchange', this.hashChanged, false)
    Object.keys(this.timers).forEach(timer => {
      clearTimeout(this.timers[timer])
    })
    this._is_mounted = false
    this.AbortController.abort()
    if (this.observer) {
      this.observer.disconnect()
    }
    if (this.jumpobserver) {
      this.jumpobserver.disconnect()
    }
  }

  initObserver(el) {
    this.observer.observe(el)
  }

  fetchModel() { // Request model if we dont already have it
    const { modelid, modelname, cache, actions, match, routeConfig, model } = this.props
    const signal = this.AbortController.signal
    new Promise((resolve, reject) => {
      actions.fetchOne(modelname, modelid, resolve, reject, true, signal)
    }).then(() => {
      if (model && this._is_mounted) {
        this.setState({
          url: match.url
        })
      }
      if (modelid && cache && Object.keys(cache.settings).length > 1 && !this.hasEditPermission()) { // If we're not allowed to be here, redirect to designated redirect
        const redirect = parseURL(routeConfig.edit.redirect, {
          ...model,
          site: this.props.match.params.site,
          match: this.props.match
        })
        actions.registerRedirect(redirect)
      }
    }).catch(e => {
      if (e.status !== 408) { console.error(e) }
      if ([ 'Not found.' ].includes(e)) {
        const redirect = parseURL(routeConfig.edit.redirect, {
          ...model,
          site: this.props.match.params.site,
          match: this.props.match
        })
        actions.registerRedirect(redirect)
      }
    })
  }

  assignListingFields(field, form) { // Populates deal fields when converting from listings
    setTimeout(() => { // wait for sagas to run
      const { listing_model, model_type } = form.values
      const listing = getIn(this.props.cache, `${listing_model}.${this.form.values[field.name]}`, getIn(this.props.cache, `${model_type}.${this.form.values[field.name]}`))
      if (!listing) { return }
      const mappings = [
        'property_type',
        'location',
        'farm_name',
        'street_number',
        'street_name',
        'unit_number',
        'complex_name',
        'floor_number',
        'building_name',
        'erf_number',
        'share_block_number',
        'sectional_scheme_name',
        'section_number',
        'section_plan_number',
        'mandate_type'
      ]
      let updates = {}
      let touched = {}
      mappings.forEach(listing_field => {
        if (getIn(listing, listing_field)) {
          updates = setIn(updates, listing_field, getIn(listing, listing_field))
          touched = setIn(touched, listing_field, !!getIn(listing, listing_field))
        }
      })
      this.setInitVals({ ...form.values, ...updates })
      form.setValues({ ...form.values, ...updates }).then(() => {
        form.setTouched({ ...form.touched, ...touched })
      })
    }, 150)
  }

  toggleActions(e) {
    if (e.matches && !this.state.showActions) {
      this.setState({ showActions: true })
    } else if (e.matches !== undefined && this.state.showActions) {
      this.setState({ showActions: false })
    }
  }

  setInitVals(values) {
    const { model } = this.props
    const initvals = this.initModel({ ...model, ...values })
    this.setState({
      initvals
    })
  }

  initModel(model) { // Initialise model data for formik
    let initvals = { }
    const { modelname, config, user, portals } = this.props
    Object.keys(model).forEach(k => {
      if (model[k] === null) {return} // Ignore null values
      if ([ 'id', 'on_show_events', 'holiday_seasons' ].includes(k)) {
        initvals[k] = model[k]
      } else {
        const fields = config.fields.filter(
          field => field.name === k ||
          (typeof field.name === 'string' && field.name.startsWith(`${k}.`) && !field.name.startsWith('portals.'))
        )
        fields.forEach(f => {
          if (f && this.isConditional(f, 'edit', false, { values: model, initialValues: model })) {
            if (![ undefined, null ].includes(f.defaultvalue) && ![ undefined, null ].includes(getIn(model, f.name))) {
              initvals[f.name] = f.defaultvalue
            }
            if (f.edit) { initvals[k] = model[k] } // Only add editable fields to the form
            if ((f.input === 'TextNotes' && f.type === 'tel') || f.input === 'Float') { // Ensure float-type fields are converted accordingly
              let places = 1
              if (f.step) {
                places = f.step.toString().split('.').pop().length
              }
              initvals[k] = ![
                undefined,
                null
              ].includes(model[k]) ? String(parseFloat(model[k]).toFixed(places)) : model[k]
              const note = f.notes_name ? f.notes_name : `${f.name}_notes`
              initvals[note] = model[note]
            }

            if (f.input === 'CheckNotes' || f.input === 'SelectNotes') {
              const note = f.notes_name ? f.notes_name : `${f.name}_notes`
              initvals[note] = model[note]
            }
            if (f.input === 'Map' && (model.map_x_position || model.map_y_position)) {
              initvals[f.name] = { lat: model.map_x_position, lng: model.map_y_position }
            }
            if (f.container) {
              if (model[f.container]) { // Contained fields using container name and field name
                if (f.edit && f.name in model[f.container]) {
                  if (f.name === 'images') { // Images only require ID
                    initvals[f.name] = model[f.container][f.name] ? (
                      model[f.container][f.name].filter(i => i).map(i => i.id)
                    ) : null
                  } else {
                    initvals[f.name] = getIn(model, `${f.container}.${f.name}`)
                  }
                }
              } else if (getIn(model, `${f.container}.${f.name}`)) { // Contained using dot notation ie. meta.agent_of_the_month
                initvals[f.name] = getIn(model, `${f.container}.${f.name}`)
              } else if (getIn(model, f.container)) { // Contained using dot notation ie. meta.agent_of_the_month
                initvals[f.name] = getIn(model, f.container)
              }
            }
            if (f.file) { initvals[f.name] = null}
            if (f.relatedfield && model[f.relatedfield] && model.meta && model.meta[f.modelname]) { // Contained fields
              let meta = model.meta[f.modelname]
              if (Array.isArray(meta)) { meta = meta.find(o => o.id === model[f.relatedfield]) }
              if (f.edit) { initvals[f.name] = meta[f.name] }
            }
          }
        })
        if (([ 'residential', 'commercial', 'holiday', 'projects' ]).includes(k) && model[k]) {
          initvals[k] = model[k]
          initvals.listing_model = k // Leads which have a listing
        }
        if (([ 'consent' ]).includes(k) && model.meta[k]) {
          initvals.legal_basis = model.meta[k].legal_basis
        }
      }
    })
    if (!model.listing_images || !model.listing_images.length) {
      initvals.display_on_website = false
    }
    if ([ 'residential', 'commercial', 'holiday', 'projects', 'articles' ].includes(modelname) && model.meta.portals) {
      initvals.portals = {}
      model.meta.portals.forEach(p => {
        if (!user.permissions.includes('is_prop_data_user')) {
          // We need the reference in the initial values to decide whether under-priv users
          // can toggle the portal off if there is no reference
          if (![ 'property24', 'private-property' ].includes(p.meta.portal.slug)) { delete p.reference }
          delete p.feed_status
          delete p.last_message
          delete p.on_portal
        }
        const pidx = Object.keys(portals).filter(pk => getIn(portals, `${pk}.portal`) && getIn(portals, `${pk}.meta`)).find(pk => portals[pk].portal === p.portal)
        if (pidx) {
          const { slug } = portals[pidx].meta.portal
          initvals.portals[slug] = { ...p }
        }
      })
    }
    if ([ 'branches', 'agents' ].includes(modelname) && model.meta.portals) {
      initvals.portals = []
      model.meta.portals.forEach(p => {
        if (!user.permissions.includes('is_prop_data_user')) {
          delete p.reference
          delete p.feed_status
          delete p.last_message
          delete p.on_portal
        }
        initvals.portals.push({ ...p })
      })
    }
    if ([ 'images', 'documents' ].includes(modelname)) {
      initvals.file = []
      initvals.file.push(model.id)
    }

    Object.keys(initvals).forEach(f => { // Remove fields which the user is not allowed to edit
      if (![ 'id', 'portals' ].includes(f)) {
        // eslint-disable-next-line no-confusing-arrow
        const fielddupes = config.fields.filter(fi => fi.name?.indexOf('.') === -1 ? fi.name === f : fi.name?.substring(0, fi.name?.indexOf('.')) === f)
        let keepval = false
        fielddupes.forEach(field => {
          if (hasPermission(field.permissions, user.permissions)) {
            keepval = true // Only one positive allow is required to keep the field value
          }
        })
        if (!keepval) { delete initvals[f] }
      }
    })
    if (model && modelname === 'branches' && model.review_providers && model.review_providers.length) {
      initvals.review_providers = model.meta.review_providers
    }
    Object.keys(initvals).filter(k => k.includes('.')).forEach(k => {
      initvals = setIn(initvals, k, getIn(initvals, k))
    })
    return initvals
  }

  hasEditPermission() {
    const { config, user, model, addons } = this.props
    if (config.addons) {return hasAddons(config.addons, addons) } // Entire module is disabled
    const agent_id = this.props.user.agent.id
    const requiredPermissions = this.props.routeConfig.edit.permissions
    if (user.permissions.includes('is_prop_data_user')) { return true }
    if (!requiredPermissions) { return true } // No permissions needed
    const hasEditOwnPermissions = requiredPermissions.filter(perm => perm.endsWith('_update_own'))
    const hasEditAllPermissions = requiredPermissions.filter(perm => perm.endsWith('_update'))
    if (hasPermission(hasEditAllPermissions, user.permissions)) {
      // branches are an exception here
      switch (config.modelname) {
        case 'branches':
          return user.permissions.includes('is_prop_data_user') || user.permissions.includes('apply_to_all_branches') || (user.agent.branches.includes(parseInt(this.props.modelid, 10)))
        default:
          return true
      }
    }
    if (model && hasPermission(hasEditOwnPermissions, user.permissions)) {
      switch (config.modelname) {
        case 'residential':
        case 'commercial':
        case 'projects':
        case 'holiday':
          if (![ model.agent, model.agent_2, model.agent_3, model.agent_4 ].every(agent => agent === agent_id)) {
            return false
          }
          return true
        case 'users':
          if (model.agent !== agent_id) { // Agent doesn't match
            return false
          }
          return true
        case 'leads':
          if (model.agent !== user.agent.id) { // Agent doesn't match
            const contact = getIn(model, 'meta.contact')
            if (hasPermission([ 'leads_contacts_associated_agents_update' ], this.props.user.permissions) && contact.associated_agents && contact.associated_agents.includes(agent_id)) { // User is allowed to view associated contacts
              return true
            } else if (contact && contact.introduction_agent !== agent_id) {
              return false
            }
          }
          return true
        case 'contacts':
          if (hasPermission([ 'contacts_associated_agents_update' ], this.props.user.permissions) && model.associated_agents && model.associated_agents.includes(agent_id)) { // User is allowed to view associated contacts
            return true
          } else if (model.introduction_agent && model.introduction_agent !== agent_id) {
            return false
          }
          return true
        case 'subscribers': {
          const contact = getIn(model, 'meta.contact')
          if (hasPermission([ 'mailing_list_contacts_associated_agents_update' ], this.props.user.permissions) && contact.associated_agents && contact.associated_agents.includes(agent_id)) { // User is allowed to view associated contacts
            return true
          } else if (contact && contact.introduction_agent !== agent_id) {
            return false
          }
          return true
        }
        case 'profiles': {
          const contact = getIn(model, 'meta.contact')
          if (hasPermission([ 'profiles_contacts_associated_agents_update' ], this.props.user.permissions) && contact.associated_agents && contact.associated_agents.includes(agent_id)) { // User is allowed to view associated contacts
            return true
          } else if (contact && contact.introduction_agent !== agent_id) {
            return false
          }
          return true
        }
        default:
          return true
      }
    }
    return false
  }

  redirectSchema(schema) { this.setState({ redirect: schema }) }

  toggleWebEdit(e) {
    e.preventDefault()
    e.target.blur()
    this.setState({ webedit: !this.state.webedit, quality: false, required: false })
  }

  toggleRequired(e) {
    e.preventDefault()
    this.setState({ required: !this.state.required, quality: false, webedit: false })
  }

  toggleQuality(e) {
    e.preventDefault()
    this.setState({ quality: !this.state.quality, required: false, webedit: false })
  }

  toggleCollapsed(e) {
    e.preventDefault()
    e.target.blur()
    this.setState({ collapsed: !this.state.collapsed })
  }

  removeImage() { // This is here because there are no side effects
  }

  assignTeamAgents(field, form) { // Populates agent fields when team is selected
    setTimeout(() => { // wait for sagas to run
      const team = getIn(this.props.cache, `teams.${this.form.values[field.name]}`)
      if (!team) {
        if (this.form.values[field.name]) {
          setTimeout(() => {
            this.assignTeamAgents(field, form)
          }, 350)
        }
        return
      }
      const agents = team.agents.filter(a => a !== team.team_leader) || []
      const updated_agents = {
        agent: team.team_leader,
        agent_2: getIn(agents, 0),
        agent_3: getIn(agents, 1),
        agent_4: getIn(agents, 2)
      }
      const touched = {
        agent: !!team.team_leader,
        agent_2: !!getIn(agents, 0),
        agent_3: !!getIn(agents, 1),
        agent_4: !!getIn(agents, 2)
      }
      form.setValues({ ...form.values, ...updated_agents }).then(() => {
        form.setTouched({ ...form.touched, ...touched })
      })
    }, 150)
  }

  handleSubmit(values, actions) {
    const valid = merge({}, values)
    let raw

    Object.keys(valid).forEach(k => { // Do some post processing
      if (Array.isArray(valid[k]) && !this.props.config.fields.find(f => f.name === k && f.multi) && !k.includes('review_providers')) {
        valid[k] = getIn(valid, `${k}.0`, null) // Arrays passed in will be mutated to objects if not configed as multi
      }

      let field = this.props.config.fields.find(f => f.name === k && f.group)
      if (!field) { field = this.props.config.fields.find(f => f.name === k) }
      if (field && field.parent) { // This is a contained value ie. branch deactivate and listings reassignment
        if (valid[field.parent]) {
          valid[field.parent][k] = valid[k]
        } else {
          valid[field.parent] = {}
          valid[field.parent][k] = valid[k]
        }
        delete valid[k]
      }

      if (field && field.delete) { // Used for deleting fields at save time ie. pending_user
        delete valid[k]
      }

      if (field && field.rename) { // Used for renaming fields at save time ie. user -> username
        valid[field.rename] = String(valid[k])
        delete valid[k]
      }

      if (field && field.input === 'ContactLookup' && field.multi) {
        valid[field.name] = getIn(valid, field.name, [])
        if (valid[field.name]) {
          valid[field.name] = valid[field.name].filter(f => f)
        }
      }

      // don't send empty array fields
      if (field && [ 'FieldArray', 'SizeBreakdowns', 'OnShowEvents', 'LandUseBreakdowns', 'TranslatableTextArea', 'SortableTexts' ].includes(field.input) && field.multi) {
        valid[field.name] = getIn(valid, field.name, [])
        if (valid[field.name]) {
          valid[field.name] = valid[field.name].filter(f => {
            if (f && (field.fields?.some(fe => f[fe.name]) || !field.fields)) {
              return true
            }
            return false
          })
        }
      }

      if (field && field.input === 'FieldList') {
        valid[field.name] = valid[field.name].filter(v => v)
      }

      if (k === 'portals' && ((valid.display_on_website && valid.feed_to_portals) || this.props.config.servicename === 'article')) { // Only set portals if display on site is on and feed to portals is enabled
        raw = valid[k] // Copy initial raw portals object
        valid[k] = [] // Create a new portals list based on cached config
        if (raw) { // Construct the portals payload from global portal data
          Object.keys(raw).forEach(pk => {
            const pidx = Object.keys(this.props.portals)
              .filter(pid => this.props.portals[pid] && this.props.portals[pid].meta)
              .find(pid => this.props.portals[pid].meta.portal.slug === pk) // Find the config for the portal by slug
            const pconf = { ...raw[pk] } // Initialise final portals object for update payload
            if (pidx && // Portal exists in site portals
              this.props.portals[pidx].active // Site portal is active
            ) {
              pconf[this.props.config.servicename] = valid.id // Set the ID for the modelname
              pconf.portal = this.props.portals[pidx].portal
              if (raw[pk].reference) { pconf.reference = raw[pk].reference } // PD users can set a ref manually
              if (raw[pk].expiration_date) { pconf.expiration_date = raw[pk].expiration_date }
              valid[k].push(pconf)
            }
          })
        }
      }
      // remove fields that the current user doesn't
      // have permission for from the payload
      if (this.ignored_fields && this.ignored_fields.includes(k)) {
        delete valid[k]
      }
    })

    if ([ 'images', 'documents' ].includes(this.props.modelname)) { delete valid.file }

    return new Promise((resolve, reject) => {
      clearTimeout(this.timers.create_autosave)
      this.props.actions.updateModel({ values: valid, autosaved: true, resolve, reject })
    }).then(r => {
      actions.setSubmitting(false)
      const newidx = this.props.steps.next
      const modelid = getIn(this.props, `steps.selected.${newidx}`)
      let redirect = null // Redirect to next selected model id edit
      if (modelid !== false && modelid !== undefined && modelid !== r) { // Redirect to next selected modelid
        redirect = `/secure/${this.props.match.params.site}/${this.props.config.modelname}/${modelid}/edit`
      } else if (this.state.redirect) { // This is updated by the ContextMenu component on submit which depends on modelactions save in model config
        redirect = parseURL(this.state.redirect, {
          ...valid,
          id: r,
          site: this.props.match.params.site,
          match: this.props.match
        })
      } else if (this.props.config.modelname === 'settings') {
        redirect = this.props.location.pathname
      } else {
        redirect = `/secure/${this.props.match.params.site}/${this.props.config.modelname}/${r}`
      }
      if (redirect && redirect !== this.props.location.pathname) { // Don't redirect if location is the same
        this.props.actions.registerRedirect(redirect)
      } else if (redirect === this.props.location.pathname) {
        window.scrollTo(0, 0)
      }
    }).catch(e => {
      handleSubmitError(e, actions, this.form)
    })
  }

  setIgnoreFields(field, unset = false) {
    if (this.ignored_fields && !this.ignored_fields.includes(field) && !unset) {
      this.ignored_fields = [ ...this.ignored_fields, field ]
    } else if (this.ignored_fields && this.ignored_fields.includes(field) && unset) {
      this.ignored_fields.filter(f => f !== field)
    }
  }

  renderTabs () {
    const { config, model, user, match, location, routeConfig, modelid } = this.props
    const tabs = {}
    Object.keys(config.fieldgroups).forEach(g => {
      if (!tabs[config.fieldgroups[g].tab]) {
        tabs[config.fieldgroups[g].tab] = []
      }
      tabs[config.fieldgroups[g].tab].push({ ...config.fieldgroups[g], name: g })
    })
    return (
      <HorizontalTabs
        config={routeConfig}
        location={location}
        match={match}
        model={model}
        modelid={modelid}
        defaultTab={slugify(config.tabs[0])}
        user={{ permissions: user.permissions, agent: user.agent }}
      >
        { config.tabs.map((tab, tabidx) => (
          <Tab key={`tab-${tabidx}`} tab={slugify(tab)} label={tab}>
            {tabs[tab].map((group, gidx) => {
              const fields = config.fields.filter(field =>
                field.group === group.name &&
                  field.edit &&
                  hasPermission(field.permissions, user.permissions)
              ).map(field => {
                if (field.group === 'Publish' && field.name.split('.').length > 2) {
                  const parts = field.name.split('.')
                  const meta = parts[0]
                  const pslug = parts[1]
                  if (meta === 'portals') {
                    if (!this.state.active_portals.includes(pslug)) { // If portal is not active in agency settings, hide input
                      field.edit = false
                    }
                  }
                }
                if (field.input === 'ContactLookup') {
                  field.model = model
                }
                return field
              })
              return fields.length ? (
                <FieldGroup
                  key={`fg-${tabidx}-${gidx}`}
                  gidx={gidx}
                  groupname={group.name}
                  card={group.card}
                  match={match}
                  background
                  classes={config.fieldgroups[group.name].classes}
                  fields={fields}
                  active_portals={this.state.active_portals}
                  required={this.state.required}
                  quality={this.state.quality}
                  webedit={this.state.webedit}
                  collapsed={this.state.collapsed}
                  setIgnoreFields={this.setIgnoreFields}
                  setInitVals={this.setInitVals}
                />
              ) : null
            })}
          </Tab>
        )
        ) }
      </HorizontalTabs>
    )
  }

  renderGroups() {
    const { config, model, match, modelid } = this.props
    return Object.keys(config.fieldgroups).map((group, gidx) => {
      const current = slugify(group) === this.state.currentGroup.id
      let classes = config.fieldgroups[group].classes || ''
      if (current) {
        classes = `${classes} active`
      }
      return (
        <FieldGroup
          key={`fg-${gidx}`}
          id={slugify(group)}
          groupname={group}
          gidx={gidx}
          match={match}
          model={model}
          modelid={modelid}
          modelname={this.props.config.modelname}
          columns={config.fieldgroups[group].columns}
          classes={classes}
          fields={config.fields}
          active_portals={this.state.active_portals}
          required={this.state.required}
          quality={this.state.quality}
          webedit={this.state.webedit}
          collapsed={this.state.collapsed}
          setIgnoreFields={this.setIgnoreFields}
          setInitVals={this.setInitVals}
        />
      )
    })
  }

  renderList() {
    const { config } = this.props
    const hasTabs = !!config.tabs
    return (
      <div
        className={classNames('jumplist', { sticky: this.state.sticky, show: this.state.showJump })}
        id={'slider-jumplist-groups'}
      >
        <div className="jumplist-inner" ref={el => { this.sliderref = el }}>
          { !hasTabs && !this.state.showJump ? (
            <span ref={el => (this.cardtoggle = el)} className={`cardtoggle-jump${this.state.sticky ? ' sticky' : ''}`}>
              <Button type="button" className="btn btn-icon-right" onClick={() => this.setState({ showJump: true })}>
                {getIn(this.state, 'currentGroup.label', Object.keys(config.fieldgroups)[0])}
              </Button>
            </span>
          ) : null }
          {Object.keys(config.fieldgroups).map((group, gidx) => {
            if (document.querySelector(`#${slugify(group)}`)) {
              this.initObserver(document.querySelector(`#${slugify(group)}`))
              return (
                <NavLink
                  key={`jl-${gidx}`}
                  aria-current="step"
                  isActive={() => slugify(group) === this.state.currentGroup.id}
                  to={{ hash: `#${slugify(group)}`, search: this.props.location.search }}
                  onClick={() => {
                    const element = this.scroller.querySelector(`#${slugify(group)}`)
                    this.setState({ showJump: false }, () => {
                      this.scrollTo(element)
                    })
                  }}
                >
                  {group}
                </NavLink>
              )
            }
            return null
          })}
        </div>
      </div>
    )
  }

  hashChanged() {
    this.hashLinkScroll()
  }

  getCurrentElement() {
    if (document.querySelectorAll('.card')) {
      clearTimeout(this.timers.currentGroup)
      this.timers.currentGroup = setTimeout(() => {
        let cards = document.querySelectorAll('.card')
        cards = Array.prototype.slice.call(cards)
        const visible = cards.filter(c => isInViewport(c))
        const currentAvailable = sortBy(cards.map(c => ({ id: c.getAttribute('id'), label: c.querySelector('h3') ? c.querySelector('h3').textContent : null, top: c.getBoundingClientRect().top, height: c.getBoundingClientRect().height })), 'top')
        const currentVisible = sortBy(visible.map(c => ({ id: c.getAttribute('id'), label: c.querySelector('h3') ? c.querySelector('h3').textContent : null, top: c.getBoundingClientRect().top, height: c.getBoundingClientRect().height })), 'top')
        let current
        if (this.state.selectedGroup && this.state.selectedGroup.id) {
          current = currentVisible.find(c => c.id === this.state.selectedGroup.id)
        }
        if (!current) {
          current = currentAvailable.filter(c => c.top + c.height >= (208 + (!this.state.showActions ? 65 : 0)))[0]
        }
        if (current && current.id !== getIn(this.state.currentGroup, 'id')) {
          this.setState({ currentGroup: current }, () => {
            clearTimeout(this.timers.push)
            this.timers.push = setTimeout(() => {
              history.replace({ hash: current.id, search: this.props.location.search })
            }, 300)
          })
        }
      }, 70)
    }
  }

  cancelOtherScrolls() {
    clearTimeout(this.timers.scroll_update)
  }

  updateScroller() {
    const { hash } = window.location
    if (this.scroller) {
      const id = hash.replace('#', '')
      const element = document.getElementById(id)
      this.scrollTo(element)
    } else {
      clearTimeout(this.timers.scroll_update)
      this.timers.scroll_update = setTimeout(this.updateScroller, 300)
    }
  }

  hashLinkScroll() {
    const { hash } = window.location
    if (hash !== '') {
      // Push onto callback queue so it runs after the DOM is updated,
      // this is required when navigating from a different page so that
      // the element is rendered on the page before trying to getElementById.
      clearTimeout(this.timers.scroll_update)
      this.timers.scroll_update = setTimeout(this.updateScroller, 300)
    } else {
      window.scrollTo(0, 0)
    }
  }

  scrollTo(el) {
    if (!el) { return }
    const scrollTop = el.offsetTop - (!this.state.showActions ? 48 : 20)
    const currentGroup = { id: el.getAttribute('id'), label: el.querySelector('h3') ? el.querySelector('h3').textContent : '', top: el.getBoundingClientRect().top, height: el.getBoundingClientRect().height }
    if (
      scrollTop !== this.state.scrollTop
      || currentGroup !== this.state.currentGroup
      || this.state.selectedGroup !== currentGroup
    ) {
      this.setState({ scrollTop, currentGroup, selectedGroup: currentGroup }, () => {
        this.scroller.scrollTo({
          top: scrollTop,
          behavior: 'smooth'
        })
      })
    }
  }


  handleUpdate() {
    if (!this.state.showActions) {
      const toggleBox = this.cardtoggle ? this.cardtoggle.getBoundingClientRect() : {}
      const toggleBoxTop = toggleBox.top - 64 - 68 - 44
      if (toggleBoxTop <= 0 && !this.state.sticky) {
        this.setState({ sticky: true })
      } else if (toggleBoxTop > 0 && this.state.sticky) {
        this.setState({ sticky: false })
      }
    }
  }

  render() {
    const { config, model, user, match } = this.props
    if (!match) { return null }
    if (!model) { return null }
    if (!this.state.initvals) { return null }
    const hasTabs = !!config.tabs
    return (
      <Formik
        initialValues={{
          ...this.state.initvals,
          endpoint: config.endpoint,
          modelname: config.modelname
        }}
        initialTouched={this.state.inittouched}
        validationSchema={getIn(validate, `${user.agent.site.region}.${config.modelname}`, getIn(validate, `default.${config.modelname}`))}
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={true}
        onSubmit={this.handleSubmit}
      >{ formik => {
          this.form = formik
          return (
            <CustomForm
              model={this.props.model ? true : false}
              id={this.props.id || 'content'}
              className={this.props.className}
              autosave
              actions={this.actions}
              mode="edit"
              modelname={config.modelname}
              userid={user.agent.id}
              modelid={model.id}
              onChange={(changes, form) => {
                if (Object.keys(this.form.touched).length > 0) { // Only fire when touched
                  changes.forEach(changedfield => {
                    if (
                      Object.keys(this.form.touched).includes(changedfield) &&
                      getIn(this.form.values, changedfield)
                    ) {
                      config.fields.filter(f => f.name === changedfield && f.onchange).forEach(cb => {
                        this.actions[cb.onchange](cb, form) // Perform the required action
                      })
                    }
                  })
                  if (changes.length > 0) {
                    const new_init = {
                      ...this.initModel({ ...model, ...formik.values }),
                      endpoint: config.endpoint,
                      modelname: config.modelname
                    }
                    changes.forEach(change => {
                      // if conditional fields appeared and we have data for them,
                      // and they weren't changed in this edit, then
                      // update the form values
                      const conditional = getIn(this.state.conditional_fields, change)
                      if (conditional) {
                        if (Array.isArray(conditional)) {
                          conditional.forEach(field => {
                            const current = getIn(formik.values, field)
                            const stored = getIn(new_init, field)
                            if (![ null, undefined ].includes(stored) && !current && !isEqual(stored, current)) {
                              formik.setFieldValue(field, stored, false).then(() =>
                                formik.setFieldTouched(field, false, false)
                              )
                            }
                          })
                        }
                      }
                    })
                    clearTimeout(this.timers.create_autosave)
                    this.timers.create_autosave = setTimeout(() => this.actions.autosaveForm({
                      userid: user.agent.id,
                      modelname: config.modelname,
                      mode: 'edit',
                      modelid: this.props.model.id,
                      values: form.values }),
                    3000)
                  }
                }
                if ([ 'settings' ].includes(this.props.config.modelname)) {
                  if (form.values.enable_popia) {
                    if (!form.values.cookie_consent) {
                      form.setFieldValue('cookie_consent', true).then(() => {
                        form.setFieldTouched('cookie_consent', true)
                      })
                    }
                  }
                }
              }}
              render={ () => (
                <>
                  <div className="viewhead">
                    <ModelActions
                      redirectSchema={this.redirectSchema}
                      editmode={true}
                      actions={this.actions}
                      statusmsg={formik.status ? formik.status.msg : false}
                      modelname={this.props.config.modelname}
                    >
                      {this.state.showActions && (
                        <>
                          <div className="card-legend">
                            {config.legend ?
                              config.legend.map((legend, ind) => (
                                <legend
                                  key={`leg-${ind}`}
                                  onClick={e => {
                                    if (legend.action) {
                                      return this[legend.action](e)
                                    }
                                    return null
                                  }}
                                  className={classNames(legend.className, 'btn btn-white btn-icon-left', {
                                    active: getIn(this.state, legend.className)
                                  })}
                                >
                                  Show {legend.label.toLowerCase()} fields only
                                </legend>
                              )) : null}
                          </div>
                          <span className="cardtoggle-required" >
                            {[ 'residential', 'commercial', 'holiday' ].includes(config.modelname) ? (
                              <Button type="button" className="btn btn-white btn-icon-left btn-icon-16" onClick={this.toggleWebEdit} icon={this.state.webedit ? '#icon16-ListAlt' : '#icon16-WebsiteListing'}>
                                {this.state.webedit ? 'Show all fields' : 'Show Website Listing' }
                              </Button>
                            ) : null}
                            {this.state.collapsable ? (
                              <Button type="button" className="btn btn-white btn-icon-left btn-icon-16" onClick={this.toggleCollapsed} icon='#icon16-Collapse'>
                                {this.state.collapsed ? 'Expand groups' : 'Collapse groups' }
                              </Button>
                            ) : null }
                          </span>
                        </>
                      )}
                    </ModelActions>
                  </div>
                  <div className={`view model${!hasTabs ? ' model-form' : ''} `}>
                    { !hasTabs && this.renderList() }
                    { !hasTabs &&
                      <div ref={el => (this.scroller = el)} className="viewcontent">
                        <div className="view-list">
                          {(!this.state.showActions) ? (
                            <div className="card-meta">
                              <div className="card-toggles">
                                <div className="card-legend">
                                  {config.legend ?
                                    config.legend.map((legend, ind) => (
                                      <legend
                                        key={`leg-${ind}`}
                                        onClick={e => {
                                          if (legend.action) {
                                            return this[legend.action](e)
                                          }
                                          return null
                                        }}
                                        className={classNames(legend.className, 'btn btn-white btn-icon-left', {
                                          active: getIn(this.state, legend.className)
                                        })}
                                      >
                                        {getIn(this.state, legend.className) ? 'Show all fields' : `Show ${legend.label.toLowerCase()}`}
                                      </legend>
                                    )) : null}
                                </div>
                                <span className="cardtoggle-required" >
                                  {[ 'residential', 'commercial', 'holiday' ].includes(config.modelname) ? (
                                    <Button type="button" className="btn btn-white btn-icon-left btn-icon-16" onClick={this.toggleWebEdit} icon={this.state.webedit ? '#icon16-ListAlt' : '#icon16-WebsiteListing'}>
                                      {this.state.webedit ? 'Show all fields' : 'Show Website Listing' }
                                    </Button>
                                  ) : null}
                                  <Button type="button" className="btn btn-white btn-icon-left btn-icon-16" onClick={this.toggleCollapsed} icon='#icon16-Collapse'>
                                    {this.state.collapsed ? 'Expand groups' : 'Collapse groups' }
                                  </Button>
                                </span>
                              </div>
                            </div>
                          ) : null}
                          {this.props.steps.selected && this.props.steps.selected.length > 1 &&
                              <Card
                                background
                                body={
                                  <Step
                                    selected={this.props.steps.selected}
                                    editmode={true}
                                    modelid={model.id}
                                    stepPage={this.props.steps.stepPage}
                                    next={this.props.steps.next}
                                    previous={this.props.steps.previous}
                                  />
                                }
                              />
                          }
                          { hasTabs ? this.renderTabs() : this.renderGroups() }
                        </div>
                      </div>
                    }
                    { hasTabs &&
                    <div className="viewcontent">
                      <div className="view-list">
                        {(!this.state.showActions) ? (
                          <div className="card-meta">
                            <div className="card-toggles">
                              <div className="card-legend">
                                {config.legend ?
                                  config.legend.map((legend, ind) => (
                                    <legend
                                      key={`leg-${ind}`}
                                      onClick={e => {
                                        if (legend.action) {
                                          return this[legend.action](e)
                                        }
                                        return null
                                      }}
                                      className={classNames(legend.className, 'btn btn-white btn-icon-left', {
                                        active: getIn(this.state, legend.className)
                                      })}
                                    >
                                      {getIn(this.state, legend.className) ? 'Show all fields' : `Show ${legend.label.toLowerCase()}`}
                                    </legend>
                                  )) : null}
                              </div>
                              <span className="cardtoggle-required" >
                                {[ 'residential', 'commercial', 'holiday' ].includes(config.modelname) ? (
                                  <Button type="button" className="btn btn-white btn-icon-left btn-icon-16" onClick={this.toggleWebEdit} icon={this.state.webedit ? '#icon16-ListAlt' : '#icon16-WebsiteListing'}>
                                    {this.state.webedit ? 'Show all fields' : 'Show Website Listing' }
                                  </Button>
                                ) : null}
                                <Button type="button" className="btn btn-white btn-icon-left btn-icon-16" onClick={this.toggleCollapsed} icon='#icon16-Collapse'>
                                  {this.state.collapsed ? 'Expand groups' : 'Collapse groups' }
                                </Button>
                              </span>
                              { !hasTabs ? (
                                <span ref={el => (this.cardtoggle = el)} className={`cardtoggle-jump${this.state.sticky ? ' sticky' : ''}`}>
                                  <Button type="button" className="btn btn-icon-right" onClick={() => this.setState({ showJump: true })}>
                                    {getIn(this.state, 'currentGroup.label', Object.keys(config.fieldgroups)[0])}
                                  </Button>
                                </span>
                              ) : null }
                            </div>
                          </div>
                        ) : null}
                        {this.props.steps.selected && this.props.steps.selected.length > 1 &&
                        <Card
                          background
                          body={
                            <Step
                              selected={this.props.steps.selected}
                              editmode={true}
                              modelid={model.id}
                              stepPage={this.props.steps.stepPage}
                              next={this.props.steps.next}
                              previous={this.props.steps.previous}
                            />
                          }
                        />
                        }
                        { hasTabs ? this.renderTabs() : this.renderGroups() }
                      </div>
                    </div>
                    }
                  </div>
                </>
              )}
            />
          )
        }}
      </Formik>
    )
  }
}

ModelEdit.propTypes = {
  model: PropTypes.oneOfType([ PropTypes.bool, PropTypes.object ]),
  modelname: PropTypes.string,
  models: PropTypes.object,
  portals: PropTypes.object,
  modelid: PropTypes.number,
  cache: PropTypes.object,
  selected: PropTypes.array,
  config: PropTypes.object,
  actions: PropTypes.object,
  configs: PropTypes.object,
  location: PropTypes.object,
  title: PropTypes.string,
  match: PropTypes.object,
  routeConfig: PropTypes.object,
  user: PropTypes.object,
  steps: PropTypes.object,
  addons: PropTypes.array,
  id: PropTypes.string,
  className: PropTypes.string,
  state: PropTypes.object
}

export default ModelEdit

// ModelEdit.whyDidYouRender = true
