/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from 'react'

import { ErrorMessage, getIn } from 'formik'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import HelpText from '../../HelpText'
import InputCounter from './InputCounter'
import Label from './Label'


const isEnter = (e, form) => {
  if (e.keyCode === 13) { // fire goToPage on enter
    return form.submitForm()
  } // continue typing
  return true
}

const TextInput = ({
  field: {
    name,
    value
  },
  form: {
    touched,
    handleBlur,
    handleChange,
    ...form
  },
  label,
  className,
  type,
  disabled,
  readonly,
  id,
  show_search,
  prefix,
  suffix,
  after,
  help,
  counter,
  // eslint-disable-next-line react/prop-types
  form_el,
  // eslint-disable-next-line react/prop-types
  resetPassword,
  // eslint-disable-next-line no-unused-vars
  error,
  autoComplete,
  forwardRef,
  defaultValue,
  ...props
}) => {
  const timer = useRef()

  const elm = useRef(null)
  const [ tvalue, setValue ] = useState(![ undefined, null ].includes(defaultValue) ? defaultValue : '')
  const t = useRef()
  const b = useRef()
  const onChange = e => {
    setValue(![ undefined, null ].includes(e.target.value) ? e.target.value : '')
    clearTimeout(t.current)
    t.current = setTimeout(() => {
      if (props.onChange && props.onChange instanceof Function) {
        props.onChange(e)
      } else if (props.onChange && props.actions && props.actions[props.onChange]) {
        props.actions[props.onChange]({ field: { name, value }, form })
      }
      handleChange(e)
    }, 150)
  }
  const onBlur = e => {
    setValue(![ undefined, null ].includes(e.target.value) ? e.target.value : '')
    clearTimeout(b.current)
    b.current = setTimeout(() => {
      if (props.onBlur && props.onBlur instanceof Function) {
        props.onBlur(e)
      } else if (props.onBlur && props.actions && props.actions[props.onBlur]) {
        props.actions[props.onBlur]({ field: { name, value }, form })
      }
      handleBlur(e)
    }, 150)
  }

  useEffect(() => {
    if (defaultValue !== tvalue) {
      setValue(![ undefined, null ].includes(defaultValue) ? defaultValue : tvalue || '')
    }
  }, [ defaultValue ])

  useEffect(() => {
    if (value !== tvalue) {
      setValue(![ undefined, null ].includes(value) ? value : tvalue || '')
    }
  }, [ props.aidx ])

  // We watch touched cause it can be used to trigger a state change when imperitively changing the value
  // ie. using setFieldValue in the CleverCompose sidebar when updating the marketing heading
  useEffect(() => {
    if (touched && value && value !== tvalue) {
      setValue(value)
    }
  }, [ touched ])

  const input = (
    <div id={id} className={`form-group ${name} ${props.classes ? props.classes : ''}`}>
      <Label htmlFor={`input-${id}`} className="formlabel">{label}</Label>
      <div className="forminput">
        {prefix}
        <input
          autoComplete={autoComplete}
          id={`input-${id}`}
          name={name}
          type={type}
          ref={forwardRef || elm}
          disabled={disabled}
          readOnly={readonly}
          placeholder={props.placeholder}
          className={classNames('form-control', { 'input-group-prefix': !!prefix, 'input-group-suffix': !!suffix }, className)}
          onFocus={e => {
            if (props.onFocus) {
              props.onFocus(e)
            }
          }}
          onBlur={onBlur}
          onKeyDown={show_search ? e => {
            clearTimeout(timer.current)
            timer.current = setTimeout(() => {
              // eslint-disable-next-line react/prop-types
              if (form_el && elm && form_el.contains(elm.current)) {
                isEnter(e, form)
              }
            }, 500)
          } : null}
          onChange={onChange}
          value={tvalue === 0 || tvalue ? tvalue : ''} // Cannot set value otherwise debounce breaks
          maxLength={props.maxLength}
        />
        {suffix}
      </div>
      {after}
      {help &&
        <span className="help-text">
          {help.map(component => (<HelpText {...component} key={`input-${name}-help`} />))}
        </span>
      }
      {counter &&
        <InputCounter parentRef={elm} {...props.counter} />
      }
      {resetPassword &&
        <Link to="/forgot" className="forgot">Forgot password?</Link>
      }
      { // Fix error not displaying for Customer Experience fields (fake array field)
        // eslint-disable-next-line no-nested-ternary
        getIn(form.errors, name) ? (
          <ErrorMessage name={name} render={msg => <div className="error">{msg.replace(name, label)}</div> } />
        ) : (
          error && error[name] && touched[name] ? (
            <div className="error">{error[name]}</div>
          ) : null
        )}
    </div>
  )
  return input
}

TextInput.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  _value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  id: PropTypes.string,
  aidx: PropTypes.number,
  prefix: PropTypes.string,
  suffix: PropTypes.object,
  actions: PropTypes.object,
  autoComplete: PropTypes.string,
  show_search: PropTypes.bool,
  after: PropTypes.string,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  maxLength: PropTypes.number,
  classes: PropTypes.string,
  form_el: PropTypes.any,
  resetPassword: PropTypes.bool,
  error: PropTypes.object,
  placeholder: PropTypes.string,
  help: PropTypes.array,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  counter: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  forwardRef: PropTypes.any
}

export default React.memo(TextInput)
