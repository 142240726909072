/* eslint-disable new-cap */
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { getIn } from 'formik'
import React, { useEffect, useState, useRef } from 'react'
import isEqual from 'react-fast-compare'
import { buildLambdaURI } from '../../../../utils'
import Loader from '../../Loader'
import { Button } from '../../../ui/Button'
import WideSidebar from '../../../ui/sidebar/WideSidebar'
import AssetSelectSidebar from '../../../../containers/AssetSelectSidebar'
import Label from './Label'


const ImageItem = props => {
  const { file, className } = props

  return (
    <div className={className}>
      <div>
        <img alt="" src={file.preview} />
      </div>
    </div>
  )
}

ImageItem.propTypes = {
  file: PropTypes.object,
  selected: PropTypes.bool,
  path: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  name: PropTypes.string,
  readonly: PropTypes.bool,
  value: PropTypes.number
}

const AssetSelector = props => {
  const { modelname, actions, user, settings, match, field, form, label, initialValue } = props
  const [ loading, setLoading ] = useState(false)
  const [ asset, setAsset ] = useState(null)
  const [ value, setValue ] = useState(initialValue)
  const abortController = useRef(new AbortController())

  const fetchAsset = () => {
    new Promise((resolve, reject) => {
      actions.fetchOne(modelname, value, resolve, reject)
    }).then(resp => {
      const model = getIn(resp, `${modelname}.${value}`)
      setAsset(model)
      setLoading(false)
      abortController.current?.abort()
    })
  }

  useEffect(() => {
    if (!isEqual(field.value, value)) {
      setValue(field.value)
    }
    if (value && !asset || value && asset && value !== asset.id) { fetchAsset() }
  }, [ value, field.value ])

  useEffect(() => {
    if (value) { fetchAsset() }
    return () => {
      abortController.current?.abort()
    }
  }, [])

  const file = value && asset && asset.meta ? asset.meta.asset : null
  if (file) {
    file.preview = buildLambdaURI(file.file, { w: 320, h: 240 })
  }

  if (loading) { return <Loader inline={true} /> }
  return (
    <div id={field.name} className={classNames('assetselectorinput', 'form-group', props.classes, field.name)}>
      <Label htmlFor={field.name}>
        {label}
      </Label>
      <div className="forminput">
        <div className={classNames('asset-selector', value ? 'selected' : null)}>

          <div className="asset-selector-btn-container">
            {value ? (
              <div className="asset-selector-btn">
                <Button
                  icon="#icon24-Bin"
                  type="button"
                  className="btn btn-grey btn-icon-24 btn-icon-only"
                  onClick={() => { form.setFieldValue(field.name, null) }}
                />
              </div>
            ) : null}
            <div className="asset-selector-btn">
              <Button
                type="button"
                id='asset-search-btn'
                tabIndex="-1"
                onClick={() => actions.toggleWideSidebar(`asset-search-sidebar-${field.name}`) }
                className="btn btn-grey btn-icon-left btn-icon-16"
              >Search Assets</Button>
            </div>
          </div>

          <WideSidebar sidebar={`asset-search-sidebar-${field.name}`}>
            <AssetSelectSidebar
              label={label}
              field={field}
              modelname="assets"
              user={user}
              key={`asset-search-sidebar-${field.name}`}
              settings={settings}
              siblingform={form}
              match={match}
              addSelected={s => { form.setFieldValue(field.name, s[0]) }}
            />
          </WideSidebar>

          {value && file ? (
            <ImageItem
              key={`asset-${field.name}`}
              item={asset}
              className={classNames('asset-item')}
              value={value}
              file={file}
              name={field.name}
            />
          ) : null }
        </div>
      </div>
    </div>
  )
}

export default AssetSelector

AssetSelector.propTypes = {
  actions: PropTypes.object,
  user: PropTypes.object,
  settings: PropTypes.object,
  match: PropTypes.object,
  field: PropTypes.object,
  classes: PropTypes.string,
  form: PropTypes.object,
  initialValue: PropTypes.string,
  modelname: PropTypes.string,
  label: PropTypes.string
}
